/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

name Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import { useArgonController } from "context";

function NameCell({ image, name }) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  return (
    <ArgonBox display="flex" alignItems="center" pr={2}>
      <ArgonBox mr={2}>
        <ArgonAvatar
          src={image}
          alt={name}
          variant="rounded"
          sx={{
            height: "42px",
            width: "42px",
          }}
        />
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonTypography variant="jpBodyBold" className="tr-w-2">
          {name}
        </ArgonTypography>
      </ArgonBox>
    </ArgonBox>
  );
}

// Typechecking props for the NameCell
NameCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default NameCell;
