import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Divider,
  Grid,
  styled,
} from "@mui/material";
import borders from "assets/theme/base/borders";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";
import UserNavbar from "examples/Navbars/UserNavbar";

import { ReactComponent as AddIcon } from "assets/images/icons/Add.svg";
import { ReactComponent as MinusIcon } from "assets/images/icons/Minus.svg";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import { ReactComponent as EmptyStarIcon } from "assets/images/icons/EmptyStar.svg";

import { useArgonController } from "context";
import FormEditor from "components/Common/FormEditor";
import { useNavigate, useParams } from "react-router-dom";
import api2 from "api2";
import { useSelector } from "react-redux";
import axios from "axios";
import FormField from "components/Common/FormField";
import ArgonSnackbar from "components/ArgonSnackbar";
import Footer from "examples/Footer";

const AddReview = () => {
  const { listingId, reviewId } = useParams();
  const { borderRadius } = borders;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const navigate = useNavigate();
  const [listingInfo, setListingInfo] = useState();
  const [reviewInfo, setReviewInfo] = useState();
  const [ageRating, setAgeRating] = useState(2);
  const [rating, setRating] = useState(1);
  const [review, setReview] = useState("");
  const [ratingName, setRatingName] = useState();
  const token = useSelector((state) => state?.authState?.user?.token);
  const [showError, setShowError] = useState(false);
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });
  const fetchListing = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let result = await api2
        .get(`listings/${listingId}`, config)
        .then((res) => {
          setListingInfo({
            ...res?.data?.data,
          });
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  const fetchReview = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let result = await api2
        .get(`param-ratings/${reviewId}`, config)
        .then((res) => {
          setReview(res?.data?.data?.ratingData?.ratingText);
          setRatingName(res?.data?.data?.ratingData?.ratingName);
          setRating(res?.data?.data?.ratingData?.rating);
          setAgeRating(res?.data?.data?.ratingData?.ageRating);
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  useEffect(() => {
    if (listingId) {
      fetchListing();
    }
    if (reviewId) {
      fetchReview();
    }
  }, []);

  const AddIconStyled = styled(AddIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.white.main,
    },
  }));

  const MinusIconStyled = styled(MinusIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.white.main,
    },
  }));

  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 48,
    width: 48,
    cursor: "pointer",
    path: {
      fill: theme.palette.yellow.five,
    },
    // ":hover": {
    //   path: {
    //     fill: darkMode ? theme.palette.white.main : theme.palette.yellow.five,
    //   },
    // },
  }));

  const FullStarBankIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 48,
    width: 48,
    cursor: "pointer",
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
    // ":hover": {
    //   path: {
    //     fill: darkMode ? theme.palette.white.main : theme.palette.yellow.five,
    //   },
    // },
  }));
  const handleSubmit = async () => {
    // if(listingId && rating && review && ratingName){
    const payload = {
      listingId,
      rating,
      ageRating,
      ratingText: review,
      ratingName: ratingName,
    };
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      if (!reviewId) {
        await api2.post(`param-ratings/add`, payload, config).then((res) => {
          setAlert({
            status: "success",
            isOpen: true,
            alertMsg: "Review Added successfully.",
            bgColor: "bggreen",
          });
          setTimeout(() => {
            navigate(-1);
          }, 1000);
        });
      } else {
        await api2
          .put(`param-ratings/update/${reviewId}`, payload, config)
          .then((res) => {
            setAlert({
              status: "success",
              isOpen: true,
              alertMsg: "Review Update successfully.",
              bgColor: "bggreen",
            });
            setTimeout(() => {
              navigate(-1);
            }, 1000);
          });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
    // }
  };
  return (
    <>
      <PageLayout>
        <UserNavbar />
        <Container>
          <Grid container my={4}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <ArgonBox>
                  <ArgonTypography variant="jpH3Bold">
                    {reviewId ? "Edit a Review" : "Create a Review"}
                  </ArgonTypography>
                  {/*  */}
                  <ArgonBox
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      my: 2,
                    }}
                  >
                    <ArgonBox
                      component="img"
                      src={listingInfo?.thumbnailImg}
                      sx={{
                        width: "150px",
                        height: "100%",
                        objectFit: 'cover',
                        objectPosition: 'top',
                        borderRadius: borderRadius.md,
                      }}
                    />

                    <ArgonBox display="flex" flexDirection="column" gap={1}>
                      <ArgonTypography variant="jpH2">
                        {listingInfo?.name}
                      </ArgonTypography>
                      <ArgonTypography variant="jpBodyRegular">
                        {listingInfo?.category?.name}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>

                  {/*  */}
                  <ArgonBox
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                    my={5}
                  >
                    <ArgonBox display="flex" flexDirection="column" gap={1}>
                      <ArgonTypography variant="jpH3Bold">
                        Age rating
                      </ArgonTypography>
                      <ArgonTypography variant="jpBodyRegular">
                        Kids in this age group should be able to understand this
                        title.
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox display="flex" alignItems="center" gap={1}>
                      <ArgonButton
                        color="info"
                        iconOnly
                        sx={{
                          boxShadow: "none",
                        }}
                        onClick={() => {
                          if (ageRating > 1) {
                            setAgeRating(ageRating - 1);
                          }
                        }}
                      >
                        <MinusIconStyled />
                      </ArgonButton>
                      <ArgonTypography
                        variant="jpBodyBold"
                        sx={({ palette: { natural } }) => ({
                          border: `2px solid ${natural.five}`,
                          borderRadius: borderRadius.md,
                          padding: "8px 24px",
                        })}
                      >
                        {ageRating}
                      </ArgonTypography>
                      <ArgonButton
                        color="info"
                        iconOnly
                        sx={{
                          boxShadow: "none",
                        }}
                        onClick={() => {
                          if (ageRating < 18) {
                            setAgeRating(ageRating + 1);
                          }
                        }}
                      >
                        <AddIconStyled />
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>

                  <Divider />

                  {/*  */}
                  <ArgonBox
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                    my={5}
                  >
                    <ArgonBox display="flex" flexDirection="column" gap={1}>
                      <ArgonTypography variant="jpH3Bold">
                        Quality rating
                      </ArgonTypography>
                      <ArgonTypography variant="jpBodyRegular">
                        Do you think it's any good? Contribute to the
                        community's decision.
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox display="flex" alignItems="center">
                      {[...Array(5)].map((x, index) => (
                        <div
                          key={index}
                          onClick={(e) => {
                            setRating(index + 1);
                          }}
                        >
                          {index < rating ? (
                            <FullStarFillIconStyled />
                          ) : (
                            <FullStarBankIconStyled />
                          )}
                        </div>
                      ))}
                    </ArgonBox>
                  </ArgonBox>

                  <Divider />

                  {/*  */}
                  <ArgonBox
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    my={5}
                  >
                    <ArgonBox display="flex" flexDirection="column" gap={1}>
                      <ArgonTypography variant="jpH3Bold">
                        HeadLine
                      </ArgonTypography>
                    </ArgonBox>

                    <ArgonBox
                      sx={{
                        position: "relative",
                        display: "inline-block",
                        height: "100%",
                      }}
                    >
                      <FormField
                        placeholder="Head Line"
                        value={ratingName}
                        onChange={(e) => setRatingName(e.target.value)}
                      />
                    </ArgonBox>
                  </ArgonBox>

                  <Divider />

                  <ArgonBox
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    my={5}
                  >
                    <ArgonBox display="flex" flexDirection="column" gap={1}>
                      <ArgonTypography variant="jpH3Bold">
                        Write your review
                      </ArgonTypography>
                    </ArgonBox>

                    <ArgonBox
                      sx={{
                        position: "relative",
                        display: "inline-block",
                        height: "100%",
                      }}
                      value={review}
                    >
                      <FormEditor
                        value={review}
                        onChange={(value) => setReview(value)}
                      />
                    </ArgonBox>
                  </ArgonBox>

                  <Divider />

                  {/*  */}
                  <ArgonBox
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap={3}
                  >
                    <ArgonButton
                      size="large"
                      sx={({ palette: { error } }) => ({
                        background: error.secondary,
                        color: error.primary,
                        ":hover": {
                          background: error.secondary,
                        },
                        ":focus:not(:hover)": {
                          background: error.secondary,
                        },
                      })}
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </ArgonButton>
                    <ArgonButton
                      color="info"
                      size="large"
                      onClick={handleSubmit}
                    >
                      {reviewId ? "Save" : "Submit"}
                    </ArgonButton>
                  </ArgonBox>
                  <></>
                </ArgonBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {alert.isOpen && (
          <ArgonSnackbar
            color={alert.status}
            icon="check"
            title={alert.status}
            content={alert.alertMsg}
            open={alert.isOpen}
            onClose={() => setAlert({ isOpen: false })}
            close={() => setAlert({ isOpen: false })}
            bgColor={alert.bgColor}
          />
        )}
      </PageLayout>
      <Footer />
    </>
  );
};

export default AddReview;
