import { Switch } from '@mui/material'
import { getArticleApi } from 'Actions/listingAction';
import { getListingApi } from 'Actions/listingAction';
import { updateListingApi } from 'Actions/listingAction';
import api2 from 'api2';
import React from 'react'
import { useDispatch } from 'react-redux';

export default function EditorChoice({item,setUpdatedId}) {
    const dispatch = useDispatch()
    const handleClick =async(e)=>{
        const payload = {
            editorChoice:e?.target?.checked
        }
        setUpdatedId(item?._id)
        dispatch(updateListingApi(payload, item?._id));

    }
  return (
    <Switch checked={item?.editorChoice} onChange={handleClick}/>
  )
}
