import { Divider, Grid } from '@mui/material'
import { getCategoriesListApi } from 'Actions/categoryAction'
import ArgonBox from 'components/ArgonBox'
import ArgonTypography from 'components/ArgonTypography'
import BookMarkCard from 'examples/Cards/BookMarkCard'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

export default function FilteredBookmarkedCard({ item }) {
    const [isShow, setIsShow] = useState(true)
    return (
        <>
                            {item[Object.keys(item)].length ? (
                                <>
                            <ArgonBox my={4}>
                                <ArgonBox
                                    mb={4}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <ArgonTypography variant="jpH2">{Object.keys(item)}</ArgonTypography>
                                    {item[Object.keys(item)].length > 4 && (
                                    <ArgonTypography
                                        variant="jpBodyMedium"
                                        sx={({ palette: { blue } }) => ({
                                            color: blue.five,
                                            cursor: "pointer",
                                        })}
                                        onClick={()=>setIsShow(!isShow)}
                                    >
                                        See {isShow ?  'All' :'less'}
                                    </ArgonTypography>
                                    )}
                                </ArgonBox>
                                <Grid container spacing={3}>
                                    {item[Object.keys(item)].length ? (
                                        <>
                                            {item[Object.keys(item)].map((i,index) => (
                                                <>
                                                {isShow ? (
                                                    <>
                                                    {index<4 && (
                                                     <Grid item md={3} xs={12}>
                                                     <BookMarkCard i={i} />
                                                    </Grid>
                                                    )}
                                                    </>
                                                ):(
                                                    <Grid item md={3} xs={12}>
                                                    <BookMarkCard i={i} />
                                                </Grid>
                                                )}
                                               
                                                </>
                                            ))}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </ArgonBox>
                            <Divider />
                              </>
                            ):(
                                <></>
                            )}
        </>
    )
}
