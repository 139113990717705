/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import FormField from "components/Common/FormField";
import ArgonAvatar from "components/ArgonAvatar";
import burceMars from "assets/images/bruce-mars.jpg";
import ArgonButton from "components/ArgonButton";
import { Icon } from "@mui/material";
import { useSelector } from "react-redux";
import { updateUser } from "Actions/userAction";
import { useDispatch } from "react-redux";
import ArgonSnackbar from "components/ArgonSnackbar";
import api2 from "api2";
import axios from "axios";
import Loader from "components/Common/CircularProgress";
import FormEditor from "components/Common/FormEditor";
import CustomFields from "layouts/pages/listings/CustomFields";

function MyProfile() {
  const [skills, setSkills] = useState(["react", "angular"]);
  const userData = useSelector((state) => state?.authState?.user);
  const [customFieldsArray,setCustomFieldsArray] = useState()
  const ref = useRef();
  const [isLoader, setIsLoader] = useState(false)
  const [userBioContent, setUserBioContent] = useState("")
  
  const dispatch = useDispatch();
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
  });
  const [userInfo, setUserInfo] = useState();
  const customFieldsList = useSelector((state)=>state?.userCustomFieldsState?.userCustomFieldsList?.data?.data)
  useEffect(()=>{
    setCustomFieldsArray(customFieldsList?.customFields)
  },[customFieldsList])
  useEffect(() => {
    setUserInfo(userData?.user);
    if(userData?.user?.customFieldsList?.length ){
      setCustomFieldsArray(userData?.user?.customFieldsList)
    }
    // setUserBioContent(userData?.user?.bio || "")
  }, [userData]);
  const handleSubmit = () => {
    userInfo['customFieldsList'] = customFieldsArray
    dispatch(updateUser(userInfo, userInfo?._id));
    dispatch({
      type: "login",
      payload: "success",
      data: { ...userData, user: userInfo },
    });
    setAlert({
      ...alert,
      status: "success",
      isOpen: true,
      alertMsg: "Profile updated successfully.",
      bgColor: "bggreen",
    });
  };
  const handleUpload = async (fieldName, file) => {
    try {
      let formData = new FormData();
      formData.append("file", file);
      let result = await api2.post(`imageUpload`, formData);
      setIsLoader(false)
      setUserInfo({
        ...userInfo,
        [fieldName]: result?.data?.data,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  return (
    <Card id="profile" sx={{ overflow: "visible" }}>
      <ArgonBox p={3}>
        <ArgonTypography variant="h5">My Profile </ArgonTypography>
      </ArgonBox>

      <ArgonBox p={2}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Grid item sx={{ position: "relative" }}>
            {!isLoader ? (
              <>
                {userInfo?.profilePicture ? (
                  <ArgonBox
                    component="img"
                    src={userInfo?.profilePicture}
                    sx={{
                      height: "120px",
                      width: "120px",
                      objectFit: "cover",
                    }}
                    alt="profile-image"
                    borderRadius="lg"
                    shadow="sm"
                  />) :
                  (<ArgonAvatar
                    variant="rounded"
                    sx={{
                      height: "120px",
                      width: "120px",
                    }}
                    alt="profile-image"
                    shadow="sm"
                    bgColor="info"
                  />)

                }</>
            ) : (
              <Loader />
            )}


            <ArgonBox
              alt="spotify logo"
              position="absolute"
              right={0}
              bottom={0}
              mr={-1}
            >
              <ArgonButton
                variant="gradient"
                color="info"
                size="small"
                iconOnly
                onClick={() => {
                  ref.current.childNodes[0].childNodes[1].childNodes[0].click();
                }}
              >
                <Icon>edit</Icon>
              </ArgonButton>
            </ArgonBox>
            <ArgonBox ref={ref} sx={{ display: "none" }}>
              <FormField
                type="file"
                placeholder="Alec"
                onChange={(e) => {
                  handleUpload("profilePicture", e.target?.files[0]);
                  setIsLoader(true)
                }}
              />
            </ArgonBox>
          </Grid>
          <Grid item>
            <ArgonBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
              mt={0.5}
              lineHeight={1}
            >
              <ArgonTypography variant="h5" fontWeight="medium">
                {userInfo?.userName}
              </ArgonTypography>
              <ArgonTypography
                variant="button"
                color="text"
                fontWeight="medium"
              >
                @{userInfo?.name || userInfo?.userName}
              </ArgonTypography>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
      <ArgonBox component="form" py={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="User name"
              placeholder="Alec"
              value={userInfo?.userName}
              onChange={(e) =>
                setUserInfo({ ...userInfo, userName: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Your name"
              placeholder="Thompson"
              value={userInfo?.name}
              onChange={(e) =>
                setUserInfo({ ...userInfo, name: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="email"
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
              value={userInfo?.email}
              onChange={(e) =>
                setUserInfo({ ...userInfo, email: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type="Number"
              label="Age"
              placeholder="23"
              value={userInfo?.age}
              onChange={(e) =>
                setUserInfo({ ...userInfo, age: e.target.value })
              }
            />
          </Grid>

        </Grid>
        {/* <Grid item px={3} xs={12} sm={6}> */}
      <ArgonBox component="form" xs={12} py={3} >
          <CustomFields customFieldsList={customFieldsArray} setCustomFieldsList={setCustomFieldsArray}/>
          </ArgonBox>
        {/* </Grid> */}

        <ArgonBox
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          my={3}
        >
          <ArgonButton
            variant="gradient"
            color="info"
            size="large"
            onClick={handleSubmit}
          >
            Save
          </ArgonButton>
        </ArgonBox>
      </ArgonBox>
      {alert?.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </Card>
  );
}

export default MyProfile;
