import authReducer from "reducers/authReducer";
import categoryReducer from "reducers/categoryReducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";

export const rootReducer = 
combineReducers({
  // authState: authReducer,
  // categoryState: categoryReducer,
  })
const store = createStore(rootReducer, applyMiddleware(thunk))
export  default store ;