/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Argon Dashboard 2 PRO MUI are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Argon Dashboard 2 PRO MUI layouts

import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Error404 from "layouts/authentication/error/404";

// Argon Dashboard 2 PRO MUI components
import Category from "layouts/pages/category";
import AddCategory from "layouts/pages/category/add-category";
import Listings from "layouts/pages/listings";
import AddListing from "layouts/pages/listings/add-listing";
import ViewListing from "./layouts/pages/listings/view-listing";
import CommunityReviews from "layouts/pages/community-reviews";
import Team from "layouts/pages/users/team";
import Customers from "layouts/pages/users/customers";
import Flagged from "layouts/pages/users/flagged";
import FolderIcon from "components/Common/Icons/FolderIcon";
import InboxIcon from "components/Common/Icons/InboxIcon";
import ChatBubbleIcon from "components/Common/Icons/ChatBubbleIcon";
import PeopleIcon from "components/Common/Icons/PeopleIcon";
import AddCommunityReview from "layouts/pages/community-reviews/add-community-review";
import UserProfile from "layouts/pages/users/user-profile";
import Home from "layouts/user/home";
import SingleCategory from "layouts/user/categoey/single-category";
import AddReview from "layouts/user/review/add-review";
import Search from "layouts/user/search";
import Articles from "layouts/pages/articles";
import AddArticle from "layouts/pages/articles/add-article";
import ArticleCategory from "layouts/pages/articles/article-category";
import AddArticleCategory from "layouts/pages/articles/article-category/add-article-category";
import CustomFields from "layouts/pages/users/custom-fields";
import AllArticles from "layouts/user/articles";
import ViewArticle from "layouts/user/articles/view/ViewArticle";

const routes = [
  {
    route: '/home',
    component: <Home />,
    accessRoles: ['parent', 'teenager', 'author', 'editor', 'superadmin'],
  },
  {
    route: '/home/:categoryName/:categoryId',
    component: <Home />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/:categoryName/single-category/:listingId',
    component: <SingleCategory />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/:categoryName/single-category/:listingId/add-review',
    component: <AddReview />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/:categoryName/single-category/:listingId/edit-review/:reviewId',
    component: <AddReview />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/signin',
    isPublic: true,
    component: <SignIn />,
    accessRoles: [],
  },
  {
    route: '/signup',
    isPublic: true,
    component: <SignUp />,
    accessRoles: [],
  },
  {
    route: '/add-category',
    isPublic: false,
    component: <AddCategory />,
    accessRoles: ['superadmin'],
  },
  {
    type: 'collapse',
    name: 'Categories',
    isPublic: false,
    key: 'categories',
    route: '/categories',
    icon: <FolderIcon />,
    component: <Category />,
    noCollapse: true,
    accessRoles: ['superadmin'],
  },
  {
    route: '/edit-category/:id',
    isPublic: false,
    component: <AddCategory />,
    accessRoles: ['superadmin'],
  },
  {
    route: '/edit-listing/:id',
    isPublic: false,
    component: <AddListing />,
    accessRoles: ['author', 'superadmin'],
  },
  {
    type: 'collapse',
    name: 'Listings',
    key: 'listings',
    route: '/listings',
    icon: <InboxIcon />,
    component: <Listings />,
    noCollapse: true,
    accessRoles: ['author', 'superadmin', 'editor'],
  },
  {
    route: '/add-listing',
    isPublic: false,
    component: <AddListing />,
    accessRoles: ['author', 'superadmin'],
  },
  {
    route: '/view-listing/:id',
    isPublic: false,
    component: <ViewListing />,
    accessRoles: ['author', 'superadmin'],
  },
  {
    type: 'collapse',
    name: 'Community Reviews',
    key: 'community-reviews',
    route: '/community-reviews',
    icon: <ChatBubbleIcon />,
    component: <CommunityReviews />,
    noCollapse: true,
    accessRoles: ['author', 'superadmin', 'editor'],
  },
  {
    route: '/add-community-review',
    component: <AddCommunityReview />,
    accessRoles: ['author', 'superadmin', 'editor'],
  },
  {
    route: '/search/:word',
    component: <Search />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/search',
    component: <Search />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/all-article/:id',
    component: <AllArticles />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/all-article',
    component: <AllArticles />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    type: 'collapse',
    name: 'Users',
    key: 'users',
    icon: <PeopleIcon />,
    collapse: [
      {
        name: 'Team',
        key: 'team',
        route: '/team',
        accessRoles: ['superadmin'],
        component: <Team />,
      },
      {
        name: 'Customers',
        key: 'customers',
        route: '/customers',
        accessRoles: ['superadmin'],
        component: <Customers />,
      },
      {
        name: 'Flagged',
        key: 'flagged',
        route: '/flagged',
        accessRoles: ['superadmin', 'editor'],
        component: <Flagged />,
      },
      {
        name: 'Custom Fields',
        key: 'customFields',
        route: '/custom-fields',
        accessRoles: ['superadmin', 'editor'],
        component: <CustomFields />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Articles',
    key: 'articles',
    noCollapse: true,
    accessRoles: ['author', 'superadmin', 'editor'],
    icon: <FolderIcon />,
    collapse: [
      {
        name: 'Article Category',
        key: 'article-category',
        route: '/article-category',
        accessRoles: ['superadmin'],
        component: <ArticleCategory />,
      },
      {
        name: 'Articles List',
        key: 'articles',
        route: '/articles',
        component: <Articles />,
        accessRoles: ['author', 'superadmin', 'editor'],
      },
    ],
  },

  {
    route: '/add-article',
    isPublic: false,
    component: <AddArticle />,
    accessRoles: ['author', 'superadmin', 'editor'],
  },
  {
    route: '/edit-article/:id',
    isPublic: false,
    component: <AddArticle />,
    accessRoles: ['author', 'superadmin', 'editor'],
  },
  {
    route: '/view-article/:id',
    component: <ViewArticle />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/add-article-category',
    isPublic: false,
    component: <AddArticleCategory />,
    accessRoles: ['superadmin'],
  },
  {
    route: '/edit-article-category/:id',
    isPublic: false,
    component: <AddArticleCategory />,
    accessRoles: ['superadmin'],
  },
];

export default routes;
