import React from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI base styles
import { Card, styled } from "@mui/material";
import { useArgonController } from "context";
import borders from "assets/theme/base/borders";

import { ReactComponent as IconCheckIcon } from "assets/images/icons/IconCheck.svg";
import { ReactComponent as ChatBubbleIconSvg } from "assets/images/icons/ChatBubble.svg";

const SubscriptionDetailCard = () => {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { borderRadius } = borders;

  const ChatBubbleIconStyled = styled(ChatBubbleIconSvg)(({ theme }) => ({
    height: 20,
    width: 20,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.five,
    },
  }));

  const IconCheckIconSvg = styled(IconCheckIcon)(({ theme }) => ({
    height: 64,
    width: 64,
  }));
  return (
    <>
      <Card sx={{ p: 4, width: "100%", height: "100%" }}>
        <ArgonBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <ArgonTypography variant="jpH3Bold">
            Subscription Detail
          </ArgonTypography>

          <ArgonBox
            mt={4}
            sx={{
              width: "100%",
            }}
          >
            <ArgonBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <ArgonBox
                mb={2}
                sx={({ palette: { blue } }) => ({
                  background: blue.two,
                  padding: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "100%",
                })}
              >
                <IconCheckIconSvg />
              </ArgonBox>

              <ArgonTypography variant="jpH3Bold">
                Premium Account
              </ArgonTypography>
              <ArgonBox display="flex" alignItems="center" gap={0.5}>
                <ChatBubbleIconStyled />
                <ArgonTypography
                  variant="jpCaptionRegular"
                  sx={({ palette: { natural, white } }) => ({
                    color: darkMode ? white.main : natural.five,
                  })}
                >
                  3 Reviews Viewed
                </ArgonTypography>
              </ArgonBox>

              <Grid
                container
                sx={{
                  width: "100%",
                  mt: 4,
                }}
              >
                <Grid
                  sx={({ palette: { natural } }) => ({
                    width: "100%",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 1,
                    border: `1px solid ${natural.three}`,
                    borderRadius: `${borderRadius.md} 0 0 ${borderRadius.md}`,

                    "@media (max-width: 768px)": {
                      borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,
                    },
                  })}
                  item
                  md={6}
                >
                  <ArgonTypography variant="jpCaptionRegular">
                    Subscribed On
                  </ArgonTypography>
                  <ArgonTypography variant="jpBodyBold">
                    9th June 2023
                  </ArgonTypography>
                </Grid>

                <Grid
                  item
                  md={6}
                  sx={({ palette: { natural } }) => ({
                    width: "100%",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 1,
                    border: `1px solid ${natural.three}`,
                    borderRadius: ` 0 ${borderRadius.md} ${borderRadius.md} 0`,
                    "@media (max-width: 768px)": {
                      borderRadius: `0 0 ${borderRadius.md} ${borderRadius.md} `,
                    },
                  })}
                >
                  <ArgonTypography variant="jpCaptionRegular">
                    Expired On
                  </ArgonTypography>

                  <ArgonTypography variant="jpBodyBold">
                    10th June 2023
                  </ArgonTypography>
                </Grid>
              </Grid>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </>
  );
};

export default SubscriptionDetailCard;
