/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonEditor from "components/ArgonEditor";

function FormEditor({ label, ...rest }) {
  return (
    <ArgonBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
    >
      <ArgonBox mb={1} lineHeight={0} display="inline-block">
        <ArgonTypography
          component="label"
          variant="jpCaptionRegular"
          textTransform="capitalize"
        >
          {label}
        </ArgonTypography>
      </ArgonBox>
      <ArgonEditor {...rest} />
    </ArgonBox>
  );
}

// Setting default values for the props of FormEditor
FormEditor.defaultProps = {
  label: " ",
};

// Typechecking props for FormEditor
FormEditor.propTypes = {
  label: PropTypes.string,
};

export default FormEditor;
