/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import { useArgonController } from "context";

function NameCell({ image, listingName, categoryName,year }) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  return (
    <ArgonBox display="flex" alignItems="center" pr={2}>
      <ArgonBox mr={2}>
        <ArgonAvatar src={image} alt={listingName} variant="rounded" 
        sx={{
          height: '72px',
          width: '72px',
        }}
        />
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonTypography variant="jpBodyBold" className="tr-w-2">
          {listingName}
        </ArgonTypography>
        <ArgonBox  display="flex" alignItems="center">
          <ArgonTypography component="span" variant="jpCaptionRegular" 
            sx={({ palette: { white, natural } }) => ({

              color: darkMode ? white.main : natural.five,
              
            ":after" :{
              content: '"•"',
              padding: '0 0.5rem',
            },
            
            ":last-child:after" :{
              content: '""'
            }
          })}>
            {categoryName}
           
          </ArgonTypography>{" "}
          <ArgonTypography component="span" variant="jpCaptionRegular" 
            sx={({ palette: { white, natural } }) => ({

              color: darkMode ? white.main : natural.five,
              
            ":after" :{
              content: '"•"',
              padding: '0 0.5rem',
            },
            
            ":last-child:after" :{
              content: '""'
            }
          })}>
             {year}
           
          </ArgonTypography>{" "}
          {/* orders */}
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}

// Typechecking props for the NameCell
NameCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  orders: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default NameCell;
