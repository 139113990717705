/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Link, useNavigate } from "react-router-dom";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import moment from "moment";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";
import ArgonInput from "components/ArgonInput";
import { Card, Menu, MenuItem, Switch } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import { useArgonController } from "context";
import CategoriesCard from "examples/Cards/CategoriesCard";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getListingApi } from "Actions/listingAction";
import { useSelector } from "react-redux";
import NameCell from "./components/NameCell";
import ByWhomCell from "./components/ByWhomCell";
import DefaultCell from "./components/DefaultCell";
import ActionCell from "./components/ActionCell";
import api2 from "api2";
import ArgonSnackbar from "components/ArgonSnackbar";
import axios from "axios";
import DeleteModal from "components/Common/DeleteModal";
import StatusActionCell from "../community-reviews/components/StatusActionCell";
import ConfirmationModel from "components/Common/ConfirmationModel";
import { getMyListingApi } from "Actions/listingAction";
import EditorChoice from "./view-listing/editorChoice";
const columns = [
  { Header: "Listing Name", accessor: "name" },
  { Header: "added by", accessor: "addedBy" },
  { Header: "reviewed by", accessor: "reviewedBy" },
  { Header: "date added ", accessor: "dateAdded" },
  { Header: "last updated", accessor: "lastUpdated" },
  { Header: "editor choice", accessor: "editorChoice" },
  { Header: "status", accessor: "status" },
  { Header: "action", accessor: "action" },
];
function Listings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { size } = typography;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [search, setSearch] = useState();
  const [isOpen, setIsOpen] = useState();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState();
  const [openMenu, setOpenMenu] = useState(null);
  const userData = useSelector((state) => state?.authState?.user);
  const [selectedId, setSelectedId] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [updatedId, setUpdatedId] = useState();

  const [deleteId, setIdDelete] = useState();
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const [rows, setRows] = useState([]);
  const listingList = useSelector((state) =>
    userData?.user?.role === 'superadmin' || userData?.user?.role === 'editor'
      ? state?.listingState?.listingList?.data
      : state?.listingState?.myListingList?.data
  );
  const token = useSelector((state) => state?.authState?.user?.token);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(0);

  useEffect(() => {
    if (
      userData?.user?.role === 'superadmin' ||
      userData?.user?.role === 'editor'
    ) {
      dispatch(getListingApi(skip, limit, search));
    } else {
      dispatch(getMyListingApi(search, userData?.user?._id));
    }
  }, [dispatch, search,updatedId]);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem onClick={handleCloseMenu}>Action</MenuItem>
      <MenuItem onClick={handleCloseMenu}>Another action</MenuItem>
      <MenuItem onClick={handleCloseMenu}>Something else here</MenuItem>
    </Menu>
  );
  const handleDelete = (id) => {
    setIsOpen(true);
    setIdDelete(id);
  };
  const handleEdit = (id) => {
    navigate(`/edit-listing/${id}`);
  };
  const handleView = (id) => {
    navigate(`/view-listing/${id}`);
  };
  const handleSuccess = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await api2.delete(`listings/remove/${deleteId}`, config).then((res) => {
        if (res)
          setAlert({
            status: "success",
            isOpen: true,
            alertMsg: "Listing deleted successfully.",
            bgColor: "bggreen",
          });
        setTimeout(() => {
          dispatch(getListingApi(skip, limit));
        }, 1000);
        setIsOpen(false);
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  useEffect(() => {
    const listing = listingList?.listing || listingList;
    const rowsArray =
      listing?.length &&
      listing?.map((item) => {
        const obj = {
          name: (
            <NameCell
              id={item?._id}
              image={item?.thumbnailImg}
              moviename={item?.name}
              categoryName={item?.category?.name}
              releasedYear={item?.yearReleased}
              status={item?.isDraft ? 'IsDraft' : 'IsPublish'}
            />
          ),
          addedBy: (
            <ByWhomCell
              image={item?.createdBy?.profilePicture}
              name={item?.createdBy?.userName}
            />
          ),
          reviewedBy: (
            <ByWhomCell
              name={item?.author?.userName}
              image={item?.author?.profilePicture}
            />
          ),
          dateAdded: (
            <DefaultCell>
              {moment(item?.createdAt).format('MMM Do, yyyy')}
            </DefaultCell>
          ),
          editorChoice: (
            <EditorChoice item={item} setUpdatedId={setUpdatedId} />
          ),
          status: (
            <ArgonTypography
              variant="jpBodyRegular"
              sx={{ textTransform: 'capitalize' }}
            >
              {item?.approvalStatus}
            </ArgonTypography>
          ),
          lastUpdated: (
            <DefaultCell>
              {item?.updatedAt &&
                moment(item?.updatedAt).format('MMM Do, yyyy')}
            </DefaultCell>
          ),
        };
        if (
          userData?.user?.role === 'author' ||
          userData?.user?.role === 'superadmin'
        ) {
          obj['action'] = (
            <ActionCell
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              id={item?._id}
              handleView={handleView}
            />
          );
        }
        if (userData?.user?.role === 'editor') {
          obj['action'] = (
            <StatusActionCell
              item={item}
              setSelectedId={setSelectedId}
              setSelectedStatus={setSelectedStatus}
              setIsConfirmationOpen={setIsConfirmationOpen}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          );
        }
        return obj;
      });
    setRows(rowsArray || []);
  }, [listingList]);

  const handleConfirmation = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await api2
        .put(
          `listings/update/${selectedId}`,
          { approvalStatus: selectedStatus },
          config
        )
        .then((res) => {
          if (res)
            setAlert({
              status: 'success',
              isOpen: true,
              alertMsg: 'Listing updated successfully.',
              bgColor: 'bggreen',
            });
          dispatch(getListingApi(skip, limit));
          setIsConfirmationOpen(false);
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error('Cancelled');
      }
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Card
          sx={{
            p: 2,
          }}
        >
          <ArgonBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            <ArgonBox>
              <ArgonInput
                placeholder="Search Something.."
                startAdornment={
                  <Icon fontSize="large" style={{ marginRight: '6px' }}>
                    search
                  </Icon>
                }
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </ArgonBox>{' '}
            {userData?.user?.role === 'author' ||
            userData?.user?.role === 'superadmin' ||
            userData?.user?.role === 'editor' ? (
              <ArgonButton
                component={Link}
                to="/add-listing"
                color="info"
                size="large"
                sx={{ px: 3 }}
              >
                Add New &nbsp;
                <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
              </ArgonButton>
            ) : (
              <></>
            )}
          </ArgonBox>
        </Card>

        <Card sx={{ mt: 4 }}>
          <ArgonBox p={2}>
            {/* <ArgonButton variant="text" onClick={handleOpenMenu}>
              <ArgonTypography
                variant="jpCaptionMedium"
                display="flex"
                alignItems="center"
                gap={1}
              >
                Add Filter
                <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
              </ArgonTypography>
            </ArgonButton> */}
            {renderMenu()}
          </ArgonBox>

          <ArgonBox>
            <DataTable
              table={{ columns, rows: rows }}
              entriesPerPage={false}
              //   showTotalEntries={false}
            />
          </ArgonBox>
        </Card>
      </ArgonBox>

      <DeleteModal
        deleteContent={'Are you sure want to delete this item ?'}
        isOpenMenu={isOpen}
        setIsOpenMenu={setIsOpen}
        handleSuccess={handleSuccess}
      />
      <ConfirmationModel
        isOpenMenu={isConfirmationOpen}
        setIsOpenMenu={setIsConfirmationOpen}
        handleSuccess={handleConfirmation}
        message={`Are you want to sure to ${selectedStatus} this review.`}
      />
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </DashboardLayout>
  );
}

export default Listings;
