import { Card, Icon, styled } from "@mui/material";
import colors from "assets/theme/base/colors";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";
import { useArgonController } from "context";
import React, { useState } from "react";
import { ReactComponent as EditIcon } from "assets/images/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "assets/images/icons/Trash.svg";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "components/Common/DeleteModal";
import api2 from "api2";
import axios from "axios";
import { getCategoriesListApi } from "Actions/categoryAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ArgonSnackbar from "components/ArgonSnackbar";

const CategoriesCard = ({
  _id,
  name,
  description,
  thumbnail,
  listingCount,
  articleCount,
  handleEdit,
  handleDelete,
  alert,
  setAlert,
}) => {
  // const [alert, setAlert] = useState({
  //   status: "",
  //   isOpen: false,
  //   alertMsg: "",
  //   bgColor: ""
  // })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [isOpen, setIsOpen] = useState(false);

  const EditIconSvg = styled(EditIcon)(({ theme }) => ({
    height: 20,
    width: 20,
    path: {
      fill: theme.palette.natural.eight,
    },
  }));

  const DeleteIconSvg = styled(DeleteIcon)(({ theme }) => ({
    path: {
      fill: theme.palette.error.primary,
    },
  }));

  return (
    <Card sx={{ height: '100%' }}>
      <ArgonBox p={2}>
        <ArgonBox
          sx={{ position: 'relative' }}
          mb={2}
          // component={Link}
          // to={`/edit-category/${_id}`}
          onClick={() => handleEdit(_id)}
        >
          <ArgonBox
            component="img"
            width="100%"
            height="200px"
            borderRadius="md"
            src={thumbnail}
            sx={{
              objectFit: 'cover',
              objectPosition: 'top',
            }}
          />
          <ArgonBox
            sx={({ palette: { white }, functions: { rgba } }) => ({
              position: 'absolute',
              bottom: 16,
              right: 16,
              background: rgba(white.main, 0.24),
              padding: '4px 8px',
              backdropFilter: ' blur(8px)',
              border: `1px solid ${white.main}`,
            })}
            borderRadius="sm"
          >
            <ArgonTypography
              variant="jpBodyMedium"
              sx={({ palette: { white, natural } }) => ({
                color: white.main,
              })}
            >
              {listingCount || articleCount} {name}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <ArgonTypography
            variant="jpH3Bold"
            sx={({ palette: { white, natural } }) => ({
              color: darkMode ? white.main : natural.eight,
            })}
          >
            {name}
          </ArgonTypography>
          <ArgonTypography
            variant="jpBodyRegular"
            sx={({ palette: { white, natural } }) => ({
              color: darkMode ? white.main : natural.five,
            })}
            className="tr-2"
          >
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox mt={2} display="flex" alignItems="center" gap={1}>
          <ArgonButton
            sx={({ palette: { blue } }) => ({
              px: 3,
              width: '90%',
              background: blue.one,
              ':hover': {
                background: blue.one,
              },
              ':focus:not(:hover)': {
                background: blue.six,
              },
            })}
            onClick={() => handleEdit(_id)}
          >
            <EditIconSvg /> &nbsp; Edit
          </ArgonButton>

          <ArgonButton
            iconOnly
            sx={({ palette: { error } }) => ({
              background: error.secondary,
              width: '10%',
              ':hover': {
                background: error.secondary,
              },
              ':focus:not(:hover)': {
                background: error.secondary,
              },
            })}
            onClick={() => setIsOpen(true)}
          >
            <DeleteIconSvg />
          </ArgonButton>
        </ArgonBox>{' '}
      </ArgonBox>
      <DeleteModal
        deleteContent={'Are you sure want to delete this category ?'}
        isOpenMenu={isOpen}
        setIsOpenMenu={setIsOpen}
        handleSuccess={() => {
          setIsOpen(false);
          handleDelete(_id);
        }}
      />
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </Card>
  );
};

export default CategoriesCard;
