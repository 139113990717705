import React, { useEffect, useState } from 'react'

import { Grid, styled, useTheme } from '@mui/material';

import ArgonBox from 'components/ArgonBox';
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import ArgonDatePicker from 'components/ArgonDatePicker';
import FormEditor from 'components/Common/FormEditor';
import FormField from 'components/Common/FormField';
import { useArgonController } from 'context';
import moment from 'moment';
import ArgonTypography from 'components/ArgonTypography';
import { IconPickerItem } from 'react-fa-icon-picker';

export default function CustomFields({ customFieldsList, setCustomFieldsList }) {
  const [rating, setRating] = useState(0)
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const theme = useTheme();
  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 32,
    width: 32,
    cursor: "pointer",
    path: {
      fill: theme.palette.yellow.five,
    },
  }));

  const FullStarBankIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 32,
    width: 32,
    cursor: "pointer",
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
  }));
  
  return (
    <>
      {customFieldsList?.length ? (
        <Grid container spacing={3}>
          {
            customFieldsList?.map((item, index) => (
              <>
                {item?.type === "text" && (
                  <Grid item lg={12} xs={12} sm={12}>
                    <ArgonBox>
                      <FormField
                        type="text"
                        placeholder={`Enter ${item.name}`}
                        size="large"
                        label={item?.name}
                        value={item?.value}
                        onChange={(e) => {
                          const customFields = [...customFieldsList]
                          customFields.splice(index, 1, {
                            ...item,
                            value: e.target.value,
                          })
                          setCustomFieldsList(customFields);
                        }}
                      />
                    </ArgonBox>
                  </Grid>
                )}
                {item?.type === "number" && (
                  <Grid item lg={12} xs={12} sm={12}>
                    <ArgonBox>
                      <FormField
                        type="number"
                        placeholder={`Enter ${item.name}`}
                        size="large"
                        value={item?.value}
                        label={item?.name}
                        onChange={(e) => {
                          const customFields = [...customFieldsList]
                          customFields.splice(index, 1, {
                            ...item,
                            value: e.target.value,
                          })
                          setCustomFieldsList(customFields);
                        }}
                      />
                    </ArgonBox>
                  </Grid>
                )}
                {item?.type === "date" && (
                  <Grid item lg={12} xs={12} sm={12}>
                    <ArgonBox>
                      <ArgonDatePicker
                        label={item?.name}
                        size='large'
                        value={item?.value && moment(item?.value).format("YYYY-MM-DD")}
                        placeholder={`Select ${item?.name}`}
                        onChange={(value) => {
                          const customFields = [...customFieldsList]
                          customFields.splice(index, 1, {
                            ...item,
                            value: value[0]
                          })
                          setCustomFieldsList(customFields);
                        }}
                      />
                    </ArgonBox>
                  </Grid>
                )}
                {item?.type === "rating" && (
                  <Grid item lg={12} xs={12} sm={12}>
                    <ArgonBox>
                      <ArgonTypography
                        component="label"
                        variant="jpCaptionRegular"
                        textTransform="capitalize">

                        {item?.name}
                      </ArgonTypography>
                      <ArgonBox display="flex" alignItems="center">
                        {[...Array(5)].map((x, i) => (
                          <div
                            key={i}
                            onClick={() => {
                              const customFields = [...customFieldsList]
                              customFields.splice(index, 1, {
                                ...item,
                                value: i + 1,
                                icon:item?.icon
                              })
                              setCustomFieldsList(customFields);
                            }}
                          >
                           
                           {i < parseInt(item?.value) ? (
                              <>
                              {item?.icon ? (
                               <IconPickerItem
                                  icon={ item?.icon }
                                  size={ 40 }
                                  color= {theme.palette.yellow.five}
                                />
                             ):(
                              <FullStarFillIconStyled />
                              
                             )}
                             </>
                            ) : (
                              <>
                               {item?.icon ? (
                                <IconPickerItem
                                   icon={ item?.icon  }
                                   size={ 40 }
                                   color='#000'
                                 />
                              ):(
                              <FullStarBankIconStyled />  
                              )}
                              </>
                            )}
                          </div>
                        ))}
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                )}
                {item?.type === "textArea" && (
                  <Grid item lg={12} xs={12} sm={12}>
                    <ArgonBox>
                      <FormEditor
                        label={item?.name}
                        placeholder={`Enter ${item?.name}`}
                        value={item?.value || ''}
                        onChange={(e) => {
                          const customFields = [...customFieldsList]
                          customFields.splice(index, 1, {
                            ...item,
                            value: e,
                          })
                          setCustomFieldsList(customFields);
                        }}
                      />
                    </ArgonBox>
                  </Grid>
                )}

              </>
            ))
          }
        </Grid>
      ) : (
        <></>
      )}
    </>
  )
}
