import ArgonBox from "components/ArgonBox";
import React from "react";
import ArgonTypography from "components/ArgonTypography";
import AmazonIcon from "../../../../../assets/images/icons/AmazonIcon.svg";
import NetFlixIcon from "../../../../../assets/images/icons/NetflixIcon.svg";
import DisneyHotstarIcon from "../../../../../assets/images/icons/DisneyHotstarIcon.svg";
import VootIcon from "../../../../../assets/images/icons/VootIcon.svg";
import Zee5Icon from "../../../../../assets/images/icons/Zee5Icon.svg";
import { Tooltip } from "@mui/material";
import { Link, useLocation } from 'react-router-dom';
const list = {
  movies: 'Watch',
  books: 'Read',
  apps: 'Download',
  games: 'Play',
};

const WhereToWatch = ({ listingInfo }) => {
  const location = useLocation();
  const handleClick = (link) => {
    window.open(link);
  };
  return (
    <>
      <ArgonBox display="flex" flexDirection="column" gap={2}>
        <ArgonTypography variant="jpH3Bold">
          Where to {list[location.pathname.split('/')[1]]}
        </ArgonTypography>

        <ArgonBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          {listingInfo?.whereToWatch?.length ? (
            <>
              {listingInfo?.whereToWatch.map((item) => (
                <Tooltip
                  title={item?.type}
                  sx={{ textTransform: 'capitalize' }}
                >
                  <ArgonBox
                    // component={Link}
                    // to={item?.link}
                    onClick={() => handleClick(item?.link)}
                    sx={{ display: 'inline-block', textAlign: 'center' }}
                  >
                    {item?.type === 'amazon prime' && (
                      <ArgonBox
                        component="img"
                        sx={{
                          maxWidth: '4.5rem',
                          margin: '.25rem',
                          padding: '.25rem',
                          backgroundColor: '#fff',
                          border: '1.5px solid #ccc',
                          borderRadius: '.25rem',
                          boxShadow: 'none',
                        }}
                        src={AmazonIcon}
                        alt=""
                      />
                    )}
                    {item?.type === 'netFlix' && (
                      <ArgonBox
                        component="img"
                        sx={{
                          maxWidth: '4.5rem',
                          margin: '.25rem',
                          padding: '.25rem',
                          backgroundColor: '#fff',
                          border: '1.5px solid #ccc',
                          borderRadius: '.25rem',
                          boxShadow: 'none',
                        }}
                        src={NetFlixIcon}
                        alt=""
                      />
                    )}
                    {item?.type === 'hotstar' && (
                      <ArgonBox
                        component="img"
                        sx={{
                          maxWidth: '4.5rem',
                          margin: '.25rem',
                          padding: '.25rem',
                          backgroundColor: '#fff',
                          border: '1.5px solid #ccc',
                          borderRadius: '.25rem',
                          boxShadow: 'none',
                        }}
                        src={DisneyHotstarIcon}
                        alt=""
                      />
                    )}
                    {item?.type === 'zee5' && (
                      <ArgonBox
                        component="img"
                        sx={{
                          maxWidth: '4.5rem',
                          margin: '.25rem',
                          padding: '.25rem',
                          backgroundColor: '#fff',
                          border: '1.5px solid #ccc',
                          borderRadius: '.25rem',
                          boxShadow: 'none',
                        }}
                        src={Zee5Icon}
                        alt=""
                      />
                    )}
                    {item?.type === 'voot' && (
                      <ArgonBox
                        component="img"
                        sx={{
                          maxWidth: '4.5rem',
                          margin: '.25rem',
                          padding: '.25rem',
                          backgroundColor: '#fff',
                          border: '1.5px solid #ccc',
                          borderRadius: '.25rem',
                          boxShadow: 'none',
                        }}
                        src={VootIcon}
                        alt=""
                      />
                    )}
                  </ArgonBox>
                </Tooltip>
              ))}
            </>
          ) : (
            <>
              <ArgonTypography variant="jpBodyMedium">
                This title is not currently available to buy or stream.
              </ArgonTypography>
            </>
          )}
        </ArgonBox>
      </ArgonBox>
    </>
  );
};

export default WhereToWatch;
