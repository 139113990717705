/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";
import ArgonInput from "components/ArgonInput";
import { Card, Switch, styled } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import { useArgonController } from "context";
import CategoriesCard from "examples/Cards/CategoriesCard";
import ArgonDropzone from "components/ArgonDropzone";
import ImageUploadCard from "examples/Cards/ImageUploadCard";
import { useEffect, useRef } from "react";
import FormField from "components/Common/FormField";
import ArgonEditor from "components/ArgonEditor";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as TextFileIcon } from "assets/images/icons/TextFile.svg";
import { ReactComponent as DeleteIcon } from "assets/images/icons/Trash.svg";
import ArgonSelect from "components/ArgonSelect";
import FormSelect from "components/Common/FormSelect";
// import FormEditor from "components/Common/FormEditor";
import { useDispatch } from "react-redux";
import api2 from "api2";
import axios from "axios";
import FormEditor from "components/Common/FormEditor";
import { useSelector } from "react-redux";
import { getCategoriesListApi } from "Actions/categoryAction";
import { getUserByRole } from "Actions/categoryAction";
import { addListingApi } from "Actions/listingAction";
import ArgonSnackbar from "components/ArgonSnackbar";
import { updateListingApi } from "Actions/listingAction";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import CloseIcon from '@mui/icons-material/Close';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import CustomFields from "../CustomFields";
import InternalReviews from "../InternalReviews";
const platformType = [
  {
    value: 'amazon prime',
    label: 'Amazon Prime',
  },
  {
    value: 'netFlix',
    label: 'Netflix',
  },
  {
    value: 'hotstar',
    label: 'Hotstar',
  },
  {
    value: 'zee5',
    label: 'Zee5',
  },
  {
    value: 'voot',
    label: 'Voot',
  },
];

function AddListing() {
  const { size } = typography;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { id } = useParams();
  const thumbnailRef = useRef();
  const coverRef = useRef();
  const mediaRef = useRef();
  const [categoryList, setCategoryList] = useState([]);
  const [authorList, setAuthorList] = useState([]);
  const [productPurchases, setProductPurchases] = useState({
    review: '',
    rating: 0,
  });
  const [drinkingDrugsSmokingReview, setDrinkingDrugsSmokingReview] =
    useState('');
  const [drinkingDrugsSmokingRating, setDrinkingDrugsSmokingRating] =
    useState(0);
  const [languageReview, setLanguageReview] = useState('');
  const [languageRating, setLanguageRating] = useState('');
  const [sexRomanceNudityReview, setSexRomanceNudityReview] = useState('');
  const [sexRomanceNudityRating, setSexRomanceNudityRating] = useState(0);
  const [violenceAndScarinessReview, setViolenceAndScarinessReview] =
    useState('');
  const [violenceAndScarinessRating, setViolenceAndScarinessRating] =
    useState(0);
  const [diverseRepresentationReview, setDiverseRepresentationReview] =
    useState('');
  const [diverseRepresentationRating, setDiverseRepresentationRating] =
    useState(0);
  const [positiveRoleModelsReview, setPositiveRoleModelsReview] = useState('');
  const [positiveRoleModelsRating, setPositiveRoleModelsRating] = useState(0);
  const [listingInformationReview, setListingInformationReview] = useState('');
  const [listingInformationRating, setListingInformationRating] = useState(0);
  const [productPurchasesRating, setProductPurchasesRating] = useState(0);
  const [productPurchasesReview, setProductPurchasesReview] = useState('');
  const [talkToYourKidsAbout, setTalkToYourKidsAbout] = useState('');
  const [isItAnyGood, setIsItAnyGood] = useState('');
  const [editorValue, setEditorValue] = useState('');
  const [whatTheStory, setWhatTheStory] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [logDescription, setLogDescription] = useState('');
  const [customFieldsList, setCustomFieldsList] = useState([]);
  const [internalReviewFieldsList, setInternalReviewFieldsList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const categories = useSelector((state) => state.categoryState.categoriesList);
  const userData = useSelector((state) => state?.authState?.user);
  const authors = useSelector(
    (state) => state?.categoryState?.authorList?.data?.userList
  );
  const [isFeatured, setIsFeatured] = useState(true);
  const [listingInfo, setListingInfo] = useState();
  const token = useSelector((state) => state?.authState?.user?.token);
  const [showError, setShowError] = useState(false);
  const [thumbnailImg, setThumbnailImg] = useState();
  const [coverImg, setCoverImg] = useState();
  const [coverLoader, setCoverLoader] = useState(false);
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  const [mediaLoader, setMediaLoader] = useState(false);
  const [isOpenLightBox, setIsOpenLightBox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [whereToWatch, setWhereToWatch] = useState([
    {
      link: '',
      type: '',
    },
  ]);
  const GenreList = [
    {
      label: 'Action',
      value: 'action',
    },
    {
      label: 'Comedy',
      value: 'comedy',
    },
    {
      label: 'Romance',
      value: 'romance',
    },
    {
      label: 'Thriller',
      value: 'thriller',
    },
  ];
  const [alert, setAlert] = useState({
    status: '',
    isOpen: false,
    alertMsg: '',
    bgColor: '',
  });
  const DeleteIconSvg = styled(DeleteIcon)(({ theme }) => ({
    path: {
      fill: theme.palette.error.primary,
    },
  }));
  const formatValue = (value) => {
    const valueArray = [];
    value.genreList.forEach((item) => {
      const matchValue = GenreList.find((i) => i?.value === item);
      const existingValue = valueArray.find(
        (i) => i?.value === matchValue?.value
      );
      if (!existingValue && matchValue) {
        valueArray.push(matchValue);
      }
    });
    return valueArray;
  };
  const fetchListing = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let result = await api2.get(`listings/${id}`, config).then((res) => {
        setListingInfo({
          ...res?.data?.data,
          genreList: formatValue(res?.data?.data),
        });
        setProductPurchasesReview(res?.data?.data.drinkingDrugsSmoking.review);
        setProductPurchasesRating(res?.data?.data.drinkingDrugsSmoking.rating);
        setDrinkingDrugsSmokingReview(
          res?.data?.data.drinkingDrugsSmoking.review
        );
        setDrinkingDrugsSmokingRating(
          res?.data?.data.drinkingDrugsSmoking.rating
        );
        setLanguageReview(res?.data?.data.language.review);
        setLanguageRating(res?.data?.data.language.rating);
        setSexRomanceNudityReview(res?.data?.data.sexRomanceNudity.review);
        setSexRomanceNudityRating(res?.data?.data.sexRomanceNudity.rating);
        setViolenceAndScarinessReview(
          res?.data?.data.violenceAndScariness.review
        );
        setViolenceAndScarinessRating(
          res?.data?.data.violenceAndScariness.rating
        );
        setListingInformationReview(res?.data?.data.listingInformation.review);
        setListingInformationRating(
          res?.data?.data.violenceAndScariness.rating
        );
        setDiverseRepresentationReview(
          res?.data?.data.diverseRepresentation.review
        );
        setDiverseRepresentationRating(
          res?.data?.data.diverseRepresentation.rating
        );
        setPositiveRoleModelsReview(res?.data?.data.positiveRoleModels.review);
        setPositiveRoleModelsRating(res?.data?.data.positiveRoleModels.rating);
        setShortDescription(res?.data?.data.shortDescription || '');
        setLogDescription(res?.data?.data.logDescription || '');
        setWhatTheStory(res?.data?.data.whatTheStory || '');
        setIsItAnyGood(res?.data?.data.isItAnyGood || '');
        setTalkToYourKidsAbout(res?.data?.data.talkToYourKidsAbout || '');
        setCustomFieldsList(res?.data?.data?.customFieldsList);
        setInternalReviewFieldsList(res?.data?.data?.internalReviewFieldsList);
        setThumbnailImg(res?.data?.data?.thumbnailImg);
        setCoverImg(res?.data?.data?.coverImg);
        setIsFeatured(res?.data?.data?.isFeatured);
        setMediaList(res?.data?.data?.media);
        setWhereToWatch(res?.data?.data?.whereToWatch);
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error('Cancelled');
      }
    }
  };
  useEffect(() => {
    if (id) {
      fetchListing();
    }
  }, []);
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setListingInfo({
      ...listingInfo,
      [name]: value,
    });
  };
  const handleUpload = async (fieldName, file) => {
    try {
      const config = {
        headers: { Authorization: token },
      };
      let formData = new FormData();
      formData.append('file', file);
      let result = await api2.post(`imageUpload`, formData, config);
      if (fieldName === 'thumbnailImg') {
        setThumbnailLoader(false);
        setThumbnailImg(result?.data?.data);
      }
      if (fieldName === 'coverImg') {
        setCoverLoader(false);
        setCoverImg(result?.data?.data);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setAlert({
          isOpen: true,
          status: 'Error',
          alertMsg: "can't upload image",
          bgColor: 'bgred',
        });
        throw new Error('Cancelled');
      }
    }
  };
  useEffect(() => {
    let authorArray = [];
    authorArray =
      authors?.length &&
      authors.map((item) => {
        return {
          label: item?.userName,
          value: item?._id,
        };
      });
    setAuthorList(authorArray || []);
  }, [authors]);
  useEffect(() => {
    let categoryListArray = [];
    categoryListArray =
      categories.length &&
      categories.map((item) => {
        return {
          label: item?.name,
          value: item?._id,
        };
      });
    setCategoryList(categoryListArray);
  }, [categories]);
  useEffect(() => {
    dispatch(getCategoriesListApi());
    if (userData?.user?.role === 'superadmin') {
      dispatch(getUserByRole(['author']));
    }
  }, [dispatch]);
  const handleClick = (e, isDraft) => {
    setShowError(true);
    if (
      listingInfo?.name &&
      listingInfo?.age &&
      listingInfo?.category &&
      listingInfo?.yearReleased &&
      listingInfo?.genreList
    ) {
      const payload = {
        ...isDraft,
        name: listingInfo?.name,
        age: listingInfo?.age,
        author:
          userData?.user?.role === 'superadmin'
            ? listingInfo?.author?._id || listingInfo?.author
            : userData?.user?._id,
        category: listingInfo?.category?._id || listingInfo?.category,
        yearReleased: listingInfo?.yearReleased,
        thumbnailImg: thumbnailImg,
        coverImg: coverImg,
        isFeatured: isFeatured,
        customFieldsList:
          customFieldsList?.length &&
          customFieldsList.map((item) => {
            return {
              name: item?.name,
              value: item?.value,
              type: item?.type,
              icon: item?.icon,
            };
          }),
        internalReviewFieldsList:
          internalReviewFieldsList?.length &&
          internalReviewFieldsList.map((item) => {
            return {
              name: item?.name,
              value: item?.value,
              rating: item?.rating,
            };
          }),
        shortDescription: shortDescription,
        logDescription: logDescription,
        whatTheStory: whatTheStory,
        genreList: listingInfo?.genreList.map((i) => i.value),
        isItAnyGood: isItAnyGood,
        media: mediaList,
        talkToYourKidsAbout: talkToYourKidsAbout,
        listingInformation: {
          review: listingInformationReview,
          rating: listingInformationRating,
        },
        positiveRoleModels: {
          review: positiveRoleModelsReview,
          rating: positiveRoleModelsRating,
        },
        diverseRepresentation: {
          review: diverseRepresentationReview,
          rating: diverseRepresentationRating,
        },
        violenceAndScariness: {
          review: violenceAndScarinessReview,
          rating: violenceAndScarinessRating,
        },
        sexRomanceNudity: {
          review: sexRomanceNudityReview,
          rating: sexRomanceNudityRating,
        },
        language: {
          review: languageReview,
          rating: languageRating,
        },
        drinkingDrugsSmoking: {
          review: drinkingDrugsSmokingReview,
          rating: drinkingDrugsSmokingRating,
        },
        productPurchases: {
          review: productPurchasesReview,
          rating: productPurchasesRating,
        },
        whereToWatch: whereToWatch,
        approvalStatus: 'approved',
      };

      if (id) {
        dispatch(updateListingApi(payload, id));
        setAlert({
          ...alert,
          status: 'success',
          isOpen: true,
          alertMsg: 'Listing update successfully.',
          bgColor: 'bggreen',
        });
      } else {
        dispatch(addListingApi(payload));
        setAlert({
          ...alert,
          status: 'success',
          isOpen: true,
          alertMsg: 'Listing added successfully.',
          bgColor: 'bggreen',
        });
      }
      setTimeout(() => {
        navigator('/listings');
      }, 500);
    }
  };
  const TextFileIconSvg = styled(TextFileIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.blue.seven,
    },
  }));
  const handleEditor = (filedName, value) => {
    setListingInfo({
      ...listingInfo,
      [filedName]: value,
    });
  };
  const yearReleased = [
    { label: '2015', value: '2015' },
    { label: '2016', value: '2016' },
    { label: '2017', value: '2017' },
    { label: '2018', value: '2018' },
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
  ];
  const handleChangesCustomFields = (e) => {
    const { name, value } = e.target;
    const listingInfoArray = listingInfo;
    listingInfoArray.customFields = {
      ...listingInfoArray.customFields,
      [name]: value,
    };
    setListingInfo(listingInfoArray);
  };
  const handleMediaUpload = async (file) => {
    const mediaListArray = [...mediaList];
    try {
      const config = {
        headers: { Authorization: token },
      };
      let formData = new FormData();
      formData.append('file', file);
      let result = await api2.post(`imageUpload`, formData, config);
      mediaListArray.push(result?.data?.data);
      setMediaList(mediaListArray);
      setMediaLoader(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        setAlert({
          isOpen: true,
          status: 'error',
          alertMsg: "can't upload image",
          bgColor: 'bgred',
        });
        throw new Error('Cancelled');
      }
    }
  };
  const handleRemove = (index) => {
    const mediaListArray = [...mediaList];
    mediaListArray.splice(index, 1);
    setMediaList(mediaListArray);
  };
  const handleAddFields = () => {
    const whereToWatchArray = [...whereToWatch];
    whereToWatchArray.push({
      name: '',
      type: '',
    });
    setWhereToWatch(whereToWatchArray);
  };
  const handleDelete = (index) => {
    const whereToWatchArray = [...whereToWatch];
    whereToWatchArray.splice(index, 1);
    setWhereToWatch(whereToWatchArray);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={4}>
        <Grid container direction="row" spacing={4} sx={{}}>
          <Grid item xs={12} md={12} lg={9}>
            <Card sx={{ p: 4, overflow: 'visible' }}>
              <ArgonBox display="flex" alignItems="center" gap={1} mb={4}>
                <TextFileIconSvg />
                <ArgonTypography
                  variant="jpH3Bold"
                  sx={({ palette: { blue, white } }) => ({
                    color: darkMode ? white.main : blue.seven,
                  })}
                >
                  Listing Information
                </ArgonTypography>
              </ArgonBox>

              <Grid container spacing={3}>
                <Grid
                  item
                  lg={4}
                  sm={12}
                  xs={12}
                  onClick={() => {
                    thumbnailRef?.current.childNodes[0].click();
                  }}
                >
                  <ImageUploadCard
                    url={thumbnailImg}
                    title={'Choose Thumbnail'}
                    isLoading={thumbnailLoader}
                  />
                  <ArgonBox
                    ref={thumbnailRef}
                    sx={{ position: 'relative', display: 'none' }}
                  >
                    <ArgonDropzone
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                      options={{
                        onDrop: (acceptedFiles) => {
                          setThumbnailLoader(true);
                          handleUpload('thumbnailImg', acceptedFiles[0]);
                        },
                        accept: { 'image/*': [] },
                      }}
                    />
                  </ArgonBox>
                </Grid>
                <Grid
                  item
                  lg={8}
                  sm={12}
                  xs={12}
                  onClick={() => {
                    coverRef?.current.childNodes[0].click();
                  }}
                >
                  <ImageUploadCard
                    url={coverImg}
                    title={'Choose Thumbnail'}
                    isLoading={coverLoader}
                  />
                  <ArgonBox
                    ref={coverRef}
                    sx={{ position: 'relative', display: 'none' }}
                  >
                    <ArgonDropzone
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                      options={{
                        onDrop: (acceptedFiles) => {
                          setCoverLoader(true);
                          handleUpload('coverImg', acceptedFiles[0]);
                        },
                        accept: { 'image/*': [] },
                      }}
                    />
                  </ArgonBox>
                </Grid>
              </Grid>

              <ArgonBox component="form" role="form" mt={4}>
                <Grid container spacing={3}>
                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormField
                        type="text"
                        placeholder="Your Listing Title"
                        size="large"
                        label="Listing Title"
                        value={listingInfo?.name}
                        name="name"
                        onChange={handleChanges}
                        error={showError && !listingInfo?.name}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormSelect
                        options={categoryList}
                        placeholder="Listing Category"
                        size="large"
                        label="Category"
                        value={
                          categoryList.length &&
                          categoryList?.find(
                            (item) =>
                              item.value === listingInfo?.category ||
                              item.value === listingInfo?.category?._id
                          )
                        }
                        onChange={(selectedValue) => {
                          setListingInfo({
                            ...listingInfo,
                            category: selectedValue?.value,
                          });
                          const selectedCategory = categories.find(
                            (item) => item._id === selectedValue.value
                          );
                          setCustomFieldsList(selectedCategory?.customFields);
                          setInternalReviewFieldsList(
                            selectedCategory?.internalReviewFields
                          );
                        }}
                        error={showError && !listingInfo?.category}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormSelect
                        options={yearReleased}
                        placeholder="Listing Year Released"
                        size="large"
                        label="Year Released"
                        value={yearReleased.find(
                          (item) => item.value === listingInfo?.yearReleased
                        )}
                        error={showError && !listingInfo?.yearReleased}
                        onChange={(selectedValue) => {
                          handleEditor('yearReleased', selectedValue.value);
                        }}
                      />
                    </ArgonBox>
                  </Grid>
                  {userData?.user?.role === 'superadmin' && (
                    <Grid item lg={6} xs={12} sm={12}>
                      <ArgonBox>
                        <FormSelect
                          options={authorList}
                          placeholder="Your Author"
                          size="large"
                          label="Author"
                          name="publisher"
                          value={
                            authorList.length &&
                            authorList?.find(
                              (item) => item.value === listingInfo?.author?._id
                            )
                          }
                          error={showError && !listingInfo?.author}
                          onChange={(selectedValue) => {
                            handleEditor('author', selectedValue.value);
                          }}
                        />
                      </ArgonBox>
                    </Grid>
                  )}

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormField
                        type="number"
                        placeholder="Your Age"
                        size="large"
                        label="Age"
                        name="age"
                        error={showError && !listingInfo?.age}
                        value={listingInfo?.age}
                        onChange={handleChanges}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormSelect
                        isMulti
                        options={GenreList}
                        placeholder="Your Genre"
                        size="large"
                        label="Genre"
                        error={showError && !listingInfo?.genreList}
                        value={listingInfo?.genreList}
                        onChange={(selectedValue) => {
                          handleEditor('genreList', selectedValue);
                        }}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox
                      display="flex"
                      alignItems="center"
                      justifyContent=""
                      gap={1}
                    >
                      <ArgonTypography
                        component="label"
                        variant="jpCaptionRegular"
                        textTransform="capitalize"
                      >
                        Featured Image
                      </ArgonTypography>
                      <Switch
                        checked={isFeatured}
                        onChange={(e) => setIsFeatured(e.target.checked)}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}></Grid>

                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        label="Short Description"
                        value={shortDescription}
                        onChange={(value) => {
                          setShortDescription(value);
                        }}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        label="Long Description"
                        value={logDescription}
                        onChange={(value) => {
                          setLogDescription(value);
                        }}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        label="What's it about ?"
                        value={whatTheStory}
                        onChange={(value) => {
                          setWhatTheStory(value);
                        }}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        label="Is It Any Good ?"
                        value={isItAnyGood}
                        onChange={(value) => {
                          setIsItAnyGood(value);
                        }}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        label="Talk To Your Kids About"
                        value={talkToYourKidsAbout}
                        onChange={(value) => {
                          setTalkToYourKidsAbout(value);
                        }}
                      />
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>

              <ArgonBox my={3}>
                <CustomFields
                  customFieldsList={customFieldsList}
                  setCustomFieldsList={setCustomFieldsList}
                />
              </ArgonBox>

              <ArgonBox my={3}>
                <ArgonTypography
                  variant="jpH3Bold"
                  sx={({ palette: { blue, white } }) => ({
                    color: darkMode ? white.main : blue.seven,
                  })}
                >
                  Photos and Videos
                </ArgonTypography>

                <Grid container my={1} spacing={3}>
                  {mediaList.length ? (
                    <>
                      {mediaList.map((item, index) => (
                        <>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            xl={3}
                            sx={{
                              position: 'relative',
                            }}
                            onClick={() => setIsOpen(true)}
                          >
                            <ArgonBox
                              component="img"
                              src={item}
                              alt=""
                              sx={({
                                borders: { borderWidth, borderColor },
                              }) => ({
                                width: '100%',
                                height: '190px',
                                aspectRatio: '16/9',
                                borderRadius: '1rem',
                                border: `${borderWidth[1]} solid ${borderColor}`,
                              })}
                              onClick={() => {
                                setIsOpenLightBox(true);
                              }}
                            />
                            <ArgonBox
                              sx={({ palette: { blue, white } }) => ({
                                position: 'absolute',
                                background: blue.five,
                                top: 10,
                                right: -10,
                                padding: 1,
                                borderRadius: '100px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              })}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemove(index);
                              }}
                            >
                              <CloseIcon color="white" />
                            </ArgonBox>
                          </Grid>
                        </>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                  <input
                    ref={mediaRef}
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/png,image/webp, image/jpeg, image/jpg"
                    onChange={(e) => {
                      setMediaLoader(true);
                      handleMediaUpload(e.target.files[0]);
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    md={4}
                    xl={3}
                    sx={{
                      height: '210px',
                      cursor: 'pointer',
                    }}
                  >
                    <PlaceholderCard
                      title={{ variant: 'h5', text: 'Add photos or videos' }}
                      mediaLoader={mediaLoader}
                      outlined
                      onClick={() => {
                        mediaRef?.current.click();
                      }}
                    />
                  </Grid>
                </Grid>
              </ArgonBox>
              <ArgonBox display="flex" justifyContent="space-between" gap={3}>
                <ArgonTypography
                  component="label"
                  variant="jpCaptionRegular"
                  textTransform="capitalize"
                ></ArgonTypography>
                <ArgonButton color="info" onClick={handleAddFields}>
                  Add
                </ArgonButton>
              </ArgonBox>
              <ArgonTypography
                variant="jpH3Bold"
                sx={({ palette: { blue, white } }) => ({
                  color: darkMode ? white.main : blue.seven,
                })}
              >
                Where to Watch
              </ArgonTypography>
              {whereToWatch.length ? (
                <>
                  {whereToWatch.map((item, index) => (
                    <ArgonBox display="flex" alignItems="flex-end" gap={3}>
                      <ArgonBox sx={{ width: '70%' }}>
                        <FormField
                          type="text"
                          placeholder="Write here..."
                          size="large"
                          value={item?.link}
                          onChange={(e) => {
                            const whereToWatchArray = [...whereToWatch];
                            whereToWatchArray.splice(index, 1, {
                              ...item,
                              link: e.target.value,
                            });
                            setWhereToWatch(whereToWatchArray);
                          }}
                        />
                      </ArgonBox>
                      {console.log(
                        'first',
                        platformType.find((i) => i.value === item?.type)
                      )}
                      <ArgonBox>
                        <FormSelect
                          options={platformType}
                          placeholder="Select input type"
                          size="large"
                          value={platformType.find(
                            (i) => i.value === item?.type
                          )}
                          onChange={(selectedValue) => {
                            const whereToWatchArray = [...whereToWatch];
                            whereToWatchArray.splice(index, 1, {
                              ...item,
                              type: selectedValue.value,
                            });
                            setWhereToWatch(whereToWatchArray);
                          }}
                        />
                      </ArgonBox>

                      <ArgonButton
                        iconOnly
                        size="large"
                        sx={({ palette: { error } }) => ({
                          background: error.secondary,
                          ':hover': {
                            background: error.secondary,
                          },
                          ':focus:not(:hover)': {
                            background: error.secondary,
                          },
                        })}
                        onClick={() => handleDelete(index)}
                      >
                        <DeleteIconSvg />
                      </ArgonButton>
                    </ArgonBox>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Card>

            {internalReviewFieldsList?.length ? (
              <Card sx={{ p: 4, my: 4 }}>
                <InternalReviews
                  internalReviewFieldsList={internalReviewFieldsList}
                  setInternalReviewFieldsList={setInternalReviewFieldsList}
                />
              </Card>
            ) : (
              <></>
            )}
          </Grid>

          <Grid item xs={12} md={12} lg={3}>
            <Card sx={{ p: 2 }}>
              <ArgonBox
                mb={1}
                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
              >
                <ArgonButton
                  color="info"
                  size="large"
                  fullWidth
                  onClick={(e) => handleClick(e, { isDraft: false })}
                >
                  Publish
                </ArgonButton>
                {listingInfo?.isDraft !== false && (
                  <ArgonButton
                    size="large"
                    sx={({ palette: { blue } }) => ({
                      background: blue.one,
                      ':hover': {
                        background: blue.one,
                      },
                      ':focus:not(:hover)': {
                        background: blue.one,
                      },
                    })}
                    fullWidth
                    onClick={(e) => handleClick(e, { isDraft: true })}
                  >
                    Save Draft
                  </ArgonButton>
                )}

                <ArgonButton
                  size="large"
                  sx={({ palette: { error } }) => ({
                    background: error.secondary,
                    color: error.primary,
                    ':hover': {
                      background: error.secondary,
                    },
                    ':focus:not(:hover)': {
                      background: error.secondary,
                    },
                  })}
                  fullWidth
                  component={Link}
                  to="/listings"
                >
                  Cancel
                </ArgonButton>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
      {isOpen && (
        <Lightbox
          mainSrc={mediaList[photoIndex]}
          nextSrc={mediaList[(photoIndex + 1) % mediaList.length]}
          prevSrc={
            mediaList[(photoIndex + mediaList.length - 1) % mediaList.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + mediaList.length - 1) % mediaList.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % mediaList.length)
          }
        />
      )}
    </DashboardLayout>
  );
}

export default AddListing;
