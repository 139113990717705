import React, { useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI base styles
import { Card, styled } from "@mui/material";
import { useArgonController } from "context";
import borders from "assets/theme/base/borders";

import { ReactComponent as EmailIcon } from "assets/images/icons/Email.svg";
import { ReactComponent as CalendarIcon } from "assets/images/icons/Calendar.svg";
import { useSelector } from "react-redux";
import moment from "moment";

const ProfileCard = () => {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { borderRadius } = borders;
  const EmailIconSvg = styled(EmailIcon)(({ theme }) => ({
    height: 20,
    width: 20,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.five,
    },
  }));

  const CalendarIconSvg = styled(CalendarIcon)(({ theme }) => ({
    height: 20,
    width: 20,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.five,
    },
  }));
  const userData = useSelector((state) =>state?.authState?.user?.user)


  return (
    <>
      <Card sx={{ p: 4, width: "100%", height: "100%" }}>
        <ArgonBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <ArgonTypography variant="jpH3Bold">User Detail</ArgonTypography>

          <ArgonBox
            mt={4}
            sx={{
              width: "100%",
            }}
          >
            <ArgonBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <ArgonBox
                component="img"
                src={userData?.profilePicture}
                alt="user-img"
                sx={{
                  height: 80,
                  width: 80,
                  borderRadius: borderRadius.md,
                  objectFit: "cover",
                }}
                mb={2}
              />

              <ArgonTypography variant="jpH3Bold">{userData?.userName}</ArgonTypography>
              <ArgonBox display="flex" alignItems="center" gap={0.5}>
                <EmailIconSvg />
                <ArgonTypography
                  variant="jpCaptionRegular"
                  sx={({ palette: { natural, white } }) => ({
                    color: darkMode ? white.main : natural.five,
                  })}
                >
                  {userData?.email}
                </ArgonTypography>
              </ArgonBox>

              <Grid
                container
                sx={{
                  width: "100%",
                  mt: 4,
                }}
              >
                <Grid
                  sx={({ palette: { natural } }) => ({
                    width: "100%",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 1,
                    border: `1px solid ${natural.three}`,
                    borderRadius: `${borderRadius.md} 0 0 ${borderRadius.md}`,

                    "@media (max-width: 768px)": {
                      borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,
                    },
                  })}
                  item
                  md={6}
                >
                  <ArgonTypography variant="jpCaptionRegular">
                    Role
                  </ArgonTypography>
                  <ArgonTypography variant="jpBodyBold">{userData?.role}</ArgonTypography>
                </Grid>

                <Grid
                  item
                  md={6}
                  sx={({ palette: { natural } }) => ({
                    width: "100%",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 1,
                    border: `1px solid ${natural.three}`,
                    borderRadius: ` 0 ${borderRadius.md} ${borderRadius.md} 0`,
                    "@media (max-width: 768px)": {
                      borderRadius: `0 0 ${borderRadius.md} ${borderRadius.md} `,
                    },
                  })}
                >
                  <ArgonTypography variant="jpCaptionRegular">
                    Status
                  </ArgonTypography>

                  <ArgonBox
                    display="flex"
                    alignItems="center"
                    gap={1}
                    sx={({ palette: { natural } }) => ({
                      padding: "2px 8px 2px 6px",
                      width: "fit-content",
                      border: `1px solid ${natural.three}`,
                      borderRadius: borderRadius.md,
                    })}
                  >
                    <ArgonBox
                      component="i"
                      sx={({ palette: { blue } }) => ({
                        background: blue.five,
                        width: "12px",
                        height: "12px",
                        borderRadius: "3px",
                        display: "inline-block",
                      })}
                    />
                    <ArgonBox display="flex" flexDirection="column">
                      <ArgonTypography variant="jpBodyMedium">
                      {userData?.isActive ?  "Active" :"DeActive"}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
              </Grid>

              <ArgonBox
                sx={({ palette: { natural, blue } }) => ({
                  background: darkMode ? blue.five : natural.two,
                  padding: "4px 8px",
                  borderRadius: borderRadius.sm,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 0.5,
                  mt: 4,
                })}
              >
                <CalendarIconSvg />
                <ArgonBox display="flex" gap={0.5}>
                  <ArgonTypography
                    variant="jpCaptionRegular"
                    sx={({ palette: { natural, white } }) => ({
                      color: darkMode ? white.main : natural.five,
                    })}
                  >
                    Last Logged
                  </ArgonTypography>{" "}
                  <ArgonTypography variant="jpCaptionRegular">
                    {moment(userData?.lastLogin).format("dddd, Do MMM YYYY")}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </>
  );
};

export default ProfileCard;
