/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useEffect, useState } from "react";
import { Grid, Grow, Popper } from "@mui/material";
import { useArgonController } from "context";
import borders from "assets/theme/base/borders";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getArticleCategoriesListApi } from "Actions/categoryAction";

function DefaultNavbarDropdown() {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownEl, setDropdownEl] = useState("");
  const [nestedDropdown, setNestedDropdown] = useState("");
  const [dropdownName, setDropdownName] = useState("");
  const [arrowRef, setArrowRef] = useState(null);
  const [byTopic, setByTopic] = useState(null);
  const [byPlatform, setByPlatform] = useState(null);
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { borderRadius } = borders;
  useEffect(() => {
    dispatch(getArticleCategoriesListApi());
  }, []);
  const articleCategories = useSelector(
    (state) => state.categoryState.articleCategoriesList || []
  );
  useEffect(()=>{
    const topics= articleCategories.length && articleCategories.filter((item)=>item?.categoryType === "topic") || []
    const platform= articleCategories.length && articleCategories.filter((item)=>item?.categoryType === "platform") || []
    setByPlatform(platform)
    setByTopic(topics)
  },[articleCategories])
   const renderMenu = () => (
    <Popper
      anchorEl={isOpen}
      popperRef={null}
      open={Boolean(isOpen)}
      placement="top-start"
      transition
      style={{ zIndex: 999 }}
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
          },
        },
      ]}
      onMouseEnter={() => setIsOpen(dropdownEl)}
      onMouseLeave={() => {
        if (!nestedDropdown) {
          setIsOpen(null);
          setDropdownName("");
        }
      }}
      // Menu
      // anchorEl={isOpen}
      // anchorReference={null}
      // anchorOrigin={{
      //   vertical: "bottom",
      //   horizontal: "left",
      // }}
      // open={Boolean(isOpen)}
      // // onClose={handleCloseMenu}
      // sx={{ mt: 1.5, width: "100%" }}
    >
      {/* <MenuList sx={{ py: 1, mx: 2, width: "255px" }}> */}
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: "left top",
            background: ({ palette: { white, background } }) =>
              darkMode ? background.dark : white.main,
            minWidth: "300px",
            height: "100%",
          }}
        >
          <ArgonBox borderRadius="lg" height={"100%"}>
            <ArgonTypography
              variant="h1"
              sx={({ palette: { white, background } }) => ({
                color: darkMode ? background.dark : white.main,
              })}
            >
              <Icon ref={setArrowRef} sx={{ mt: -3 }}>
                arrow_drop_up
              </Icon>
            </ArgonTypography>
            <ArgonBox shadow="lg" borderRadius="lg" p={1.625} mt={1}>
              <Grid container>
                <Grid item xs={6}>
                  <ArgonTypography fontWeight="bold">By Topic</ArgonTypography>
                  <ArgonBox
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    my={1}
                  >
                    {byTopic.length ? (
                      <>
                      {
                        byTopic.map((item)=>(
                    <ArgonTypography
                      sx={({ palette: { natural, blue } }) => ({
                        textTransform: "capitalize",
                        cursor: "pointer",
                        ":hover": {
                          color: !darkMode ? natural.five : blue.seven,
                        },
                      })}
                      component={Link}
                      to={`/all-article/${item?._id}`}
                      variant="jpBodyMedium"
                    >
                      {item?.name}
                    </ArgonTypography>

                        ))
                      }
                      </>
                    ):(
                      <></>
                    )}
                  </ArgonBox>
                </Grid>

                <Grid item xs={6}>
                  <ArgonTypography fontWeight="bold">
                    By Platform
                  </ArgonTypography>
                  <ArgonBox
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    my={1}
                  >
                        {byPlatform.length ? (
                      <>
                      {byPlatform.map((item)=>(
                    <ArgonTypography
                      sx={({ palette: { natural, blue } }) => ({
                        textTransform: "capitalize",
                        cursor: "pointer",
                        ":hover": {
                          color: !darkMode ? natural.five : blue.seven,
                        },
                      })}
                      component={Link}
                      to={`/all-article/${item?._id}`}
                      variant="jpBodyMedium"
                    >
                      {item?.name}
                    </ArgonTypography>

                        ))
                      }
                      </>
                    ):(
                      <></>
                    )}
                  </ArgonBox>
                </Grid>
              </Grid>
            </ArgonBox>
          </ArgonBox>
        </Grow>
      )}

      {/* </MenuList> */}
    </Popper>
  );

  return (
    <ArgonBox>
      <ArgonBox
        display="flex"
        alignItems="baseline"
        onMouseEnter={({ currentTarget }) => {
          setIsOpen(currentTarget);
          setDropdownEl(currentTarget);
        }}
        onMouseLeave={() => setIsOpen(null)}
        sx={({ palette: { white, blue } }) => ({
          padding: "10px 20px",
          borderRadius: borderRadius.md,
          cursor: "pointer",
          userSelect: "none",
          color: darkMode ? white.main : white.main,

          ":hover": {
            backgroundColor: white.main,
          },
          ":hover span": {
            color: blue.five,
          },
        })}
      >
        <ArgonTypography
          variant="jpBodyRegular"
          sx={({ palette: { white, blue } }) => ({
            padding: "0 10px 0 0",
            color: darkMode ? white.main : white.main,
          })}
        >
          Articles
        </ArgonTypography>
        <ArgonTypography
          variant="jpBodyRegular"
          ml="auto"
          sx={({ palette: { white, blue } }) => ({
            color: darkMode ? white.main : white.main,
          })}
        >
          <Icon sx={{ fontWeight: "normal", verticalAlign: "middle" }}>
            keyboard_arrow_down{" "}
          </Icon>
        </ArgonTypography>
      </ArgonBox>

      {renderMenu()}
    </ArgonBox>
  );
}

export default DefaultNavbarDropdown;
