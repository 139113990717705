import API2 from "api2";
import { errorHandling } from "components/Common/errorhandling";

const config = (accessToken) => ({
  headers: { Authorization:`Bearer ${accessToken}` },
});

export const addCategoryApi = (payload) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token
    return new Promise(function (resolve, reject) {
        API2.post(`categories/add`,payload, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({ type: "ADD_CATEGORY_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "ADD_CATEGORY_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response)
        });
    });
  };
};
export const addArticleCategoryApi = (payload) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token
    return new Promise(function (resolve, reject) {
        API2.post(`article-category/add`,payload, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({ type: "ADD_ARTICLE_CATEGORY_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "ADD_ARTICLE_CATEGORY_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response)
        });
    });
  };
};
export const updateArticleCategoryApi = (payload,id) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token
    return new Promise(function (resolve, reject) {
        API2.put(`article-category/update/${id}`,payload, config(token))
        .then((res) => {
          const { data } = res;
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
        });
    });
  };
};
export const updateCategoryApi = (payload,id) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token
    return new Promise(function (resolve, reject) {
        API2.put(`categories/update/${id}`,payload, config(token))
        .then((res) => {
          const { data } = res;
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
        });
    });
  };
};
export const getCategoriesListApi = (search) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token
    // if(token){
      const query = search ? `?search=${search}`:""
      return new Promise(function (resolve, reject) {
          API2.get(`categories${query}`, config(token))
          .then((res) => {
            const { data } = res;
            dispatch({ type: "CATEGORIES_LIST_SUCCESS", payload: "success", data:data?.data });
            resolve(data);
          })
          .catch((err) => {
            const errorMessage = err?.response?.data?.message;
            dispatch({ type: "CATEGORIES_LIST_FAILED", payload: "failed", err });
            reject(errorMessage);
            errorHandling(err?.response)
          });
      });
    // }
  };
};
export const getArticleCategoriesListApi = (search) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token
    // if(token){
      const query = search ? `?search=${search}`:""
      return new Promise(function (resolve, reject) {
          API2.get(`article-category${query}`, config(token))
          .then((res) => {
            const { data } = res;
            dispatch({ type: "CATEGORIES_ARTICLE_LIST_SUCCESS", payload: "success", data:data?.data });
            resolve(data);
          })
          .catch((err) => {
            const errorMessage = err?.response?.data?.message;
            dispatch({ type: "CATEGORIES_ARTICLE_LIST_FAILED", payload: "failed", err });
            reject(errorMessage);
            errorHandling(err?.response)
          });
      });
    // }
  };
};
export const getUserByRole = (roles,search) => {
  return (dispatch, getState) => {
    const token = getState()?.authState?.user?.token
    let query = ""
    if(search){
      query = `&search=${search}`
    }
    return new Promise(function (resolve, reject) {
      API2.get(
        `admin/users?roles=${roles}${query}`,
        config(token)
      )
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "FETCH_AuthorList_SUCCESS",
            payload: true,
            data,
          });
          resolve(data.data);
        })
        .catch((err) => {
          dispatch({ type: "FETCH_AuthorList_FAIELD", payload: true });
          reject(err);
          errorHandling(err?.response)
        });
    });
  };
};