import { Card, useThem, styled } from '@mui/material'
import ArgonBox from 'components/ArgonBox'
import ArgonTypography from 'components/ArgonTypography'
import React from 'react'
import { IconPickerItem } from 'react-fa-icon-picker'
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import { useTheme } from '@emotion/react'
import { useArgonController } from 'context'


const CustomFields = ({ listingInfo }) => {
    const [controller] = useArgonController();
    const { darkMode } = controller;
    const theme = useTheme();
    const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
        height: 28,
        width: 28,
        path: {
            fill: theme.palette.yellow.five,
        },
    }));
    const FullStarBankIconStyled = styled(FullStarIcon)(({ theme }) => ({
        height: 28,
        width: 28,
        cursor: "pointer",
        path: {
            fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
        },
    }));
    return (
        <Card sx={{ p: 4, mt: 3 }}>
            <ArgonTypography variant="jpH3Bold">{listingInfo?.category?.name} Details</ArgonTypography>

            <ArgonBox display="flex" flexDirection="column" gap={1} my={2}>

                <>
                    {listingInfo?.customFieldsList.length && listingInfo?.customFieldsList.map((item, index) => (
                        <ArgonBox sx={{ display: "flex", alignItems: "center", gap: 0.5 }} >
                            <ArgonTypography variant="jpBodyBold">
                                {item?.name}:
                            </ArgonTypography>
                            {item?.type !== "rating" ? (
                                <ArgonTypography variant="jpBodyRegular" sx={{ maxWidth: 700 }}>
                                    {item?.value}
                                </ArgonTypography>
                            ) : (
                                <ArgonBox display="flex" alignItems="center" sx={{
                                    position:" relative",
                                    top: 3
                                }}>
                                    {[...Array(5)].map((x, i) => (
                                        <div
                                            key={i}
                                        >
                                            {i < parseInt(item?.value) ? (
                                                <>
                                                    {console.log('item?.icon', item?.icon)}
                                                    {item?.icon ? (
                                                        <IconPickerItem
                                                            icon={item?.icon}
                                                            size={28}
                                                            color={theme.palette.yellow.five}
                                                        />
                                                    ) : (
                                                        <FullStarFillIconStyled />
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {item?.icon ? (
                                                        <IconPickerItem
                                                            icon={item?.icon}
                                                            size={28}
                                                            color='#000'
                                                        />
                                                    ) : (
                                                        <FullStarBankIconStyled />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </ArgonBox>
                            )}
                        </ArgonBox>
                    ))}
                </>

            </ArgonBox>
        </Card>
    )
}

export default CustomFields