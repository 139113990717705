import React, { useEffect } from "react";
import {
  Card,
  Divider,
  Grid,
  Icon,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useArgonController } from "context";
import boxShadows from "assets/theme/base/boxShadows";
import { Link } from "react-router-dom";
import { getPaymentList } from "Actions/listingAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Payment = () => {
  const dispatch = useDispatch()
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { buttonBoxShadow } = boxShadows;
  const paymentList = useSelector((state)=>state?.listingState?.paymentList?.paymentDetails)

  useEffect(()=>{
    dispatch(getPaymentList())
  },[dispatch])

  return (
    <>
      <Card sx={{ p: 3 }}>
        <ArgonTypography variant="h5">Payment Settings</ArgonTypography>

        <Divider />

        <ArgonBox mt={2} mb={3}>
          <ArgonTypography fontWeight="regular">
            Membership Details
          </ArgonTypography>

          <ArgonBox
            sx={({ palette: { white, natural, blue } }) => ({
              border: `1px solid ${natural.four}`,
              borderRadius: 1,
              py: 2,
              px: 3,
              my: 1,
              boxShadow: buttonBoxShadow.main,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            })}
          >
            <ArgonBox display="flex" flexDirection="column">
              <ArgonTypography variant="jpBodyMedium">
                Membership Plan
              </ArgonTypography>
              <ArgonTypography
                variant="jpCaptionBold"
                sx={{
                  textTransform: "capitalize",
                }}
              >
                {console.log('paymentList', paymentList)}
              {paymentList?.plan ?   paymentList?.plan === "monthly" ? "Monthly" : "yearly" :"Basic"}
              </ArgonTypography>
            </ArgonBox>

            <ArgonTypography
              component={Link}
              to="/join"
              variant="body2"
              sx={{
                lineHeight: 0,
                display: "flex",
                alignItems: "center",
                textDecoration: "underline",
                gap: 1,
              }}
            >
              Join now
              <Icon fontSize="medium">navigate_next_icon</Icon>
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>

        <Divider />

        <ArgonTypography variant="jpCaptionMedium">
          If you no longer want this account, use this link to cancel your
          membership with us.
        </ArgonTypography>

        {/* <ArgonBox my={4}>
          <ArgonBox
            mb={4}
            display="flex"
            alginItems="center"
            justifyContent="center"
          >
            <ArgonTypography variant="h3">Membership Details</ArgonTypography>
          </ArgonBox>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={4}>
              <DefaultPricingCard
                title="starter"
                subTitle={"/ Per mo"}
                price={{ currency: "$", value: 3 }}
                specifications={[{ label: "50 movie reviews", includes: true }]}
                action={{
                  route: "#",
                  color: "info",
                  label: "Active",
                  name: "monthly",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DefaultPricingCard
                title="premium"
                subTitle={"/ Per year"}
                price={{ currency: "$", value: 29 }}
                specifications={[
                  { label: "150 movie reviews", includes: true },
                ]}
                action={{
                  route: "#",
                  color: "info",
                  label: "Active",
                  name: "yearly",
                }}
              />
            </Grid>
          </Grid>
          <ArgonBox my={2}>
            <ArgonTypography variant="jpCaptionMedium">
              If you no longer want this account, use this link to cancel your
              membership with us.
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox> */}
      </Card>
    </>
  );
};

export default Payment;
