/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";
import ArgonInput from "components/ArgonInput";
import { Box, Card, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import { useArgonController } from "context";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import ReviewStats from "./components/ReviewStats";
import { useDispatch } from "react-redux";
import NameCell from "./components/NameCell";
import StarCell from "./components/StarCell";
import DefaultCell from "./components/DefaultCell";
import { useSelector } from "react-redux";
import { getReview } from "Actions/reviewAction";
import moment from "moment";
import ActionCell from "./components/ActionCell";
import ConfirmationModel from "components/Common/ConfirmationModel";
import api2 from "api2";
import ArgonSnackbar from "components/ArgonSnackbar";
import axios from "axios";
import { formateText } from "components/Common/formateText";
const columnsList = [
  // { Header: "#", accessor: "#" },
  { Header: "Reviewer Name", accessor: "reviewerName" },
  { Header: "Listing Name", accessor: "listingName" },
  { Header: "Star", accessor: "star" },
  { Header: "date added ", accessor: "dateAdded" },
  { Header: "Status", accessor: "status" },
];
function CommunityReviews() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { size } = typography;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [search, setSearch] = useState();
  const [openMenu, setOpenMenu] = useState(null);
  const [approvedList, setApprovedList] = useState([]);
  const [rejectList, setRejectList] = useState(null);
  const [pendingList, setPendingList] = useState(null);
  const [selectedId, setSelectedId] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");
  const token = useSelector((state) => state?.authState?.user?.token);
  const userData = useSelector((state) => state?.authState?.user);
  const [columns, setColumns] = useState(columnsList);
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const [rows, setRows] = useState([]);
  const userRating = useSelector(
    (state) => state?.reviewState?.myReview?.payload?.data
  );

  useEffect(() => {
    dispatch(getReview("", search));
  }, [dispatch, search]);
  useEffect(() => {
    const approvedArray = [];
    const rejectArray = [];
    const pendingArray = [];
    const userRatingArray =
      userRating?.ratingData?.length &&
      userRating?.ratingData?.map((item) => {
        if (item?.approvalStatus === "approved") {
          approvedArray.push(item);
        }
        if (item?.approvalStatus === "reject") {
          rejectArray.push(item);
        }
        if (item?.approvalStatus === "pending") {
          pendingArray.push(item);
        }
        // return {
        const obj = {
          // "#": (
          //   <>
          //     <IconButton
          //       aria-label="expand row"
          //       size="large"
          //       // onClick={() => setOpen(!open)}
          //     >
          //       {/* {open ? */}
          //       <KeyboardArrowUpIcon />
          //       {/* : */}
          //       <KeyboardArrowDownIcon />
          //       {/* } */}
          //     </IconButton>
          //   </>
          // ),
          reviewerName: (
            <NameCell
              image={item?.userId?.profilePicture}
              userName={item?.userId?.userName}
              other={item?.userId?.role}
              other2={`${0 > 1 ? "reviews" : `${1} review`}`}
            />
          ),
          listingName: (
            <NameCell
              image={item?.listingId?.thumbnailImg}
              userName={item?.listingId?.name}
              other={item?.listingId?.category?.name}
              other2={item?.listingId?.yearReleased}
            />
          ),
          star: <StarCell image={"/"} startCount={item?.rating} />,
          dateAdded: (
            <DefaultCell>
              {item?.createdAt &&
                moment(item?.createdAt).format("MMM Do, YYYY")}
            </DefaultCell>
          ),
          status: (
            <DefaultCell>
              {item?.approvalStatus === "pending"
                ? "Pending"
                : "" || item?.approvalStatus === "approved"
                ? "Approved"
                : "" || item?.approvalStatus === "reject"
                ? "Reject"
                : ""}
            </DefaultCell>
          ),
          collapsed: (
            <Box sx={{ margin: 1 }}>
              <ArgonTypography
                variant="jpCaptionNormal"
                gutterBottom
                component="div"
              >
                {formateText(item?.original?.action?.props?.item?.ratingText)}
              </ArgonTypography>
            </Box>
          ),
          action: (
            <ActionCell
              item={item}
              setSelectedId={setSelectedId}
              setSelectedStatus={setSelectedStatus}
              setIsOpen={setIsOpen}
            />
          ),
        };
        return obj;
        // }
      });
    setApprovedList(approvedArray);
    setRejectList(rejectArray);
    setPendingList(pendingArray);
    setRows(userRatingArray || []);
  }, [userRating]);
  useEffect(() => {
    if (userData?.user?.role !== "editor") {
      const findMatchColumn = columns.find(
        (item) => item?.accessor === "action"
      );
      if (!findMatchColumn) {
        columns.push({ Header: "Action", accessor: "action" });
      }
      setColumns([...columns]);
    }
  }, []);
  const handleSuccess = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await api2
        .put(
          `param-ratings/update/${selectedId}`,
          { approvalStatus: selectedStatus },
          config
        )
        .then((res) => {
          setAlert({
            status: "success",
            isOpen: true,
            alertMsg: "Review Update successfully.",
            bgColor: "bggreen",
          });
          // setTimeout(() => {
          dispatch(getReview());
          setIsOpen(false);
          // }, 1000);
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem onClick={handleCloseMenu}>Action</MenuItem>
      <MenuItem onClick={handleCloseMenu}>Another action</MenuItem>
      <MenuItem onClick={handleCloseMenu}>Something else here</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container my={4} spacing={2}>
        <Grid item xs={12} md={3}>
          <ReviewStats
            title={'Total Reviews'}
            count={userRating?.ratingData?.length}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ReviewStats
            title={'Approved Reviews'}
            count={approvedList?.length}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ReviewStats title={'Rejected Reviews'} count={rejectList?.length} />
        </Grid>
        <Grid item xs={12} md={3}>
          <ReviewStats title={'Pending Reviews'} count={pendingList?.length} />
        </Grid>
      </Grid>

      <ArgonBox>
        <Card sx={{ mt: 4 }}>
          <ArgonBox p={2}>
            {/* <ArgonButton variant="text" onClick={handleOpenMenu}>
              <ArgonTypography
                variant="jpCaptionMedium"
                display="flex"
                alignItems="center"
                gap={1}
              >
                Add Filter
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              </ArgonTypography>
            </ArgonButton> */}
            {renderMenu()}
          </ArgonBox>

          <ArgonBox>
            <DataTable
              table={{ columns, rows: rows }}
              entriesPerPage={false}
              collapsed={true}
              enableExpanding
            />
          </ArgonBox>
        </Card>
      </ArgonBox>
      <ConfirmationModel
        isOpenMenu={isOpen}
        setIsOpenMenu={setIsOpen}
        handleSuccess={handleSuccess}
        message={`Are you want to sure to ${selectedStatus} this review.`}
      />
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </DashboardLayout>
  );
}

export default CommunityReviews;
