export default (
    state = {  },
    action
  ) => {
    switch (action.type) {
      case "login":
        return {
          // keep old state
          ...state,
          // add true/false if the user is or not logged in
          loggedIn: action.payload,
          user: action.data,
          authError: null,
        };
        case "REGISTER_FAILED":
          return {
            ...state,
            status: "error",
            message: action.message,
          };
          case "REGISTER_SUCCESS":
          return {
            ...state,
            data: action?.payload?.data?.user,
            status: "success",
            message: action.message,
          };
          case "MODE":
            return {
              ...state,
              mode:action?.data,
              status: action.status
            }
      default:
        return state;
    }
  };
  