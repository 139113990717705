import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, { retries: 2 });

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
    }
    return Promise.reject(error);
  }
);

export default axios.create({
  baseURL: 'https://joyfulparentingapi.cyberange.io/',
  // baseURL: "http://localhost:5001/",
});
