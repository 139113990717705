import React, { useEffect, useState } from "react";
import { Card, Container, Grid, styled } from "@mui/material";
import borders from "assets/theme/base/borders";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useArgonController } from "context";
import Slider from "react-slick";
import AgeIcon from "assets/images/icons/AgeIcon.svg";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCategoriesListApi } from "Actions/categoryAction";
import { getListingApi } from "Actions/listingAction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ArgonPagination from "components/ArgonPagination";
import FeaturedCard from "examples/Cards/FeaturedCard";
import Icon from "@mui/material/Icon";
import { getBookmarkList } from "Actions/listingAction";


const Featured = ({ categoryName, categoryId }) => {
  const [controller] = useArgonController();
  const navigator = useNavigate();
  const { darkMode } = controller;
  const { borderRadius } = borders;
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(true);
  const [filterValue, setListingList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0)
  const [selectedFeature, setSelectedFeature] = useState("");
  const userData = useSelector((state) => state?.authState?.user?.user);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(0);
  const token = useSelector((state) => state?.authState?.user?.token);
  const search = "";
  const listingList = useSelector(
    (state) => state?.listingState?.listingList?.data || []
  );
  const categoryList = useSelector(
    (state) => state.categoryState.categoriesList || []
  );

  //
  const StyledSlider = styled(Slider)(({ theme }) => ({
    "& .slick-slide div:first-child": {
      maxWidth: "100%",
      margin: "0 8px",
    },

    "& .slick-list .slick-track": {
      margin: 0,
    },
  }));
  //
  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: theme.palette.yellow.five,
    },
  }));
  const EmptyStarIconStyed = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
  }));
  useEffect(() => {
    if (categoryId) {
      setLimit(8);
    }
  }, [categoryId]);
  useEffect(()=>{
    if(token && (userData?.role === "parent" || userData?.role === "teenager")){
      dispatch(getBookmarkList(userData?._id))
    }   
  },[dispatch])
  useEffect(() => {
    dispatch(getListingApi(skip, limit, search, categoryId, "approved"));
    dispatch(getCategoriesListApi());
    if (categoryName) {
      setShowAll(true);
    } else {
      setShowAll(false);
    }
  }, [dispatch, categoryName, limit, skip]);
  useEffect(() => {
    const filterValueArray = [];
    categoryList?.length && categoryList.map((item) => {
      filterValueArray.push({
        [item?.name]: listingList?.listing?.length && listingList?.listing.filter((i) => i.category?.name === item.name),
      });
    });
    setListingList(filterValueArray);

  }, [categoryList, listingList]);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const addOneAndMakeInteger =(number)=> {
    if (!Number.isInteger(number)) {
      number += 1;
    }
    return Math.floor(number);
  }

  return (
    <Container>
      {filterValue.length ? (
        filterValue.map((item) => (
          <ArgonBox
            // my={10}
            mb={5}
          >
            {item[Object?.keys(item)[0]]?.length ? (
              <>
                <ArgonBox my={6} display="flex" justifyContent="space-between">
                  <ArgonTypography variant="jpH2">
                    Featured {Object?.keys(item)[0]}
                  </ArgonTypography>
                  {categoryName ? (
                    <>
                    {item[Object.keys(item)[0]]?.length >4 && (
                    <ArgonTypography
                      variant="jpBodyMedium"
                      sx={({ palette: { blue } }) => ({
                        color: blue.five,
                        cursor: "pointer",
                      })}
                      onClick={() => {
                        setSelectedFeature(Object?.keys(item)[0]);
                        setShowAll(!showAll);
                      }}
                    >
                      See {!showAll ? "All" : "Less"}
                    </ArgonTypography>
                    )}
                    </>
                  ) : (
                    <>
                    {item[Object.keys(item)[0]]?.length >4 && (
                    <ArgonTypography
                      variant="jpBodyMedium"
                      component={Link}
                      to={`/home/${Object?.keys(item)[0]
                        .toLocaleLowerCase()
                        ?.trim()}/${item[Object?.keys(item)[0]][0]?.category?._id
                        }`}
                      sx={({ palette: { blue } }) => ({
                        color: blue.five,
                        cursor: "pointer",
                      })}
                    >
                      See {!showAll ? "All" : "Less"}
                    </ArgonTypography>
                    )}
                    </>
                  )}
                </ArgonBox>
              </>
            ) : (
              <></>
            )}

            {/*  */}
            {showAll ? (
              <Grid container spacing={3}>
                {item[Object.keys(item)[0]]?.length ? (
                  <>
                    {item[Object.keys(item)[0]].map((i) => (
                      <Grid item md={3} xs={12}>
                         <FeaturedCard i={i} />
                      </Grid>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              <ArgonBox>
                <StyledSlider {...settings}>
                  {item[Object.keys(item)[0]]?.length ? (
                    item[Object.keys(item)[0]].map((i, index) => (
                      <FeaturedCard i={i} />
                    ))
                  ) : (
                    <></>
                  )}
                </StyledSlider>
              </ArgonBox>
            )}
          </ArgonBox>
        ))
      ) : (
        <></>
      )}
      {listingList?.listingCount >10 ? (
      <ArgonBox sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
        my={3}>
        {categoryId && (

          <ArgonPagination
            variant={"gradient"}
            color={"info"}
          >

            <>

              {pageIndex !== 0 && (
                <>
              <ArgonPagination item onClick={() => {
                setPageIndex(parseInt(pageIndex - 1))
                setSkip(parseInt(pageIndex - 1) * 8)
              }}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </ArgonPagination>
                </>    
              )}

              {[...Array(addOneAndMakeInteger(listingList?.listingCount / 8)  )].map((x, index) => (


                <ArgonPagination
                  item
                  // key={1}
                  onClick={() => {
                    setPageIndex(parseInt(index))
                    setSkip(index * 8)
                  }}
                  active={pageIndex === index}
                >
                  {index + 1}
                </ArgonPagination>

              ))}
            </>
              {parseInt(listingList?.listingCount / 8) !== pageIndex && (
            <ArgonPagination item onClick={() => {
              setPageIndex(parseInt(pageIndex + 1))
              setSkip(parseInt(pageIndex + 1) * 8)
            }}>
              <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
            </ArgonPagination>
              )}

          </ArgonPagination>
        )}
      </ArgonBox>
      ):(
        <></>
      ) }

    </Container>
  );
};

export default Featured;
