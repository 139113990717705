
export default (
    state = { },
    action
  ) => {
    switch (action.type) {  
      case "ADD_REVIEW_SUCCESS":
        return {
          ...state,
          myReview: action,
        };
  
      case "ADD_REVIEW_FAILED":
        return {
          ...state,
          myReview: [],

        }
        default:
            return state;
    }
    }

