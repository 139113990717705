import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  styled,
} from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { ReactComponent as CloseIcon } from "assets/images/icons/Close.svg";
import { useArgonController } from "context";
import ImageUploadCard from "examples/Cards/ImageUploadCard";
import FormField from "components/Common/FormField";
import ArgonButton from "components/ArgonButton";
import FormSelect from "components/Common/FormSelect";
import ArgonSelect from "components/ArgonSelect";
import api2 from "api2";
import axios from "axios";
import ArgonDropzone from "components/ArgonDropzone";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { addUser } from "Actions/userAction";
import { registerAction } from "Actions/loginAction";
import ArgonSnackbar from "components/ArgonSnackbar";
import { updateUser } from "Actions/userAction";
import { getUserByRole } from "Actions/categoryAction";

//
const listingAuthor = [
  { value: "JohnDoe", label: "John Doe" },
  { value: "markLuis", label: "Mark Luis" },
];
const rolesList = [
  { label: 'Author', value: 'author' },
  { label: 'Editor', value: 'editor' },
  { label: 'Super Admin', value: 'superadmin' },
];
const statusList = [
  { label: "Active", value: "active" },
  { label: "Deactivate", value: "deactivate" },
];

const AddNewUser = ({
  isOpenMenu,
  setIsOpenMenu,
  selectedItem,
  setSelectedItem,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [open, setOpen] = useState(isOpenMenu);
  const ref = useRef();
  const [userInfo, setUserInfo] = useState({});
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [showError, setShowError] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });

  const ClosrIconSvg = styled(CloseIcon)(({ theme }) => ({
    height: 32,
    width: 32,
    cursor: "pointer",
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.eight,
    },
  }));
  useEffect(() => {
    setUserInfo({
      userName: selectedItem?.userName,
      email: selectedItem?.email,
      role: selectedItem?.role,
      profilePicture: selectedItem?.profilePicture,
      status: selectedItem?.isActive ? "active" : "deactivate",
      password: selectedItem?.password,
    });
  }, [selectedItem]);
  const handleClickOpen = () => {
    setIsOpenMenu(true);
  };

  const handleClose = () => {
    setIsOpenMenu(false);
    setUserInfo({});
    setShowError(false);
    setSelectedItem();
    setTimeout(() => {
      dispatch(getUserByRole(['superadmin', 'editor', 'author']));
    }, 1000);
  };
  const handleUpload = async (file) => {
    try {
      let formData = new FormData();
      formData.append("file", file);
      let result = await api2.post(`imageUpload`, formData);
      setIsLoader(false);
      setUserInfo({
        ...userInfo,
        profilePicture: result?.data?.data,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  const handleSubmit = () => {
    setShowError(true);
    if (
      userInfo?.userName &&
      userInfo?.email &&
      userInfo?.role &&
      userInfo?.password
    ) {
      const payload = {
        userName: userInfo?.userName,
        email: userInfo?.email,
        profilePicture: userInfo?.profilePicture,
        role: userInfo?.role,
        isActive: userInfo?.status === "active" ? true : false,
        password: userInfo?.password,
      };
      if (!selectedItem) {
        dispatch(registerAction(payload));
        setAlert({
          ...alert,
          status: "success",
          isOpen: true,
          alertMsg: "Member added successfully.",
          bgColor: "bggreen",
        });
      } else {
        dispatch(updateUser(payload, selectedItem?._id));
        setAlert({
          ...alert,
          status: "success",
          isOpen: true,
          alertMsg: "Member updated successfully.",
          bgColor: "bggreen",
        });
      }
      handleClose();
    }
  };
  return (
    <>
      <Dialog
        open={isOpenMenu}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Card sx={{ p: 4, overflow: "unset" }}>
          <ArgonBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ArgonTypography variant="jpH3Bold">
              {selectedItem ? "Edit" : "Add New"} User
            </ArgonTypography>

            <ArgonBox onClick={handleClose}>
              <ClosrIconSvg />
            </ArgonBox>
          </ArgonBox>

          <ArgonBox sx={{ my: 4 }}>
            <Card
              sx={({ palette: { natural } }) => ({
                border: `1px solid ${natural.three}`,
                p: 4,
                overflow: "unset",
              })}
            >
              <Grid container spacing={3} sx={{ my: 0 }}>
                <ArgonBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Grid
                    item
                    md={3}
                    onClick={() => {
                      ref?.current.childNodes[0].click();
                    }}
                  >
                    <ImageUploadCard
                      url={userInfo?.profilePicture}
                      title={"Choose Thumbnail"}
                      isLoading={isLoader}
                    />
                    <ArgonBox
                      ref={ref}
                      sx={{ position: "relative", display: "none" }}
                    >
                      <ArgonDropzone
                        style={{
                          position: "absolute",
                          bottom: 0,
                          opacity: 0,
                          cursor: "pointer",
                        }}
                        options={{
                          onDrop: (acceptedFiles) => {
                            handleUpload(acceptedFiles[0]);
                            setIsLoader(true);
                          },
                          accept: { "image/*": [] },
                        }}
                      />
                    </ArgonBox>
                  </Grid>
                </ArgonBox>

                <Grid item lg={6} sm={12} xs={12}>
                  <ArgonBox>
                    <FormField
                      type="text"
                      placeholder="User Full Name"
                      size="large"
                      label="Full Name"
                      value={userInfo?.userName}
                      error={showError && !userInfo?.userName}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          userName: e.target.value,
                        });
                      }}
                    />
                  </ArgonBox>
                </Grid>

                <Grid item lg={6} sm={12} xs={12}>
                  <ArgonBox>
                    <FormField
                      type="text"
                      placeholder="User Email"
                      size="large"
                      label="Email"
                      error={showError && !userInfo?.email}
                      value={userInfo?.email}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          email: e.target.value,
                        });
                      }}
                    />
                  </ArgonBox>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                  <ArgonBox>
                    <FormSelect
                      options={rolesList}
                      placeholder="Choose Role"
                      size="large"
                      label="Role"
                      error={showError && !userInfo?.role}
                      value={rolesList.find(
                        (item) => item?.value === userInfo?.role
                      )}
                      sx={{
                        "&.MuiMenuList-root": {
                          height: "10px !important",
                        },
                      }}
                      onChange={(selectedValue) => {
                        setUserInfo({
                          ...userInfo,
                          role: selectedValue.value,
                        });
                      }}
                    />
                  </ArgonBox>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                  <ArgonBox>
                    <FormSelect
                      options={statusList}
                      placeholder="Choose Status"
                      size="large"
                      label="Status"
                      error={showError && !userInfo?.status}
                      value={statusList.find(
                        (item) => item?.value === userInfo?.status
                      )}
                      onChange={(selectedValue) => {
                        setUserInfo({
                          ...userInfo,
                          status: selectedValue.value,
                        });
                      }}
                    />
                  </ArgonBox>
                </Grid>
                <Grid item lg={6} sm={12} xs={12}>
                  <ArgonBox mb={2}>
                    <FormField
                      type="password"
                      placeholder="Please Enter Password"
                      size="large"
                      label="password"
                      error={showError && !userInfo?.password}
                      // value={userInfo?.password}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          password: e.target.value,
                        });
                      }}
                    />
                  </ArgonBox>
                </Grid>
              </Grid>
            </Card>
          </ArgonBox>
          <DialogActions sx={{ p: 0 }}>
            <ArgonButton
              color="info"
              size="large"
              sx={{ px: 3 }}
              onClick={handleSubmit}
            >
              Save
            </ArgonButton>
          </DialogActions>
        </Card>
      </Dialog>
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </>
  );
};

export default AddNewUser;
