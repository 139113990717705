import ArgonBox from "components/ArgonBox";
import UserNav from "./UserNav";
import UserCategoryNav from "./UserCategoryNav";

function UserNavbar() {
  return (
    <ArgonBox display="flex" flexDirection="column">
      <UserNav />
      <UserCategoryNav />
    </ArgonBox>
  );
}

export default UserNavbar;
