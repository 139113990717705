
export default (
    state = { },
    action
  ) => {
    switch (action.type) {  
        case "ADD_LISTING_SUCCESS":
            return {
              ...state,
              listing: action?.data,
            };
      
          case "ADD_LISTING_FAILED":
            return {
              ...state,
              listing: {},
            };
        case "GET_LISTING_SUCCESS":
            return {
              ...state,
              listingList: action?.data,
            };
      
          case "GET_LISTING_FAILED":
            return {
              ...state,
              listingList: [],
            };
        case "GET_ARTICLE_SUCCESS":
            return {
              ...state,
              articleList: action?.data,
            };
      
          case "GET_ARTICLE_FAILED":
            return {
              ...state,
              articleList: [],
            };
        case "GET_MY_LISTING_SUCCESS":
            return {
              ...state,
              myListingList: action?.data,
            };
        case "GET_MY_ARTICLE_SUCCESS":
            return {
              ...state,
              myArticleList: action?.data,
            };
        case "GET_MY_ARTICLE_FAILED":
            return {
              ...state,
              myArticleList: [],
            };
      
        case "GET_BOOKMARKED_LISTING_SUCCESS":
            return {
              ...state,
              bookmarkList: action?.data?.data,
            };
      
          case "GET_MY_LISTING_FAILED":
            return {
              ...state,
              myListingList: [],
            };
            case "GET_PAYMENT_LIST_SUCCESS":
            return {
              ...state,
              paymentList: action?.data?.data,
            };
      
          case "GET_PAYMENT_LIST_FAILED":
            return {
              ...state,
              paymentList: [],
            };
        default:
            return state;
    }
}