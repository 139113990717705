import React, { useEffect, useState } from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import UserNavbar from "examples/Navbars/UserNavbar";
import HeroSection from "./components/HeroSection";
import ArgonBox from "components/ArgonBox";
import Featured from "./components/Featured";
import { Route, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCategoriesListApi } from "Actions/categoryAction";
import { useSelector } from "react-redux";
import Footer from "examples/Footer";
import { getListingApi } from "Actions/listingAction";
import { getFeaturedImgApi } from "Actions/listingAction";

const Home = () => {
  const dispatch = useDispatch();
  const { categoryName, categoryId } = useParams();
  const listingList = useSelector(
    (state) => state?.listingState?.listingList?.data || []
  );
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getFeaturedImgApi("approved"));
  }, [dispatch]);
  return (
    <>
      <PageLayout>
        <UserNavbar />
        <ArgonBox>
          {pathname === "/home" && <HeroSection />}

          {/*  */}
          <Featured categoryName={categoryName} categoryId={categoryId} />
        </ArgonBox>
      </PageLayout>
      <Footer />
    </>
  );
};

export default Home;
