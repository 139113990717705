import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import PropTypes from "prop-types";

DragComponent.propTypes = {
  isDragActive: PropTypes.func,
};

export default function DragComponent({ isDragActive }) {
  return (
    <>
      <ArgonBox
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        {/* <PlayFileIcon color={"white"} /> */}
        <ArgonTypography
          sx={({ palette: { text } }) => ({
            color: text.main,
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "155%",
          })}
        >
          {isDragActive
            ? "Drop the files here ..."
            : "Drag and drop an image, or import from computer"}
        </ArgonTypography>
      </ArgonBox>
      <ArgonButton
        color="info"
        variant="contained"
        sx={{
          width: "fit-content",
          textTransform: "uppercase",
        }}
      >
        Select image
      </ArgonButton>
    </>
  );
}
