import React from "react";
import { Card, styled } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
//
import { ReactComponent as FileIcon } from "assets/images/icons/File.svg";
import Loader from "components/Common/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import ArgonBox from "components/ArgonBox";

function ImageUploadCard({ url, title, isLoading }) {
  const FileIconSvg = styled(FileIcon)(({ theme }) => ({
    height: 50,
    width: 50,
    path: {
      fill: theme.palette.blue.five,
    },
  }));
  return (
    <>
      {!isLoading ? (
        <>
          {!url ? (
            <Card
              sx={({ palette: { blue, natural } }) => ({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                cursor: "pointer !important",
                background: blue.one,
                padding: 2,
                height: "200px",
                overflow: "hidden",
                width: "100%",
              })}
            >
              <>
                <FileIconSvg />
                <ArgonTypography
                  variant="jpCaptionRegular"
                  sx={({ palette: { blue } }) => ({
                    color: blue.eight,
                    textAlign: "center",
                    //   color: darkMode ? white.main : natural.eight,
                  })}
                >
                  {title}
                </ArgonTypography>

                <ArgonButton
                  sx={({ palette: { blue, white } }) => ({
                    px: 3,
                    background: blue.five,
                    color: white.main,
                    ":hover": {
                      background: blue.five,
                    },
                    ":focus:not(:hover)": {
                      background: blue.six,
                    },
                  })}
                >
                  Browse
                </ArgonButton>
              </>
            </Card>
          ) : (
            <Card
              sx={({ palette: { blue, natural } }) => ({
                cursor: "pointer !important",
                height: "200px",
                overflow: "visible",
                width: "100%",
                position: "relative",
              })}
            >
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "8px",
                  objectFit: "cover",
                  objectPosition: "top",
                  // objectFit: "contain",
                }}
                src={url}
                alt=""
              />

              <ArgonBox
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 20,
                  background: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  borderRadius: "50px",
                  zIndex: 1000,
                }}
              >
                <EditIcon />
              </ArgonBox>
            </Card>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default ImageUploadCard;
