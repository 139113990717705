import { Container } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleClick = ()=>{
            // eslint-disable-next-line no-restricted-globals 
      window.open('https://isacindia.org')
  }
  return (
    <Container>
      <ArgonBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={3}
        my={3}
      >
        <ArgonTypography
          variant="jpCaptionMedium"
          sx={{
            cursor:'pointer',
          }}
          onClick={handleClick}
          target="_blank"
        >
          © {new Date().getFullYear()} ISAC{" "}
        </ArgonTypography>

        <ArgonBox display="flex" alignItems="center" flexWrap="wrap" gap={4}>
          <ArgonTypography variant="jpCaptionMedium" component={Link} to="#">
            About
          </ArgonTypography>
          <ArgonTypography variant="jpCaptionMedium" component={Link} to="#">
            Contact
          </ArgonTypography>
          <ArgonTypography variant="jpCaptionMedium" component={Link} to="#">
            Privacy Policy
          </ArgonTypography>
          <ArgonTypography variant="jpCaptionMedium" component={Link} to="#">
            Terms of Use
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    </Container>
  );
};

export default Footer;
