import React, { useEffect } from "react";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import ArgonButton from "components/ArgonButton";
import { Card, Grid, styled } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import FormField from "components/Common/FormField";
import FormSelect from "components/Common/FormSelect";
import { ReactComponent as DeleteIcon } from "assets/images/icons/Trash.svg";
import { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { addUserCustomFieldsApi } from "Actions/userCustomActionFields";
import { useDispatch } from "react-redux";
import ArgonSnackbar from "components/ArgonSnackbar";
import { getUserCustomFieldsApi } from "Actions/userCustomActionFields";
import { useSelector } from "react-redux";
import { updateUserCustomFieldsApi } from "Actions/userCustomActionFields";

const CustomFields = () => {
  const dispatch = useDispatch();
  const customFieldsList = useSelector(
    (state) => state?.userCustomFieldsState?.userCustomFieldsList?.data?.data
  );
  const [customFields, setCustomFields] = useState([
    {
      name: "",
      type: "",
    },
  ]);
  useEffect(() => {
    console.log("customFieldsList", customFieldsList);
    setCustomFields(customFieldsList?.customFields || []);
  }, [customFieldsList]);
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });

  const inputTypeList = [
    {
      value: "text",
      label: "Text",
    },
    {
      value: "textArea",
      label: "Text Area",
    },
    {
      value: "date",
      label: "Date",
    },
    {
      value: "number",
      label: "Number",
    },
  ];
  useEffect(() => {
    dispatch(getUserCustomFieldsApi());
  }, []);
  const handleSave = () => {
    if (!customFieldsList?.customFields?.length) {
      dispatch(addUserCustomFieldsApi({ customFields: customFields }));
      setAlert({
        ...alert,
        status: "success",
        isOpen: true,
        alertMsg: "User Custom added successfully.",
        bgColor: "bggreen",
      });
      // dispatch(getUserCustomFieldsApi())
    } else {
      dispatch(
        updateUserCustomFieldsApi({
          id: customFieldsList?._id,
          payload: { customFields: customFields },
        })
      );
      setAlert({
        ...alert,
        status: "success",
        isOpen: true,
        alertMsg: "User Custom update successfully.",
        bgColor: "bggreen",
      });
      // dispatch(getUserCustomFieldsApi())
    }
  };

  const handleAddFields = () => {
    const customFieldsArray = [...customFields];
    customFieldsArray.push({
      name: "",
      type: "",
    });
    setCustomFields(customFieldsArray);
  };

  const handleDelete = (index) => {
    const customFieldsArray = [...customFields];
    customFieldsArray.splice(index, 1);
    setCustomFields(customFieldsArray);
  };

  const DeleteIconSvg = styled(DeleteIcon)(({ theme }) => ({
    path: {
      fill: theme.palette.error.primary,
    },
  }));
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <ArgonBox pt={4}>
          <Card sx={{ p: 4, mb: 20, overflow: "visible" }}>
            <Grid container>
              <Grid item lg={12} sm={12} xs={12}>
                <ArgonBox display="flex" justifyContent="space-between" gap={3}>
                  <ArgonTypography
                    component="label"
                    variant="h5"
                    textTransform="capitalize"
                  >
                    User Profile Custom Fields
                  </ArgonTypography>

                  <ArgonButton color="info" onClick={handleAddFields}>
                    Add
                  </ArgonButton>
                </ArgonBox>
                <>
                  <Grid item lg={12} my={2} sm={12} xs={12}>
                    {customFields?.length ? (
                      <>
                        <ArgonBox display="flex" flexDirection="column" gap={3}>
                          {customFields.map((item, index) => (
                            <ArgonBox
                              display="flex"
                              alignItems="flex-end"
                              gap={3}
                            >
                              <ArgonBox sx={{ width: "100%" }}>
                                <FormField
                                  type="text"
                                  // label="Input type title"
                                  placeholder="Write here..."
                                  size="large"
                                  value={item?.name}
                                  onChange={(e) => {
                                    const customFieldsArray = [...customFields];
                                    customFieldsArray.splice(index, 1, {
                                      name: e.target.value,
                                      type: customFieldsArray[index].type,
                                    });
                                    setCustomFields(customFieldsArray);
                                  }}
                                />
                              </ArgonBox>
                              <ArgonBox>
                                <FormSelect
                                  options={inputTypeList}
                                  placeholder="Select input type"
                                  size="large"
                                  value={inputTypeList.find(
                                    (i) => i.value === item?.type
                                  )}
                                  onChange={(newSelectedValue) => {
                                    const customFieldsArray = [...customFields];
                                    customFieldsArray.splice(index, 1, {
                                      name: customFieldsArray[index].name,
                                      type: newSelectedValue.value,
                                    });
                                    setCustomFields(customFieldsArray);
                                  }}
                                  // label="Input type"
                                />
                              </ArgonBox>

                              <ArgonButton
                                iconOnly
                                size="large"
                                sx={({ palette: { error } }) => ({
                                  background: error.secondary,
                                  ":hover": {
                                    background: error.secondary,
                                  },
                                  ":focus:not(:hover)": {
                                    background: error.secondary,
                                  },
                                })}
                                onClick={() => handleDelete(index)}
                              >
                                <DeleteIconSvg />
                              </ArgonButton>
                            </ArgonBox>
                          ))}
                        </ArgonBox>
                      </>
                    ) : (
                      <ArgonTypography
                        variant="caption2"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          my: 10,
                        }}
                      >
                        Add custom fields that show up in the user profile.
                      </ArgonTypography>
                    )}
                  </Grid>

                  {/* Color */}
                  {/* <ArgonBox>
                    <>
                      <FormField
                        type="color"
                        label="Accent Color"
                        size="large"
                      />
                      <FormField
                        type="color"
                        label="Header Background"
                        size="large"
                      />
                      <FormField
                        type="color"
                        label="Sidebar Background"
                        size="large"
                      />
                      <FormField
                        type="color"
                        label="Sidebar Text Color"
                        size="large"
                      />
                      <FormField
                        type="color"
                        label="Sidebar Icon Color"
                        size="large"
                      />
                      <FormField
                        type="color"
                        label="Body Background"
                        size="large"
                      />
                      <FormField type="color" label="Text Color" size="large" />
                    </>
                    <ArgonButton color="info">Save</ArgonButton>
                  </ArgonBox> */}

                  <ArgonBox
                    display="flex"
                    justifyContent="space-between"
                    gap={3}
                  >
                    <ArgonTypography
                      component="label"
                      variant="h5"
                      textTransform="capitalize"
                    ></ArgonTypography>

                    <ArgonButton color="info" onClick={handleSave}>
                      Save
                    </ArgonButton>
                  </ArgonBox>
                </>
              </Grid>
            </Grid>
          </Card>
        </ArgonBox>
        {alert.isOpen && (
          <ArgonSnackbar
            color={alert.status}
            icon="check"
            title={alert.status}
            content={alert.alertMsg}
            open={alert.isOpen}
            onClose={() => setAlert({ isOpen: false })}
            close={() => setAlert({ isOpen: false })}
            bgColor={alert.bgColor}
          />
        )}
      </DashboardLayout>
    </>
  );
};

export default CustomFields;
