/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";
import {
  LoginSocialGoogle,
  LoginSocialMicrosoft,
  LoginSocialApple,
} from "reactjs-social-login";
import {
  GoogleLoginButton,
  MicrosoftLoginButton,
  AppleLoginButton,
} from "react-social-login-buttons";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import BgImage from "assets/images/bg/bg1.png";
import FormField from "components/Common/FormField";
import { Button } from "@mui/material";
import { loginAction } from "Actions/loginAction";
import { useDispatch } from "react-redux";
import api2 from "api2";
import ArgonSnackbar from "components/ArgonSnackbar";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
import { registerAction } from "Actions/loginAction";
import MicrosoftLogin from "react-microsoft-login";
import { setDarkMode } from "context";
import { setDarkSidenav } from "context";
import { useArgonController } from "context";
import { useSelector } from "react-redux";
import Socials from "../components/Socials";
import Separator from "../components/Separator";

function SignIn() {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const [controller, dispatch] = useArgonController();
  const darkMode = useSelector((state) => state?.authState?.mode);
  const [alert, setAlert] = useState({
    status: '',
    isOpen: false,
    alertMsg: '',
    bgColor: '',
  });
  const [credential, setCredential] = useState();
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setCredential({
      ...credential,
      [name]: value,
    });
  };
  useEffect(() => {
    setDarkMode(dispatch, darkMode);
    setDarkSidenav(dispatch, darkMode);
  }, [darkMode]);
  // const roleWiseRouting = (data) => {
  //   // switch (data?.user?.role) {
  //   //   case 'parent':
  //   //     navigate('/home');
  //   //     break;
  //   //   case 'superadmin':
  //   //     navigate('/categories');
  //   //     break;
  //   //   case 'teenager':
  //   //     navigate('/home');
  //   //     break;
  //   //   case 'editor':
  //   //     navigate('/community-reviews');
  //   //     break;
  //   //   case 'author':
  //   //     navigate('/listings');
  //   //     break;
  //   //   default:
  //   //     navigate('/home');
  //   //     break;
  //   // }
  // };
  const onSubmit = async (e) => {
    e.preventDefault();
    api2
      .post('auth/login', credential)
      .then((res) => {
        if (res.data.data.token) {
          let data = res.data.data;
          reduxDispatch({ type: 'login', payload: 'success', data: data });
          setAlert({
            isOpen: true,
            alertMsg: res.data.message,
            status: 'success',
            bgColor: 'bggreen',
          });
          setTimeout(() => {
            navigate('/home');
          }, 1000);
        }
      })
      .catch((err) => {
        setAlert({
          isOpen: true,
          alertMsg: err?.response?.data.message || 'Something went wrong.',
          status: 'error',
          bgColor: 'bggreen',
        });
      });
  };
  useEffect(() => {
    reduxDispatch({ type: 'login', payload: 'success', data: {} });
    gapi.load('client:auth2', () => {
      gapi.auth2.init({ clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID });
    });
  }, []);
  const handleLogin = (provider, loginData) => {
    const payload = {
      role: 'parent',
      loginWith: provider,
      userName: loginData?.name,
      name: loginData?.giveName,
      profilePicture: loginData.picture,
      email: loginData?.email,
      googleId: loginData?.googleId,
    };
    console.log('payload', payload);
    api2
      .post('auth/login', payload)
      .then((res) => {
        let data = res.data.data;
        setAlert({
          isOpen: true,
          alertMsg: 'login successfully',
          status: 'success',
          bgColor: 'bggreen',
        });
        reduxDispatch({ type: 'login', payload: 'success', data: data });
        setTimeout(() => {
          navigate('/home');
        }, 3000);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleLoginMicrosoft = (e) => {};

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={{
        image: BgImage,
        title: '',
      }}
    >
      {/* <>
        <ArgonBox mb={2}>
          <Socials />
        </ArgonBox>
        <ArgonBox px={12}>
          <Separator />
        </ArgonBox>
      </> */}
      <ArgonBox>
        <ArgonBox component="form" role="form">
          <ArgonBox mb={2}>
            <FormField
              type="email"
              name="email"
              placeholder="Your Email"
              size="large"
              label="Email Address"
              onChange={handleChanges}
            />
          </ArgonBox>
          <ArgonBox mb={2}>
            <FormField
              type="password"
              name="password"
              placeholder="Your Password"
              size="large"
              label="Password"
              onChange={handleChanges}
            />
          </ArgonBox>
          <ArgonBox display="flex" justifyContent="flex-end">
            <ArgonTypography
              variant="jpCaptionMedium"
              // component={Link}
              // to="/"
              color="info"
              sx={{ cursor: 'pointer' }}
            >
              Forgot Password ?
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox mt={4} mb={1}>
            <ArgonButton
              type="submit"
              color="info"
              size="large"
              fullWidth
              onClick={onSubmit}
            >
              Sign In
            </ArgonButton>
            {/* <ArgonBox >
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Log in with Google"
              onSuccess={handleLogin}
              onFailure={handleLogin}
              cookiePolicy={'single_host_origin'}
            />
            </ArgonBox>
            <ArgonBox>
            <MicrosoftLogin
              clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
              authCallback={handleLoginMicrosoft}
              graphScopes={["user.read"]}
            />
            </ArgonBox> */}
            {/* <LoginSocialGoogle
              client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
              redirect_uri={process.env.REDIRECT_URI}
              scope="openid profile email"
              discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={({ provider, data }) => {
                handleLogin(provider, data);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <GoogleLoginButton />
            </LoginSocialGoogle> */}
            {/* <LoginSocialMicrosoft
          client_id={process.env.REACT_APP_MICROSOFT_CLIENT_ID || ''}
          redirect_uri={"http://localhost:3000/signin"}
          // onLoginStart={onLoginStart}
          onResolve={({ provider, data }) => {
            // setProvider(provider);
            // setProfile(data);
          }}
          onReject={(err) => {
            console.log(err);
          }}
        >
          <MicrosoftLoginButton />
        </LoginSocialMicrosoft> */}
            {/* <LoginSocialApple
          client_id={process.env.REACT_APP_APPLE_ID || ''}
          scope={'name email'}
          redirect_uri={"http://localhost:3000/signin"}
          // onLoginStart={onLoginStart}
          onResolve={({ provider, data }) => {
            // setProvider(provider);
            // setProfile(data);
          }}
          onReject={err => {
            console.log(err);
          }}
        >
          <AppleLoginButton />
        </LoginSocialApple> */}
          </ArgonBox>
        </ArgonBox>
        <ArgonBox mt={3} textAlign="center">
          <ArgonTypography
            variant="jpBodyRegular"
            color="text"
            fontWeight="regular"
          >
            Don&apos;t have an account?{' '}
            <ArgonTypography
              component={Link}
              to="/signup"
              variant="jpBodyMedium"
              color="info"
              fontWeight="medium"
              buttonTheme="dark"
              // onClick={handleRedirect}
            >
              Sign up
            </ArgonTypography>
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </IllustrationLayout>
  );
}

export default SignIn;
