import React from "react";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI base styles
import { Card } from "@mui/material";
import { useArgonController } from "context";
import borders from "assets/theme/base/borders";

const ListingsViewCard = () => {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { borderRadius } = borders;

  return (
    <>
      <Card sx={{ p: 2 }}>
        <ArgonBox
          component="img"
          src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt=""
          sx={{
            height: "320px",
            borderRadius: borderRadius.md,
            // objectFit: "contain",
          }}
        />

        <ArgonBox display="flex" flexDirection="column" gap={1.2} mt={3}>
          <ArgonTypography variant="jpH3Bold" className="tr-w-1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </ArgonTypography>
          <ArgonTypography
            component="span"
            variant="jpBodyMedium"
            sx={({ palette: { white, natural } }) => ({
              color: darkMode ? white.main : natural.five,

              ":after": {
                content: '"•"',
                padding: "0 1rem",
              },

              ":last-child:after": {
                content: '""',
              },
            })}
          >
            11
          </ArgonTypography>{" "}
        </ArgonBox>
      </Card>
    </>
  );
};

export default ListingsViewCard;
