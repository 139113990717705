import React, { useEffect, useState } from "react";
import { Card, Container, Grid, styled } from "@mui/material";
import borders from "assets/theme/base/borders";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useArgonController } from "context";
import Slider from "react-slick";
import AgeIcon from "assets/images/icons/AgeIcon.svg";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCategoriesListApi } from "Actions/categoryAction";
import { getListingApi } from "Actions/listingAction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ArgonPagination from "components/ArgonPagination";
import FeaturedCard from "examples/Cards/FeaturedCard";
import Icon from "@mui/material/Icon";
import { getBookmarkList } from "Actions/listingAction";
import EditorChoiceCard from "examples/Cards/EditorChoiceCard";

const EditorsRecommend = ({ categoryName, categoryId }) => {
  const [controller] = useArgonController();
  const navigator = useNavigate();
  const { darkMode } = controller;
  const { borderRadius } = borders;
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(true);
  const [filterValue, setListingList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedFeature, setSelectedFeature] = useState("");
  const userData = useSelector((state) => state?.authState?.user?.user);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(0);
  const token = useSelector((state) => state?.authState?.user?.token);
  const search = "";
  const listingList = useSelector(
    (state) => state?.listingState?.listingList?.data || []
  );
  const categoryList = useSelector(
    (state) => state.categoryState.categoriesList || []
  );

  //
  const StyledSlider = styled(Slider)(({ theme }) => ({
    "& .slick-slide div:first-child": {
      maxWidth: "100%",
      margin: "0 1px",
    },

    "& .slick-list .slick-track": {
      margin: "0",
    },
  }));
  //

  useEffect(() => {
    if (categoryId) {
      setLimit(8);
    }
  }, [categoryId]);

  useEffect(() => {
    if (
      token &&
      (userData?.role === "parent" || userData?.role === "teenager")
    ) {
      dispatch(getBookmarkList(userData?._id));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getListingApi(skip, limit, search, categoryId, "approved",true));
    dispatch(getCategoriesListApi());
    if (categoryName) {
      setShowAll(true);
    } else {
      setShowAll(false);
    }
  }, [dispatch, categoryName, limit, skip]);

  useEffect(() => {
    const filterValueArray = [];
    categoryList?.length &&
      categoryList.map((item) => {
        filterValueArray.push({
          [item?.name]:
            listingList?.listing?.length &&
            listingList?.listing.filter((i) => i.category?.name === item.name),
        });
      });
    setListingList(filterValueArray);
  }, [categoryList, listingList]);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  const addOneAndMakeInteger = (number) => {
    if (!Number.isInteger(number)) {
      number += 1;
    }
    return Math.floor(number);
  };

  return (
    <>
                {listingList?.listing?.length ? (
      <ArgonBox display="flex" flexDirection="column" gap={2}>
        <ArgonTypography variant="jpH3Bold">
          Our Editors Recommend
        </ArgonTypography>

        <ArgonBox>
          <ArgonBox>
            <ArgonBox>
              <StyledSlider {...settings}>
                 { listingList?.listing.map((i, index) => (
                    <EditorChoiceCard i={i} />
                  ))}
              </StyledSlider>
            </ArgonBox>{" "}
          </ArgonBox>
      </ArgonBox>
        </ArgonBox>
                ) : (
                  <></>
                )}
    </>
  );
};

export default EditorsRecommend;
