import styled from "@emotion/styled";
import { useArgonController } from "context";
import React from "react";
import { ReactComponent as FolderSvg } from "assets/images/icons/Folder.svg";

const FolderIcon = () => {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const FolderStyled = styled(FolderSvg)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.five,
    },
  }));
  return (
    <>
      <FolderStyled />
    </>
  );
};

export default FolderIcon;
