/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "assets/images/icons/Logout.svg";
import { styled } from "@mui/material";
import rgba from "assets/theme/functions/rgba";
import { light } from "@mui/material/styles/createPalette";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import theme from "assets/theme";
import { useDispatch } from "react-redux";
const sidenavItems = [
  {
    icon: <i className="ni ni-spaceship" />,
    label: "profile",
    to: "profile",
  },

  // {
  //   icon: <i className="ni ni-atom" />,
  //   label: "change password",
  //   to: "change-password",
  // },
  {
    icon: <i className="ni ni-bullet-list-67" />,
    label: "my reviews",
    to: "my-review",
  },
  {
    icon: <i className="ni ni-book-bookmark" />,
    label: "my saved list",
    to: "my-bookmarks",
  },
];
function ProfileSideBar() {
  const location = useLocation();
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [controller] = useArgonController();
  const [sideBarRoute, setSideBarRoute] = useState(sidenavItems);
  const userData = useSelector((state) => state?.authState?.user?.user);
  const { darkMode } = controller;
  const { palette, functions, typography } = theme;
  const { blue, white, dark } = palette;

  const LogoutIconStyled = styled(LogoutIcon)(({ theme }) => ({
    height: 20,
    width: 20,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.secondary.main,
    },
  }));
  const categoryList = useSelector(
    (state) => state.categoryState.categoriesList || []
  );
  const activeRootStyle = {
    backgroundColor: blue.two,
    color: darkMode ? dark.main : dark.main,
  };

  useEffect(() => {
    let sideBarRouteArray = [...sideBarRoute];
    if (userData && userData?.role === "parent") {
      let routerArray = [
        {
        icon: <i className="ni ni-badge" />,
        label: "my kids",
        to: "my-kids"
      },
      {
        icon: <i className="ni ni-money-coins" />,
        label: "payment",
        to: "payment",
      }]
      sideBarRouteArray = [...sideBarRouteArray,...routerArray];
    }
    setSideBarRoute(sideBarRouteArray);
  }, []);

  const renderSidenavItems = sideBarRoute.map(({ icon, label, to }, key) => {
    const itemKey = `item-${key}`;
    return (
      <ArgonBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <ArgonTypography
          component={Link}
          to={`/${to}`}
          variant="button"
          fontWeight="regular"
          // color="text"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem, rgba },
            palette: { light, blue, white, dark },
            transitions,
          }) => ({
            // ...(isActiveRoot?.pathName === to && activeStyleRoot),
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            color: darkMode ? white.main : dark.main,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: blue.two,
              color: darkMode ? dark.main : dark.main,
            },
            ...(location?.pathname?.includes(to.toLowerCase().trim()) &&
              activeRootStyle),
          })}
        >
          <ArgonBox fontSize="16px" color="secondary" mr={1.5} lineHeight={0}>
            {icon}
          </ArgonBox>
          {label}
        </ArgonTypography>
      </ArgonBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <ArgonBox
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}

        <ArgonTypography
          // component={Link}
          // to={"/signin"}
          variant="button"
          fontWeight="regular"
          // color="text"
          textTransform="capitalize"
          sx={({
            borders: { borderRadius },
            functions: { pxToRem, rgba },
            palette: { light, blue, yellow, dark },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            color: darkMode ? white.main : dark.main,
            cursor:"pointer",

            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: blue.two,
              color: darkMode ? dark.main : dark.main,
            },
          })}
          onClick={() => {
            navigator("/signin");
            sessionStorage.setItem("listingId", "");
            dispatch({ type: "login", payload: "success", data: {} });
          }}
        >
          <ArgonBox mr={1} lineHeight={0}>
            <LogoutIconStyled />
          </ArgonBox>
          Sign Out
        </ArgonTypography>
      </ArgonBox>
    </Card>
  );
}

export default ProfileSideBar;
