import ArgonBox from 'components/ArgonBox';
import ArgonButton from 'components/ArgonButton';
import FormField from 'components/Common/FormField';
import FormSelect from 'components/Common/FormSelect';
import React from 'react'
import { IconPicker } from 'react-fa-icon-picker';
import { Card, styled } from "@mui/material";
import { ReactComponent as DeleteIcon } from "assets/images/icons/Trash.svg";
const inputTypeList = [
  {
    value: "text",
    label: "Text",
  },
  {
    value: "textArea",
    label: "Text Area",
  },
  {
    value: "rating",
    label: "Rating",
  },
  {
    value: "date",
    label: "Date",
  },
  {
    value: "number",
    label: "Number",
  },
];
export default function CustomField({ item, index, customFields, setCustomFields, handleDelete }) {
  const DeleteIconSvg = styled(DeleteIcon)(({ theme }) => ({
    path: {
      fill: theme.palette.error.primary,
    },
  }));
  return (
    <ArgonBox
      display="flex"
      alignItems="flex-end"
      gap={3}
    >
      <ArgonBox sx={{ width: "70%" }}>
        <FormField
          type="text"
          // label="Input type title"
          placeholder="Write here..."
          size="large"
          value={item?.name}
          onChange={(e) => {
            const customFieldsArray = [
              ...customFields,
            ];
            customFieldsArray.splice(
              index,
              1,
              { name: e.target.value, type: customFieldsArray[index].type }
            );
            setCustomFields(customFieldsArray);
          }}
        />
      </ArgonBox>
      {item?.type === "rating" && (
        <ArgonBox>
          <IconPicker
            value={item?.icon}
            style={{
              display: "none"
            }}
            onChange={(value) => {
              const customFieldsArray = [
                ...customFields,
              ];
              customFieldsArray.splice(
                index,
                1,
                {
                  ...item,
                  icon: value
                }
              );
              setCustomFields(customFieldsArray);
            }}
          />

        </ArgonBox>
      )}
      <ArgonBox>
        <FormSelect
          options={inputTypeList}
          placeholder="Select input type"
          size="large"
          value={inputTypeList.find((i) => i.value === item?.type)}
          onChange={(newSelectedValue) => {
            const customFieldsArray = [
              ...customFields,
            ];
            customFieldsArray.splice(
              index,
              1,
              { name: customFieldsArray[index].name, type: newSelectedValue.value }
            );
            setCustomFields(customFieldsArray);
          }}
        // label="Input type"
        />
      </ArgonBox>

      <ArgonButton
        iconOnly
        size="large"
        sx={({ palette: { error } }) => ({
          background: error.secondary,
          ":hover": {
            background: error.secondary,
          },
          ":focus:not(:hover)": {
            background: error.secondary,
          },
        })}
        onClick={() => handleDelete(index)}
      >
        <DeleteIconSvg />
      </ArgonButton>
    </ArgonBox>
  )
}
