import rootReducer from "reducers/index.js";

import { applyMiddleware, combineReducers, createStore } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";





const persistConfig = {
  key: "root",
  storage,
};
const presistedReducer = persistReducer(persistConfig, rootReducer);
const persistorStore = createStore(presistedReducer, applyMiddleware(thunk))
const persistor = persistStore(persistorStore);
export { persistor, persistorStore };