
export default (
    state = { },
    action
  ) => {
    switch (action.type) {  
        case "LIST_TEAM_SUCCESS":
            return {
              ...state,
              teamList: action?.data,
            };
      
          case "LIST_TEAM_FAILED":
            return {
              ...state,
              teamList: [],
            };
        case "GET_MY_FAMILY_SUCCESS":
            return {
              ...state,
              familyList: action?.data,
            };
      
          case "GET_MY_FAMILY_FAILED":
            return {
              ...state,
              familyList: [],
            };
        default:
            return state;
    }
}