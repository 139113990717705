/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import { useArgonController } from "context";
import ArgonAvatar from "components/ArgonAvatar";
import {
  AppBar,
  Card,
  Container,
  Grid,
  Tab,
  Tabs,
  styled,
} from "@mui/material";

//
import FullStar from "assets/images/icons/FullStar.svg";
import HalfStar from "assets/images/icons/HalfStar.svg";
import EmptyStar from "assets/images/icons/EmptyStar.svg";

function StarCell({ startCount, image }) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: theme.palette.yellow.five,
    },
  }));
  const EmptyStarIconStyed = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
  }));

  return (
    <ArgonBox display="flex" alignItems="center" pr={2}>
      <ArgonBox display="flex" alignItems="center" mr={1}>
        {[...Array(5)].map((x, index) => (
          <div key={index}>
            {index < startCount ? (
              <FullStarFillIconStyled />
            ) : (
              <EmptyStarIconStyed />
            )}
          </div>
        ))}
        {/* <ArgonAvatar
          src={FullStar}
          alt={FullStar}
          sx={{
            height: "24px",
            width: "24px",
          }}
        />
        <ArgonAvatar
          src={FullStar}
          alt={FullStar}
          sx={{
            height: "24px",
            width: "24px",
          }}
        />
        <ArgonAvatar
          src={FullStar}
          alt={FullStar}
          sx={{
            height: "24px",
            width: "24px",
          }}
        />
        <ArgonAvatar
          src={HalfStar}
          alt={HalfStar}
          sx={{
            height: "24px",
            width: "24px",
          }}
        />
        <ArgonAvatar
          src={EmptyStar}
          alt={EmptyStar}
          sx={{
            height: "24px",
            width: "24px",
          }}
        /> */}
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonTypography variant="jpBodyMedium">({startCount})</ArgonTypography>
      </ArgonBox>
    </ArgonBox>
  );
}

// Setting default values for the props or StarCell
StarCell.defaultProps = {
  startCount: "",
};

// Typechecking props for the StarCell
StarCell.propTypes = {
  startCount: PropTypes.string,
};

export default StarCell;
