import { AppBar, Card, Tab, styled, Tabs, Grid } from "@mui/material";
import { getReview } from "Actions/reviewAction";
import ArgonTypography from "components/ArgonTypography";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import breakpoints from "assets/theme/base/breakpoints";
import ArgonBox from "components/ArgonBox";
import borders from "assets/theme/base/borders";
import { useArgonController } from "context";
import { ReactComponent as DoubleCheckIcon } from "assets/images/icons/DoubleCheckIcon.svg";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";

import api2 from "api2";
import axios from "axios";
import ArgonButton from "components/ArgonButton";
import { formateText } from "components/Common/formateText";
const ReadMore = ({ children }) => {
  const text = children;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <ArgonTypography variant="jpBodyRegular">
      {isReadMore ? text?.slice(0, 350) : text}
      <ArgonBox onClick={toggleReadMore} my={1}>
        <ArgonTypography
          variant="jpBodyMedium"
          sx={({ palette: { blue, white } }) => ({
            color: darkMode ? white.main : blue.five,
            cursor: "pointer",
          })}
        >
          {isReadMore ? "Read More" : " Show Less"}
        </ArgonTypography>
      </ArgonBox>
    </ArgonTypography>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <ArgonBox>{children}</ArgonBox>}
    </div>
  );
}
const CommunityReviews = () => {
  const { id } = useParams();
  const { borderRadius } = borders;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [listingInfo, setListingInfo] = useState();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.authState?.user);
  const userRating = useSelector(
    (state) => state?.reviewState?.myReview?.payload?.data
  );
  useEffect(() => {
    dispatch(getReview(id));
  }, [dispatch]);
  const fetchListing = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${userData?.token}` },
      };
      let result = await api2.get(`listings/${id}`, config).then((res) => {
        setListingInfo({
          ...res?.data?.data,
        });
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  useEffect(() => {
    if (id) {
      fetchListing();
    }
  }, []);
  function tabIndexProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [value, setValue] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const StyledTab = styled(Tab)(({ theme }) => ({
    "&.Mui-selected": {
      color: "#fff !important",
      backgroundColor: theme.palette.blue.five,
    },
  }));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const DoubleCheckIconStyled = styled(DoubleCheckIcon)(({ theme }) => ({
    height: 24,
    width: 24,
  }));

  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: theme.palette.yellow.five,
    },
  }));
  const EmptyStarIconStyed = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
  }));
  return (
    <Grid container>
      <Grid xs={12}>
        <>
          <Grid item xs={12} my={2}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={value}
                onChange={handleChange}
                sx={({ palette: { natural } }) => ({
                  backgroundColor: natural.two,
                  height: "48px",
                })}
              >
                <StyledTab label="Parent Reviews" {...tabIndexProps(0)} />
                <StyledTab label="Kids Reviews" {...tabIndexProps(1)} />
              </Tabs>
            </AppBar>

            {/*  */}
            <TabPanel value={value} index={0}>
              {userRating?.ratingData?.length ? (
                <>
                  {userRating?.ratingData.map((item) => (
                    <>
                      {item?.userId?.role === "parent" ? (
                        <Card
                          sx={{
                            p: 3,
                            my: 4,
                          }}
                        >
                          <ArgonBox
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <ArgonBox
                              display="flex"
                              alignItems="center"
                              gap={1}
                              mb={2}
                            >
                              <ArgonBox
                                component="img"
                                src={item?.userId?.profilePicture}
                                alt=""
                                height="40px"
                                width="40px"
                                borderRadius="section"
                                sx={{ objectFit: "cover" }}
                              />
                              <ArgonTypography
                                variant="jpH3Bold"
                                sx={({ palette: { blue, white } }) => ({
                                  color: darkMode ? white.main : blue.seven,
                                })}
                              >
                                {item?.userId?.name}
                              </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox
                              display="flex"
                              alignItems="stretch"
                              gap={1}
                            >
                              <ArgonBox sx={{ display: "flex" }}>
                                {[...Array(5)].map((x, index) => (
                                  <div key={index}>
                                    {index < item?.rating ? (
                                      <FullStarFillIconStyled />
                                    ) : (
                                      <EmptyStarIconStyed />
                                    )}
                                  </div>
                                ))}
                              </ArgonBox>
                              <ArgonTypography variant="jpBodyMedium">
                                ({item?.rating})
                              </ArgonTypography>
                            </ArgonBox>
                          </ArgonBox>

                          {formateText(item?.ratingText)?.length > 350 ? (
                            <ReadMore>{formateText(item?.ratingText)}</ReadMore>
                          ) : (
                            <>
                              <ArgonTypography variant="jpBodyRegular">
                                {formateText(item?.ratingText)}
                              </ArgonTypography>
                            </>
                          )}
                        </Card>
                      ) : (
                        <ArgonBox
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "100px",
                          }}
                        >
                          No review found
                        </ArgonBox>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </TabPanel>

            {/*  */}
            <TabPanel value={value} index={1}>
              {userRating?.ratingData?.length ? (
                <>
                  {userRating?.ratingData.map((item) => (
                    <>
                      {item?.userId?.role === "teenager" ? (
                        <>
                          <Card
                            sx={{
                              p: 3,
                              my: 4,
                            }}
                          >
                            <ArgonBox
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <ArgonBox
                                display="flex"
                                alignItems="center"
                                gap={1}
                                mb={2}
                              >
                                <ArgonBox
                                  component="img"
                                  src={item?.userId?.profilePicture}
                                  alt=""
                                  height="40px"
                                  width="40px"
                                  borderRadius="section"
                                  sx={{ objectFit: "cover" }}
                                />
                                <ArgonTypography
                                  variant="jpH3Bold"
                                  sx={({ palette: { blue, white } }) => ({
                                    color: darkMode ? white.main : blue.seven,
                                  })}
                                >
                                  {item?.userId?.name}
                                </ArgonTypography>
                              </ArgonBox>

                              <ArgonBox
                                display="flex"
                                alignItems="stretch"
                                gap={1}
                              >
                                <ArgonBox sx={{ display: "flex" }}>
                                  {[...Array(5)].map((x, index) => (
                                    <div key={index}>
                                      {index < item?.rating ? (
                                        <FullStarFillIconStyled />
                                      ) : (
                                        <EmptyStarIconStyed />
                                      )}
                                    </div>
                                  ))}
                                </ArgonBox>

                                <ArgonTypography variant="jpBodyMedium">
                                  ({item?.rating})
                                </ArgonTypography>
                              </ArgonBox>
                            </ArgonBox>
                            <ReadMore>{formateText(item?.ratingText)}</ReadMore>
                          </Card>
                        </>
                      ) : (
                        <ArgonBox
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "100px",
                          }}
                        >
                          No review found
                        </ArgonBox>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <></>
              )}
            </TabPanel>
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

export default CommunityReviews;
