/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Argon Dashboard 2 PRO MUI base styles
import ArgonInput from "components/ArgonInput";
import { Box, Card } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "./data/dataTableData";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUserByRole } from "Actions/categoryAction";
import NameCell from "./components/NameCell";
import DefaultCell from "./components/DefaultCell";
import ByWhomCell from "./components/ByWhomCell";
import ActionCell from "./components/ActionCell";
import ConfirmationModel from "components/Common/ConfirmationModel";
import api2 from "api2";
import axios from "axios";
import moment from "moment";
import ArgonTypography from "components/ArgonTypography";
import { formateText } from "components/Common/formateText";
const columns = [
  { Header: "name", accessor: "name" },
  { Header: "role", accessor: "role" },
  { Header: "Flagged By", accessor: "flaggedBy" },
  { Header: "Flagged On", accessor: "flaggedOn" },
  { Header: "action", accessor: "action", align: "center" },
];
function Flagged() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [isOpen, setIsOpen] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [search, setSearch] = useState();
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });
  const user = useSelector(
    (state) => state?.categoryState?.authorList?.data?.userList
  );
  const token = useSelector((state) => state?.authState?.user?.token);

  useEffect(() => {
    dispatch(getUserByRole(["parent", "teenager"], search));
  }, [dispatch, search]);
  useEffect(() => {
    const rowsArray =
      user?.length &&
      user.map((item) => {
        return {
          name: (
            <NameCell
              image={item?.profilePicture}
              userName={item?.userName}
              email={item?.email}
            />
          ),
          role: (
            <ArgonTypography
              variant="jpBodyRegular"
              sx={{ textTransform: "capitalize" }}
            >
              {item?.role}
            </ArgonTypography>
          ),
          flaggedBy: (
            <ByWhomCell
              image={item?.flaggedBy?.profilePicture}
              name={item?.flaggedBy?.userName}
            />
          ),
          flaggedOn: (
            <DefaultCell>
              {item?.flaggedAt
                ? moment(item?.flaggedAt).format("MMM Do, YYYY")
                : ""}
            </DefaultCell>
          ),
          collapsed: (
            <Box sx={{ margin: 1 }}>
              <ArgonTypography
                variant="jpCaptionNormal"
                gutterBottom
                component="div"
              >
                {formateText(item?.original?.action?.props?.item?.ratingText)}
              </ArgonTypography>
            </Box>
          ),
          action: (
            <ActionCell
              handleClick={() => setIsOpen(true)}
              setSelectedValue={setSelectedValue}
              collapsed
              item={item}
            />
          ),
        };
      });
    setRows(rowsArray || []);
  }, [user]);
  const handleSuccess = async () => {
    const value = selectedValue;
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await api2
        .post(
          `admin/users/flag/${selectedValue?._id}`,
          { flag: !selectedValue?.flag },
          config
        )
        .then((res) => {
          if (res)
            setAlert({
              status: "success",
              isOpen: true,
              alertMsg: "User flagged successfully.",
              bgColor: "bggreen",
            });
          dispatch(getUserByRole(["parent", "teenager"]));
          setIsOpen(false);
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <ArgonBox py={3}>
        <Card
          sx={{
            p: 2,
          }}
        >
          <ArgonBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <ArgonBox>
              <ArgonInput
                placeholder="Search Something.."
                startAdornment={
                  <Icon fontSize="large" style={{ marginRight: "6px" }}>
                    search
                  </Icon>
                }
                onChange={(e) => setSearch(e.target.value)}
              />
            </ArgonBox>{" "}
          </ArgonBox>
        </Card>
        <Card sx={{ mt: 4 }}>
          <ArgonBox pt={1}>
            <DataTable
              table={{ columns, rows: rows }}
              ntriesPerPage={false}
              collapsed={true}
              enableExpanding
            />
          </ArgonBox>
        </Card>
      </ArgonBox>
      <ConfirmationModel
        isOpenMenu={isOpen}
        setIsOpenMenu={setIsOpen}
        handleSuccess={handleSuccess}
        message={`Are you want to sure to ${
          !selectedValue?.flag ? "Unflag" : "flag"
        } this user.`}
      />
    </DashboardLayout>
  );
}

export default Flagged;
