import React from "react";
import { Card } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { ReactComponent as ArrowUp } from "assets/images/icons/ArrowUp.svg";
import { ReactComponent as ArrowDown } from "assets/images/icons/ArrowDown.svg";
import { useArgonController } from "context";
import styled from "@emotion/styled";
import borders from "assets/theme/base/borders";

const ReviewStats = ({title,count}) => {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { borderRadius } = borders;

  const ArrowUpSvg = styled(ArrowUp)(({ theme }) => ({
    height: 16,
    width: 16,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.dark.main,
    },
  }));

  const ArrowDownSvg = styled(ArrowDown)(({ theme }) => ({
    height: 16,
    width: 16,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.dark.main,
    },
  }));

  return (
    <>
      <Card sx={{ p: 3 }}>
        <ArgonBox display="flex" flexDirection="column" gap={1}>
          <ArgonBox display="flex" alignItems="center" gap={2}>
            <ArgonTypography variant="jpH1" sx={{}}>
              {count}
            </ArgonTypography>

            <ArgonBox
              display="flex"
              alignItems="center"
              gap={0.5}
              sx={({ palette: { natural, blue } }) => ({
                backgroundColor: darkMode ? blue.five : natural.two,
                padding: "4px 12px",
                borderRadius: borderRadius.xxl,
              })}
            >
              <ArrowUpSvg />
              <ArgonTypography variant="jpCaptionRegular">0</ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          <ArgonTypography variant="jpBodyRegular">
            {title}
          </ArgonTypography>
        </ArgonBox>
      </Card>
    </>
  );
};

export default ReviewStats;
