import React, { useEffect, useState } from "react";
import borders from "assets/theme/base/borders";
import ArgonBox from "components/ArgonBox";
import { useArgonController } from "context";
import ArgonTypography from "components/ArgonTypography";
import { Link, useNavigate } from "react-router-dom";
import AgeIcon from "assets/images/icons/AgeIcon.svg";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import { ReactComponent as EmptyStarIcon } from "assets/images/icons/EmptyStar.svg";
import { ReactComponent as HalfStarIcon } from "assets/images/icons/HalfStar.svg";
import { styled } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ArgonButton from "components/ArgonButton";
import { formateText } from "components/Common/formateText";
import { useSelector } from "react-redux";
import api2 from "api2";
import { useDispatch } from "react-redux";
import { getBookmarkList } from "Actions/listingAction";
import axios from "axios";
import ArgonPagination from "components/ArgonPagination";
import Icon from "@mui/material/Icon";

const SearchCard = ({ selectedValue }) => {
  const dispatch = useDispatch();
  const [controller] = useArgonController();
  const navigator = useNavigate();
  const token = useSelector((state) => state?.authState?.user?.token);
  const userData = useSelector((state) => state?.authState?.user?.user);
  const [pageIndex, setPageIndex] = useState(0);

  const BookmarkList = useSelector(
    (state) => state?.listingState?.bookmarkList?.listingIds
  );
  useEffect(() => {
    if (token) {
      let bookmarkListData = [];
      bookmarkListData = BookmarkList?.map((item) => item?._id);
      setBookmarkArray(bookmarkListData || []);
    }
  }, [BookmarkList]);
  const { darkMode } = controller;
  const { borderRadius } = borders;
  const [bookmarkArray, setBookmarkArray] = useState([]);
  const ReadMore = ({ children }) => {
    const text = children;
    const [controller] = useArgonController();
    const { darkMode } = controller;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <ArgonTypography variant="jpBodyRegular">
        {isReadMore ? text?.slice(0, 150) : text}
        <ArgonBox onClick={toggleReadMore} my={1}>
          <ArgonTypography
            variant="jpBodyMedium"
            sx={({ palette: { blue, white } }) => ({
              color: darkMode ? white.main : blue.five,
              cursor: "pointer",
            })}
          >
            {isReadMore ? "Read More" : " Show Less"}
          </ArgonTypography>
        </ArgonBox>
      </ArgonTypography>
    );
  };

  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: theme.palette.yellow.five,
    },
  }));
  const EmptyStarIconStyed = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
  }));
  const handleClick = async (bookmark) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let result = await api2
        .post(`bookmarks/manage`, { listingIds: bookmark }, config)
        .then((res) => {
          if (
            token &&
            (userData?.role === "parent" || userData?.role === "teenager")
          ) {
            dispatch(getBookmarkList(userData?._id));
          }
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };

  return (
    <>
      <ArgonBox>
        <ArgonBox
          sx={{
            pb: 2,
            my: 2,
          }}
        >
          {selectedValue?.length ? (
            <>
              {selectedValue?.map((item) => (
                <ArgonBox
                  sx={({ palette: { blue, natural }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 3,
                    my: 3,
                    py: 3,
                    width: "100%",
                    borderBottom: darkMode
                      ? `1px solid ${blue.seven}`
                      : `1px solid ${natural.three}`,

                    [breakpoints.down("sm")]: {
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  })}
                >
                  <ArgonBox>
                    <ArgonBox
                      component="img"
                      src={item?.thumbnailImg}
                      // src={item?.listingId?.thumbnailImg}
                      alt=""
                      sx={({ breakpoints }) => ({
                        width: "100px",
                        height: "150px",
                        borderRadius: borderRadius.md,
                        objectFit: "cover",
                        objectPosition: "top",

                        [breakpoints.down("md")]: {
                          alignItems: "center",
                        },
                      })}
                    />
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <ArgonBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      gap={3}
                      width="100%"
                    >
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        width="80%"
                      >
                        <ArgonTypography variant="jpCaptionRegular">
                          {item?.category?.name}
                        </ArgonTypography>

                        <ArgonTypography
                          variant="jpH3Bold"
                          sx={({ palette: { blue, white } }) => ({
                            color: darkMode ? white.main : blue.seven,
                          })}
                          component={Link}
                          to="#"
                        >
                          {item?.name}
                        </ArgonTypography>

                        <ArgonBox display="flex" alignItems="baseline" gap={2}>
                          <ArgonBox display="flex" alignItems="stretch" gap={1}>
                            <ArgonBox sx={{ display: "flex" }}>
                              {[...Array(5)].map((x, index) => (
                                <ArgonBox key={index}>
                                  {index < item?.averageRating ? (
                              <FullStarFillIconStyled />
                            ) : (
                              <EmptyStarIconStyed />
                            )}
                                </ArgonBox>
                              ))}
                            </ArgonBox>

                            <ArgonTypography variant="jpBodyMedium">
                              ({item?.averageRating || 0})
                            </ArgonTypography>
                          </ArgonBox>
                          <ArgonBox display="flex" alignItems="center" gap={1}>
                            <ArgonBox component="img" src={AgeIcon} alt="" />
                            <ArgonTypography variant="JpBodyRegular">
                              {item?.age} +
                            </ArgonTypography>
                          </ArgonBox>
                        </ArgonBox>

                        <ArgonTypography variant="jpBodyRegular">
                          {formateText(item?.shortDescription)?.length > 150 ? (
                            <ReadMore>
                              {formateText(item?.shortDescription)}
                            </ReadMore>
                          ) : (
                            <>
                              <ArgonTypography variant="jpBodyRegular">
                                {formateText(item?.shortDescription)}
                              </ArgonTypography>
                            </>
                          )}
                        </ArgonTypography>
                      </ArgonBox>

                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                        width="30%"
                      >
                        <ArgonBox>
                          {!bookmarkArray?.includes(item?._id) ? (
                            <BookmarkBorderIcon
                              sx={({ palette: { blue } }) => ({
                                height: "24px",
                                width: "24px",
                                fill: blue.five,
                                cursor: "pointer",
                              })}
                              onClick={() => {
                                if (token) {
                                  const bookmarkInfo = [...bookmarkArray];
                                  bookmarkInfo.push(item?._id);
                                  handleClick(bookmarkInfo);
                                } else {
                                  navigator("/signin");
                                }
                              }}
                            />
                          ) : (
                            <BookmarkIcon
                              sx={({ palette: { blue } }) => ({
                                height: "24px",
                                width: "24px",
                                fill: blue.five,
                                cursor: "pointer",
                              })}
                              onClick={() => {
                                if (token) {
                                  const bookmarkInfo = [...bookmarkArray];
                                  const findIndex = bookmarkInfo.findIndex(
                                    (x) => x === item?._id
                                  );
                                  bookmarkInfo.splice(findIndex, 1);
                                  handleClick(bookmarkInfo);
                                }
                              }}
                            />
                          )}
                        </ArgonBox>

                        <ArgonBox>
                          <ArgonButton
                            variant="gradient"
                            color="info"
                            size="medium"
                            onClick={() => {
                              navigator(`/movies/single-category/${item?._id}`);
                            }}
                          >
                            Watch Full Review
                          </ArgonButton>
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              ))}
            </>
          ) : (
            <></>
          )}
        </ArgonBox>
      </ArgonBox>
    </>
  );
};

export default SearchCard;
