import { Grid, styled } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Lightbox from "lightbox-react";
import React, { useState } from "react";
import Slider from "react-slick";

const PhotosAndVideos = ({ listingInfo }) => {
  const [isOpen,setIsOpen] = useState()
  const [photoIndex,setPhotoIndex] = useState(0)
  const StyledSlider = styled(Slider)(({ theme }) => ({
    "& .slick-slide div:first-child": {
      maxWidth: "100%",
      margin: "0 8px",
    },

    "& .slick-list .slick-track": {
      margin: 0,
    },
  }));

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <ArgonBox>
      <ArgonBox mb={3}>
        <ArgonTypography variant="jpH3Regular">
          Videos and Photos
        </ArgonTypography>
      </ArgonBox>
      <StyledSlider {...settings}>
        {listingInfo?.media?.length ? (
          listingInfo?.media.map((item, i) => (
            
              <ArgonBox
                sx={{
                  // width: "35vw",
                  aspectRatio: "16/9",
                  borderRadius: '0.25rem',
                  transition: 'all 0.25s ease',
  
                  ":hover": {
                    scale: "0.95"
                  },
  
                }}
                component="img"
                src={item}
                alt=""
                onClick={()=>setIsOpen(true)}
              />
            
          ))
        ) : (
          <></>
        )}

      </StyledSlider>
      {isOpen &&
              <Lightbox
                mainSrc={listingInfo?.media[photoIndex]}
                nextSrc={listingInfo?.media[(photoIndex + 1) % listingInfo.length]}
                prevSrc={listingInfo?.media[(photoIndex + listingInfo?.media.length - 1) % listingInfo?.media.length]}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex((photoIndex + listingInfo?.media.length - 1) % listingInfo?.media.length)
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % listingInfo?.media.length)
                }
              />
            }
    </ArgonBox>
  );
};

export default PhotosAndVideos;
