/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";
import ArgonInput from "components/ArgonInput";
import { AppBar, Card, Tab, Tabs, styled } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import { useArgonController } from "context";
import CategoriesCard from "examples/Cards/CategoriesCard";
import ArgonDropzone from "components/ArgonDropzone";
import ImageUploadCard from "examples/Cards/ImageUploadCard";
import { useRef } from "react";
import FormField from "components/Common/FormField";
import ArgonEditor from "components/ArgonEditor";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as TextFileIcon } from "assets/images/icons/TextFile.svg";
import ArgonSelect from "components/ArgonSelect";
import FormSelect from "components/Common/FormSelect";
import FormEditor from "components/Common/FormEditor";
import borders from "assets/theme/base/borders";
import ListingInformation from "./listing-information";
import { useEffect } from "react";
import breakpoints from "assets/theme/base/breakpoints";
import PropTypes from "prop-types";
import Reviews from "./reviews";
import api2 from "api2";
import axios from "axios";
import { updateListingApi } from "Actions/listingAction";
import { useDispatch } from "react-redux";
import ArgonSnackbar from "components/ArgonSnackbar";
import { useSelector } from "react-redux";
import MoreInfo from "./more-info";
import CommunityReviews from "./community-reviews";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <ArgonBox>{children}</ArgonBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabIndexProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ViewListing() {
  const { borderRadius } = borders;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const token = useSelector((state) => state?.authState?.user?.token);

  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const StyledTab = styled(Tab)(({ theme }) => ({
    "&.Mui-selected": {
      color: "#fff !important",
      backgroundColor: theme.palette.blue.five,
    },
  }));

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const [value, setValue] = useState(0);

  const [listing, setListingInfo] = useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fetchListing = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let result = await api2.get(`listings/${id}`, config).then((res) => {
        setListingInfo({
          ...res?.data?.data,
        });
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  useEffect(() => {
    if (id) {
      fetchListing();
    }
  }, []);
  const handleSave = (isDraft) => {
    dispatch(updateListingApi({ isDraft }, id));
    setAlert({
      status: "success",
      isOpen: true,
      alertMsg: "Listing update successfully.",
      bgColor: "bggreen",
    });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={4}>
        <Grid container direction="row" spacing={4} sx={{}}>
          <Grid item xs={12} md={12} lg={9}>
            <ListingInformation listing={listing} />

            <Card sx={{ p: 2, my: 4 }}>
              <>
                {" "}
                <Grid item xs={12}>
                  <AppBar position="static">
                    <Tabs
                      orientation={tabsOrientation}
                      value={value}
                      onChange={handleChange}
                      sx={({ palette: { natural } }) => ({
                        backgroundColor: natural.two,
                        height: "48px",
                      })}
                    >
                      <StyledTab label="More Info" {...tabIndexProps(0)} />
                      <StyledTab label="Community Reviews" {...tabIndexProps(1)} />
                      <StyledTab
                        label="Reviews"
                        {...tabIndexProps(2)}
                      />
                    </Tabs>
                  </AppBar>
                </Grid>
              </>
            </Card>

            <Card sx={{ p: 2, my: 4 }}>
              <>
                <TabPanel value={value} index={0}>
                  <ArgonBox display="flex" flexDirection="column" gap={2}>
                    <MoreInfo listing={listing} />
                  </ArgonBox>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <Reviews listing={listing} />
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <CommunityReviews />
                </TabPanel>
              </>
            </Card>
          </Grid>

          <Grid item xs={12} md={12} lg={3}>
            <Card sx={{ p: 2 }}>
              <ArgonBox
                mb={1}
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <ArgonButton
                  color="info"
                  size="large"
                  fullWidth
                  onClick={() => handleSave(false)}
                >
                  Publish
                </ArgonButton>
                {listing?.isDraft !== false && (
                  <ArgonButton
                    size="large"
                    sx={({ palette: { blue } }) => ({
                      background: blue.one,
                      ":hover": {
                        background: blue.one,
                      },
                      ":focus:not(:hover)": {
                        background: blue.one,
                      },
                    })}
                    fullWidth
                    onClick={() => handleSave(false)}
                  >
                    Save Draft
                  </ArgonButton>
                )}

                <ArgonButton
                  size="large"
                  sx={({ palette: { blue } }) => ({
                    background: blue.one,
                    ":hover": {
                      background: blue.one,
                    },
                    ":focus:not(:hover)": {
                      background: blue.one,
                    },
                  })}
                  fullWidth
                  component={Link}
                  to={`/edit-listing/${id}`}
                >
                  Edit
                </ArgonButton>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>

      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </DashboardLayout>
  );
}

export default ViewListing;
