import React from "react";
import { Card, Grid, styled } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useArgonController } from "context";
import { ReactComponent as TextFileIcon } from "assets/images/icons/TextFile.svg";
import borders from "assets/theme/base/borders";
import { useState } from "react";
import { formateText } from "components/Common/formateText";

const ReadMore = ({ children }) => {
  const text = children;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <ArgonTypography variant="jpBodyRegular">
      {isReadMore ? text?.slice(0, 350) : text}
      <ArgonBox onClick={toggleReadMore} my={1}>
        <ArgonTypography
          variant="jpBodyMedium"
          sx={({ palette: { blue, white } }) => ({
            color: darkMode ? white.main : blue.five,
            cursor: "pointer",
          })}
        >
          {isReadMore ? "Read More" : " Show Less"}
        </ArgonTypography>
      </ArgonBox>
    </ArgonTypography>
  );
};

const MoreInfo = ({ listing }) => {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { borderRadius } = borders;

  const TextFileIconSvg = styled(TextFileIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.blue.seven,
    },
  }));
  const regex = /<(?:"[^"]*"['"]*|'[^']*'['"]|&nbsp;|[^'">])+>/g;

  return (
    <>
      <Card sx={{ p: 2 }}>
        <ArgonBox display="flex" alignItems="center" gap={1} mb={3}>
          <TextFileIconSvg />
          <ArgonTypography
            variant="jpH3Bold"
            sx={({ palette: { blue, white } }) => ({
              color: darkMode ? white.main : blue.seven,
            })}
          >
            Short Description
          </ArgonTypography>
        </ArgonBox>

        {formateText(listing?.shortDescription)?.length > 350 ? (
          <ReadMore>{formateText(listing?.shortDescription)}</ReadMore>
        ) : (
          <>
            <ArgonTypography variant="jpBodyRegular">
              {formateText(listing?.shortDescription)}
            </ArgonTypography>
          </>
        )}
      </Card>
      <Card sx={{ p: 2 }}>
        <ArgonBox display="flex" alignItems="center" gap={1} mb={3}>
          <TextFileIconSvg />
          <ArgonTypography
            variant="jpH3Bold"
            sx={({ palette: { blue, white } }) => ({
              color: darkMode ? white.main : blue.seven,
            })}
          >
            Log Description
          </ArgonTypography>
        </ArgonBox>
        {formateText(listing?.logDescription)?.length > 350 ? (
          <ReadMore>{listing?.logDescription}</ReadMore>
        ) : (
          <ArgonTypography variant="jpBodyRegular">
            {formateText(listing?.logDescription)}
          </ArgonTypography>
        )}
      </Card>
      <Card sx={{ p: 2 }}>
        <ArgonBox display="flex" alignItems="center" gap={1} mb={3}>
          <TextFileIconSvg />
          <ArgonTypography
            variant="jpH3Bold"
            sx={({ palette: { blue, white } }) => ({
              color: darkMode ? white.main : blue.seven,
            })}
          >
            What’s The Story ?
          </ArgonTypography>
        </ArgonBox>
        {formateText(listing?.whatTheStory)?.length > 350 ? (
          <ReadMore>{formateText(listing?.whatTheStory)}</ReadMore>
        ) : (
          <ArgonTypography variant="jpBodyRegular">
            {formateText(listing?.whatTheStory)}
          </ArgonTypography>
        )}
      </Card>
      <Card sx={{ p: 2 }}>
        <ArgonBox display="flex" alignItems="center" gap={1} mb={3}>
          <TextFileIconSvg />
          <ArgonTypography
            variant="jpH3Bold"
            sx={({ palette: { blue, white } }) => ({
              color: darkMode ? white.main : blue.seven,
            })}
          >
            Is It Any Good ?
          </ArgonTypography>
        </ArgonBox>

        {formateText(listing?.isItAnyGood)?.length > 350 ? (
          <ReadMore>{formateText(listing?.isItAnyGood)}</ReadMore>
        ) : (
          <ArgonTypography variant="jpBodyRegular">
            {formateText(listing?.isItAnyGood)}
          </ArgonTypography>
        )}
      </Card>
      <Card sx={{ p: 2 }}>
        <ArgonBox display="flex" alignItems="center" gap={1} mb={3}>
          <TextFileIconSvg />
          <ArgonTypography
            variant="jpH3Bold"
            sx={({ palette: { blue, white } }) => ({
              color: darkMode ? white.main : blue.seven,
            })}
          >
            Talk To Your Kids About
          </ArgonTypography>
        </ArgonBox>

        {formateText(listing?.talkToYourKidsAbout)?.length > 350 ? (
          <ReadMore>{formateText(listing?.talkToYourKidsAbout)}</ReadMore>
        ) : (
          <ArgonTypography variant="jpBodyRegular">
            {formateText(listing?.talkToYourKidsAbout)}
          </ArgonTypography>
        )}
      </Card>
    </>
  );
};

export default MoreInfo;
