import { Card, styled, Grid } from "@mui/material";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { formateText } from "components/Common/formateText";
import { useArgonController } from "context";
import React from "react";
import { useState } from "react";

const ReadMore = ({ children }) => {
  const text = children;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <ArgonTypography variant="jpBodyRegular">
      {isReadMore ? text?.slice(0, 100) : text}
      <ArgonBox onClick={toggleReadMore} my={1}>
        <ArgonTypography
          variant="jpBodyMedium"
          sx={({ palette: { blue, white } }) => ({
            color: darkMode ? white.main : blue.five,
            cursor: "pointer",
          })}
        >
          {isReadMore ? "Read More" : " Show Less"}
        </ArgonTypography>
      </ArgonBox>
    </ArgonTypography>
  );
};

export default function ReviewCards({ item }) {
  const regex = /<(?:"[^"]*"['"]*|'[^']*'['"]|&nbsp;|[^'">])+>/g;
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: theme.palette.yellow.five,
    },
  }));
  const EmptyStarIconStyed = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
  }));
  return (
    <>
      <Grid item md={6} sx={12}>
        <Card sx={{ p: 4, height: "100%" }}>
          <ArgonBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <ArgonTypography
              sx={{
                fontSize: 20,
                fontweight: 500,
              }}
            >
              {item?.name}
            </ArgonTypography>

            <ArgonBox sx={{ display: "flex" }}>
              {[...Array(5)].map((x, index) => (
                <div key={index}>
                  {index < item?.rating ? (
                    <FullStarFillIconStyled />
                  ) : (
                    <EmptyStarIconStyed />
                  )}
                </div>
              ))}
            </ArgonBox>
          </ArgonBox>
          {item?.value ? (
            <ArgonTypography variant="jpBodyRegular">
              {formateText(item?.value)?.length > 100 ? (
                <ReadMore>{formateText(item?.value)}</ReadMore>
              ) : (
                <>
                  <ArgonTypography variant="jpBodyRegular">
                    {formateText(item?.value)}
                  </ArgonTypography>
                </>
              )}
            </ArgonTypography>
          ) : (
            <ArgonTypography variant="jpBodyRegular" className="tr-w-1">
              Not Present
            </ArgonTypography>
          )}
        </Card>
      </Grid>
    </>
  );
}
