import API2 from "api2";
import { errorHandling } from "components/Common/errorhandling";

const config = (accessToken) => ({
  headers: { Authorization: `Bearer ${accessToken}` },
});
export const addUserCustomFieldsApi = (payload) => {
    return async (dispatch, getState) => {
      const token = getState()?.authState?.user?.token;
      return new Promise(function (resolve, reject) {
        API2.post(`user-customFields/add`, payload, config(token))
          .then((res) => {
            const { data } = res;
            dispatch({ type: "ADD_CUSTOM_FIELDS_SUCCESS", payload: "success", data });
            resolve(data);
          })
          .catch((err) => {
            const errorMessage = err?.response?.data?.message;
            dispatch({ type: "ADD_CUSTOM_FIELDS_FILED", payload: "failed", err });
            reject(errorMessage);
            errorHandling(err?.response);
          });
      });
    };
  };
export const getUserCustomFieldsApi = (payload) => {
    return async (dispatch, getState) => {
      const token = getState()?.authState?.user?.token;
      return new Promise(function (resolve, reject) {
        API2.get(`user-customFields/view`, payload, config(token))
          .then((res) => {
            const { data } = res;
            dispatch({ type: "GET_CUSTOM_FIELDS_SUCCESS", payload: "success", data });
            resolve(data);
          })
          .catch((err) => {
            const errorMessage = err?.response?.data?.message;
            dispatch({ type: "GET_CUSTOM_FIELDS_FILED", payload: "failed", err });
            reject(errorMessage);
            errorHandling(err?.response);
          });
      });
    };
  };
export const updateUserCustomFieldsApi = ({id,payload}) => {
    return async (dispatch, getState) => {
      const token = getState()?.authState?.user?.token;
      return new Promise(function (resolve, reject) {
        API2.put(`user-customFields/${id}`, payload, config(token))
          .then((res) => {
            const { data } = res;
            dispatch({ type: "GET_CUSTOM_FIELDS_SUCCESS", payload: "success", data });
            resolve(data);
          })
          .catch((err) => {
            const errorMessage = err?.response?.data?.message;
            dispatch({ type: "GET_CUSTOM_FIELDS_FILED", payload: "failed", err });
            reject(errorMessage);
            errorHandling(err?.response);
          });
      });
    };
  };
