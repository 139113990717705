/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-github-btn
import GitHubButton from "react-github-btn";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
  setOpenConfigurator,
  setDarkSidenav,
  setMiniSidenav,
  setFixedNavbar,
  setSidenavColor,
  setDarkMode,
} from "context";
import FullAccessStyled from "./FullAccessStyled";
import { Backdrop } from "@mui/material";
import { useNavigate } from "react-router-dom";

function FullAccess({ ...rest }) {
  let openConfigurator = true
  const navigation = useNavigate()
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const handleClick =()=>{
    navigation('/join')
  }
  return (
    <>

      <FullAccessStyled variant="permanent" ownerState={{ openConfigurator }}
        {...rest}
      >
        <ArgonBox sx={{ margin: '0 auto' }}>
          <ArgonBox display="flex" alignItems="center" flexDirection="column" textAlign="column" my={3} gap={3}>

            <ArgonTypography variant="jpBodyMedium"
              sx={({ palette: { blue, white } }) => ({
                color: darkMode ? white.main : blue.seven,
                textAlign: 'center'
              })}>
              You have 3 free reviews to review, we hope you enjoy them.
            </ArgonTypography>

            <ArgonBox>
              <ArgonTypography
                variant="jpH3Bold"
                sx={({ palette: { natural, white, blue } }) => ({
                  color: darkMode ? white.main : blue.seven,
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',

                })}>
                Subscribing will give you access to unlimited reviews as
                <br />
                 well as age-based media recommendations.
              </ArgonTypography>
            </ArgonBox>

            <ArgonButton variant="gradient" color="info" onClick={handleClick}>
              Get unlimited reviews
            </ArgonButton>

          </ArgonBox>
        </ArgonBox>
      </FullAccessStyled>


    </>
  );
}

export default FullAccess;
