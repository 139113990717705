/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import BgImage from "assets/images/bg/bg1.png";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import FormField from "components/Common/FormField";
import FormSelect from "components/Common/FormSelect";
import ArgonSnackbar from "components/ArgonSnackbar";
import { useDispatch } from "react-redux";
import { registerAction } from "Actions/loginAction";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { addFamily } from "Actions/userAction";
const listingAuthor = [
  { value: "parent", label: "Parent" },
  { value: "teenager", label: "Teenager" },
];
function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [registerInfo, setRegisterInfo] = useState();
  const newUserData = useSelector((state) => state?.authState?.data);
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });
  const registerData = useSelector((state) => state?.authState);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterInfo({
      ...registerInfo,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    if (
      registerInfo?.cmPassword &&
      registerInfo?.cmPassword !== registerInfo?.password
    ) {
      setAlert({
        ...alert,
        status: "error",
        isOpen: true,
        alertMsg: "Password must be same.",
        bgColor: "bgRed",
      });
    } else {
      dispatch(registerAction({ ...registerInfo, role: "parent" }));

      setAlert({
        ...alert,
        status: registerData?.status,
        isOpen: true,
        alertMsg: registerData?.message,
        bgColor: "bggreen",
      });
      navigate("/signin");
    }
  };

  return (
    <>
      <IllustrationLayout
        title="Sign Up"
        description="Fill the form to sign into your account"
        illustration={{
          image: BgImage,
          title: "",
          description: "",
        }}
      >
        <ArgonBox component="form" role="form">
          {/* <ArgonBox mb={2}>
            <FormField
              type="text"
              placeholder="Your User Name"
              size="large"
              label="User Name"
              name="userName"
              onChange={handleChange}
            />
          </ArgonBox> */}
          <ArgonBox mb={2}>
            <FormField
              type="text"
              placeholder="Your Name"
              size="large"
              label="Name"
              name="name"
              onChange={handleChange}
            />
          </ArgonBox>
          <ArgonBox mb={2}>
            <FormField
              type="email"
              placeholder="Your Email"
              size="large"
              label="Email Address"
              name="email"
              onChange={handleChange}
            />
          </ArgonBox>

          <Grid container spacing={2}>
            <Grid item md={12} sx={12}>
              <ArgonBox mb={2}>
                <FormField
                  type="Number"
                  placeholder="Your Age"
                  size="large"
                  label="Age"
                  name="age"
                  onChange={handleChange}
                />
              </ArgonBox>
            </Grid>
            {/* <Grid item md={6} sx={12}>
              <ArgonBox>
                <FormSelect
                  options={listingAuthor}
                  placeholder="Your Role"
                  size="large"
                  name=""
                  label="Role"
                  onChange={(newValue) => {
                    setRegisterInfo({
                      ...registerInfo,
                      role: newValue,
                    });
                  }}
                />
              </ArgonBox>
            </Grid> */}
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={6} sx={12}>
              <ArgonBox mb={2}>
                <FormField
                  type="password"
                  placeholder="Your Password"
                  size="large"
                  label="Password"
                  name="password"
                  onChange={handleChange}
                />
              </ArgonBox>
            </Grid>
            <Grid item md={6} sx={12}>
              <ArgonBox mb={2}>
                <FormField
                  type="password"
                  placeholder="Confirm Yout Password"
                  size="large"
                  name="cmPassword"
                  label="Confirm Password"
                  onChange={handleChange}
                />
              </ArgonBox>
            </Grid>
          </Grid>

          <ArgonBox mt={4} mb={1}>
            <ArgonButton
              color="info"
              size="large"
              fullWidth
              onClick={handleSubmit}
            >
              Sign Up
            </ArgonButton>
          </ArgonBox>
          <ArgonBox mt={3} textAlign="center">
            <ArgonTypography
              variant="jpBodyRegular"
              color="text"
              fontWeight="regular"
            >
              Already have an account?&nbsp;
              <ArgonTypography
                component={Link}
                to="/signin"
                variant="jpBodyMedium"
                color="info"
                fontWeight="bold"
              >
                Sign in
              </ArgonTypography>
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      </IllustrationLayout>
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </>
  );
}

export default SignUp;
