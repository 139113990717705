import React, { useEffect, useState } from "react";
import { Card, Divider, Grid, styled } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookMarkCard from "examples/Cards/BookMarkCard";
import Slider from "react-slick";
import { getBookmarkList } from "Actions/listingAction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FilteredBookmarkedCard from "./filteredBookmarkedCard";
import { getCategoriesListApi } from "Actions/categoryAction";

const MyBookMarks = () => {
  const dispatch = useDispatch()
  const [listingList, setListingList] = useState([])
  const [isShow,setIsShow] = useState(true )
  const userData = useSelector((state) => state?.authState?.user?.user);
  const BookmarkList = useSelector((state) => state?.listingState?.bookmarkList?.listingIds)
  const categoryList = useSelector((state) => state.categoryState.categoriesList || []);
  const [bookmarkArray, setBookmarkArray] = useState([])
  const token = useSelector((state) => state?.authState?.user?.token);
  useEffect(() => {
    if(token && (userData?.role === "parent" || userData?.role === "teenager")){
      dispatch(getBookmarkList(userData?._id))
    }   
    dispatch(getCategoriesListApi());
  }, [dispatch])
  useEffect(() => {
    let bookmarkListData = []
    bookmarkListData = BookmarkList?.map((item) => item).reverse()
    setBookmarkArray(bookmarkListData || [])
  }, [BookmarkList])
  useEffect(() => {
    const filterValueArray = [];
    categoryList.map((item) => {
      filterValueArray.push({
        [item?.name]: bookmarkArray.filter((i) => i.category?.name === item.name),
      });
    });
    setListingList(filterValueArray);
  }, [bookmarkArray, categoryList])
  return (
    <>
      <Card sx={{ p: 4 }}>
        <ArgonTypography variant="h5">My Saved List </ArgonTypography>
        {!bookmarkArray.length && (
          <ArgonBox display="flex" flexDirection="column" gap={2}>
            <ArgonTypography
              variant="jpBodyRegular"
              display="flex"
              alignItems="center"
              gap={1}
            >
              If you wish to revisit Joyful reviews at a later time, you can use
              the <BookmarkBorderIcon /> button to keep track of them.
            </ArgonTypography>
          </ArgonBox>
        )}
        {bookmarkArray?.length ? (
          <>
             <ArgonBox my={4}>
             <ArgonBox
               mb={4}
               display="flex"
               alignItems="center"
               justifyContent="space-between"
             >
               <ArgonTypography variant="jpH2">Recently Saved </ArgonTypography>
               {bookmarkArray?.length >4 && (
               <ArgonTypography
                 variant="jpBodyMedium"
                 sx={({ palette: { blue } }) => ({
                   color: blue.five,
                   cursor: "pointer",
                 })}
                 onClick={()=>setIsShow(!isShow)}
               >
                 See {isShow ? 'All' :"Less"}
               </ArgonTypography>
               )}
             </ArgonBox>
   
             <Grid container spacing={3}>
               {bookmarkArray?.length ? (
                 bookmarkArray.map((item,index) => (
                   <>
                   {isShow ?  (
                   <>
                   {index<4 && (
                    <Grid item md={3} xs={12}>
                    <BookMarkCard i={item} />
                   </Grid>
                   )}
                   </>
                   ):(
                   <Grid item md={3} xs={12}>
                     <BookMarkCard i={item} />
                   </Grid>
                   )}
                   </>
                 ))
               ) : (
                 <></>
               )}
   
             </Grid>
           </ArgonBox>
             <Divider />
             </>
        ):(
          <></>
        )}

     
        {listingList?.length ? (
          <>
            {
              listingList.map((item) => (
                <>
                <FilteredBookmarkedCard item={item} />
                </>
              ))}</>) : (<></>)}

      </Card>
    </>
  );
};

export default MyBookMarks;
