import React, { useEffect, useState } from "react";
import SearchCard from "../components/ArticleCard";
import { Container, Grid } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import api2 from "api2";
import axios from "axios";
import ArgonPagination from "components/ArgonPagination";
import Icon from "@mui/material/Icon";
import ArticleFilter from "../components/ArticleFilter";
import ArticleCard from "../components/ArticleCard";

const ArticlePage = () => {
  const { id } = useParams();
  const navigator = useNavigate();
  const [selectedValue, setSelectedValue] = useState();
  const token = useSelector((state) => state?.authState?.user?.token);
  const [categoryList, setCategoryList] = useState();
  const [filterCategory, setFilterCategory] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(8);
  const [value, setValue] = useState([10, 20]);
  const categories = useSelector((state) => state.categoryState.categoriesList);
  const fetchListing = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let query = "";
      if (id) {
        query += `${query ? `&` : ""}categoryId=${id}`;
      }
      if(value.length){
        query += `${query ? `&` : ""}age=${encodeURIComponent(JSON.stringify(value))}`;

      }
      await api2
        .get(
          `article?${query}`,
          config
        )
        .then((res) => {
          if (res) {
            setTotalCount(res?.data?.data?.articleCount);
            setSelectedValue(res?.data?.data?.article);
          }
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  useEffect(() => {
    setCategoryList(categories || []);
  }, [categories]);
  useEffect(() => {
    fetchListing();
  }, [filterCategory, skip,id,value]);
  const addOneAndMakeInteger = (number) => {
    if (!Number.isInteger(number)) {
      number += 1;
    }
    return Math.floor(number);
  };
  return (
    <Container>
      <Grid container spacing={4} my={3}>
        <Grid item sm={12}>
          <ArticleFilter
            categoryList={categoryList}
            selectedValue={selectedValue}
            setFilterCategory={setFilterCategory}
            filterCategory={filterCategory}
            value={value}
           setValue={setValue}
          />
        </Grid>
        <Grid item sm={12}>
          <ArticleCard selectedValue={selectedValue} />
        </Grid>
      </Grid>
      {selectedValue?.length > 10 ? (
      <ArgonPagination variant={"gradient"} color={"info"}>
        <>
          {pageIndex !== 0 && (
            <>
              <ArgonPagination
                item
                onClick={() => {
                  setPageIndex(parseInt(pageIndex - 1));
                  setSkip(parseInt(pageIndex - 1) * 8);
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </ArgonPagination>
            </>
          )}
          {console.log("totalCount", totalCount)}
          {[...Array(addOneAndMakeInteger(totalCount / 8))].map((x, index) => (
            <ArgonPagination
              item
              // key={1}
              onClick={() => {
                setPageIndex(parseInt(index));
                setSkip(index * 8);
              }}
              active={pageIndex === index}
            >
              {index + 1}
            </ArgonPagination>
          ))}
        </>
        {parseInt(totalCount / 8) !== pageIndex && (
          <ArgonPagination
            item
            onClick={() => {
              setPageIndex(parseInt(pageIndex + 1));
              setSkip(parseInt(pageIndex + 1) * 8);
            }}
          >
            <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
          </ArgonPagination>
        )}
      </ArgonPagination>
      ):(
        <></>
      )}
    </Container>
  );
};

export default ArticlePage;
