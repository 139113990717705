import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Error404 from "layouts/authentication/error/404";

import Home from "layouts/user/home";
import SingleCategory from "layouts/user/categoey/single-category";
import AddReview from "layouts/user/review/add-review";
import Join from "layouts/user/join";
import Search from "layouts/user/search";
import AllArticles from "layouts/user/articles";
import ViewArticle from "layouts/user/articles/view/ViewArticle";

const routes = [
  {
    route: '/signin',
    isPublic: true,
    component: <SignIn />,
    accessRoles: [],
  },
  {
    route: '/signup',
    isPublic: true,
    component: <SignUp />,
    accessRoles: [],
  },
  {
    route: '/404',
    isPublic: true,
    component: <Error404 />,
    accessRoles: ['parent', 'teenager', 'author', 'editor', 'superadmin'],
  },
  {
    route: '/home',
    component: <Home />,
    accessRoles: ['parent', 'teenager', 'author', 'editor', 'superadmin'],
  },
  {
    route: '/all-article/:id',
    component: <AllArticles />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/view-article/:id',
    component: <ViewArticle />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/all-article',
    component: <AllArticles />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/home/:categoryName/:categoryId',
    component: <Home />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/:categoryName/single-category/:listingId',
    component: <SingleCategory />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/:categoryName/single-category/:listingId/add-review',
    component: <AddReview />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/:categoryName/single-category/:listingId/edit-review/:reviewId',
    component: <AddReview />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/join',
    component: <Join />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/search/:word',
    component: <Search />,
    accessRoles: ['parent', 'teenager'],
  },
  {
    route: '/search',
    component: <Search />,
    accessRoles: ['parent', 'teenager'],
  },
];

export default routes;
