import React, { useEffect, useState } from "react";
import { Card, Checkbox, Slider, Tooltip, styled } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useArgonController } from "context";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";

const minDistance = 2;

const SearchFilter = ({
  categoryList,
  selectedValue,
  filterCategory,
  setFilterCategory,
  value,
  setValue,
  filterRating,
  setFilterRating
}) => {
  const navigator = useNavigate();
  const [controller] = useArgonController();
  const { darkMode } = controller;


  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };

  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: theme.palette.yellow.five,
    },
  }));
  const handleClick =(e)=>{
    const selectedRating = [...filterRating]
    if(e.target.checked){
      selectedRating.push(parseInt(e.target.id))
    }else{
      const findIndex = selectedRating.indexOf(parseInt(e.target.id))
      selectedRating.splice(findIndex,1)
    }
    setFilterRating(selectedRating)
  } 
  return (
    <>
      <ArgonBox>
        <Card sx={{ p: 2, overflow: "visible" }}>
          <ArgonTypography variant="caption1">Filter results</ArgonTypography>

          <ArgonBox display="flex" alignItems="center" gap={1}>
            <ArgonTypography variant="caption">
              Showing {selectedValue?.length} results.
            </ArgonTypography>
            <ArgonTypography
              variant="button"
              fontWeight="regular"
              sx={{
                cursor: "pointer",
                userSelect: "none",
                textDecoration: "underline",
              }}
              onClick={() => {
                navigator("/search");
                // eslint-disable-next-line no-restricted-globals
                window.location.reload();
              }}
            >
              Clear filters
            </ArgonTypography>
          </ArgonBox>

          <ArgonBox display="flex" flexDirection="column" gap={1} my={2}>
            <ArgonTypography variant="subtitle1">
              Entertainment Type
            </ArgonTypography>
            {categoryList?.length ? (
              <>
                {categoryList?.map((item) => (
                  <ArgonBox display="flex" alignItems="center" gap={1}>
                    <Checkbox
                      onChange={(e) => {
                        const filterCategoryArray = filterCategory;
                        if (e.target.checked) {
                          filterCategoryArray.push(item?._id);
                        } else {
                          const findIndex = filterCategoryArray.indexOf(
                            item?._id
                          );
                          filterCategoryArray.splice(findIndex, 1);
                        }
                        console.log("filterCategoryArray", filterCategoryArray);
                        setFilterCategory([...filterCategoryArray]);
                      }}
                    />
                    <ArgonTypography
                      variant="subtitle2"
                      fontWeight="regular"
                      // onClick={handleSetAgremment}
                      sx={{ cursor: "pointer", userSelect: "none" }}
                    >
                      {item?.name}
                    </ArgonTypography>
                  </ArgonBox>
                ))}
              </>
            ) : (
              <></>
            )}
          </ArgonBox>

          <ArgonBox>
            <ArgonTypography variant="subtitle1">Age</ArgonTypography>

            <Slider
              value={value}
              sx={({ palette: { white, blue } }) => ({
                mt: 3,
                "& .MuiSlider-valueLabel": {
                  fontSize: 16,
                  fontWeight: "bold",
                  bottom: 0,
                  backgroundColor: "unset",
                  color: blue.five,
                  "&:before": {
                    display: "none",
                  },
                  "& *": {
                    background: "transparent",
                    color: darkMode ? "#fff" : "#000",
                  },
                },
              })}
              onChange={handleChange}
              valueLabelDisplay="auto"
              // getAriaValueText={valuetext}
              disableSwap
            />
          </ArgonBox>
          <ArgonBox>
            <ArgonTypography variant="subtitle1">Rating</ArgonTypography>
            <ArgonBox>
              <ArgonBox display="flex" alignItems="basline" gap={1} my={1}>
                <Checkbox id={1} onChange={handleClick}/>
                <ArgonBox
                  sx={{
                    cursor: "pointer",
                    userSelect: "none",
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <FullStarFillIconStyled />
                </ArgonBox>
              </ArgonBox>
              <ArgonBox display="flex" alignItems="basline" gap={1} my={1}>
              <Checkbox id={2} onChange={handleClick}/>
                <ArgonBox
                  sx={{
                    cursor: "pointer",
                    userSelect: "none",
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <FullStarFillIconStyled />
                  <FullStarFillIconStyled />
                </ArgonBox>
              </ArgonBox>
              <ArgonBox display="flex" alignItems="basline" gap={1} my={1}>
              <Checkbox id={3} onChange={handleClick}/>
                <ArgonBox
                  sx={{
                    cursor: "pointer",
                    userSelect: "none",
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <FullStarFillIconStyled />
                  <FullStarFillIconStyled />
                  <FullStarFillIconStyled />
                </ArgonBox>
              </ArgonBox>
              <ArgonBox display="flex" alignItems="basline" gap={1} my={1}>
              <Checkbox id={4} onChange={handleClick}/>
                <ArgonBox
                  sx={{
                    cursor: "pointer",
                    userSelect: "none",
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <FullStarFillIconStyled />
                  <FullStarFillIconStyled />
                  <FullStarFillIconStyled />
                  <FullStarFillIconStyled />
                </ArgonBox>
              </ArgonBox>
              <ArgonBox display="flex" alignItems="basline" gap={1} my={1}>
              <Checkbox id={5} onChange={handleClick}/>
                <ArgonBox
                  sx={{
                    cursor: "pointer",
                    userSelect: "none",
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <FullStarFillIconStyled />
                  <FullStarFillIconStyled />
                  <FullStarFillIconStyled />
                  <FullStarFillIconStyled />
                  <FullStarFillIconStyled />
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </Card>
      </ArgonBox>
    </>
  );
};

export default SearchFilter;
