import React from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import UserNavbar from "examples/Navbars/UserNavbar";
import ProfileSidebar from "./profile-sidebar";
import { Container, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "examples/Footer";

const Profile = () => {
  return (
    <>
      <PageLayout>
        <UserNavbar />
        <Container>
          <Grid container spacing={4} my={{ md: 0, xs: 1 }}>
            <Grid item lg={3} xs={12} mx={{ md: 0, xs: 2 }}>
              <ProfileSidebar />
            </Grid>
            <Grid item lg={9} xs={12} mx={{ md: 0, xs: 2 }}>
              <Outlet />
            </Grid>
          </Grid>
        </Container>
      </PageLayout>
      <Footer />
    </>
  );
};

export default Profile;
