/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonAvatar from "components/ArgonAvatar";

function ByWhomCell({ name, image }) {
  return (
    <ArgonBox display="flex" alignItems="center" pr={2}>
      <ArgonBox mr={2}>
        {image ? (
          <ArgonBox
            component="img"
            src={image}
            sx={{
              height: "48px",
              width: "48px",
              objectFit: "cover",
            }}
            alt="profile-image"
            borderRadius="lg"
            shadow="md"
          />) :
          (<ArgonAvatar
            variant="rounded"
            sx={{
              height: "48px",
              width: "48px",
            }}
            alt="profile-image"
            shadow="md"
            bgColor="light"
          />)}

        {/* <ArgonAvatar
          src={image}
          alt={name}
          variant="rounded"
          // sx={{
          //   height: "42px",
          //   width: "42px",
          // }}
          size="lg"
          bgColor="light"
        /> */}
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonTypography
          variant="jpBodyMedium"
          sx={{ textTransform: "capitalize" }}
        >
          {name}
        </ArgonTypography>
      </ArgonBox>
    </ArgonBox>
  );
}

// Setting default values for the props or ByWhomCell
ByWhomCell.defaultProps = {
  tooltip: "",
};

// Typechecking props for the ByWhomCell
ByWhomCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["info", "success", "warning", "error"]).isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  tooltip: PropTypes.string,
};

export default ByWhomCell;
