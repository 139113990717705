/* eslint-disable no-param-reassign */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, Fragment } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-router components
import { Link, NavLink, useLocation } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import Container from "@mui/material/Container";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 PRO MUI examples components
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Argon Dashboard 2 PRO MUI Base Styles
import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 PRO React context
import { useArgonController } from "context";
import JoyfulLogo from "assets/images/logos/Joyful-logo.svg";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Switch,
  styled,
} from "@mui/material";
import theme from "assets/theme";
import { setDarkMode } from "context";
import { useDispatch } from "react-redux";

function DefaultNavbar({ routes, brand, transparent, light, action }) {
  const [controller, dispatch] = useArgonController();
  const { darkMode } = controller;
  const location = useLocation();
  const { palette, functions, typography } = theme;
  const { white, blue } = palette;

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const dispatchMode = useDispatch();

  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  const activeRootStyle = {
    backgroundColor: blue.five,
    color: white.main,

    ":hover": {
      backgroundColor: blue.five,
      color: white.main,
    },
  };

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  useEffect(() => {
    setDarkMode(dispatch, darkMode);
  }, [darkMode]);

  const MaterialUISwitch = styled(Switch)(() => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      top: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: `${darkMode} ? ${blue.five} : ${blue.five} !important`,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: darkMode ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: darkMode ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  return (
    <Container>
      <ArgonBox
        py={2}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 3 }}
        my={2}
        mx={3}
        width="calc(100% - 48px)"
        borderRadius="lg"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        position="absolute"
        left={0}
        zIndex={99}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(darkMode ? background.dark : white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <ArgonBox component={Link} to="/home" display="flex">
            <ArgonBox component="img" src={JoyfulLogo} alt="Joyful" />
          </ArgonBox>

          <ArgonBox display="flex" alignItems="center" gap={1}>
            <FormGroup
              sx={{
                // width: "100%",
                position: "relative",
              }}
            >
              <FormControlLabel
                control={<MaterialUISwitch sx={{ m: 1 }} checked={darkMode} />}
                label=""
                onChange={(e) => {
                  dispatchMode({
                    type: "MODE",
                    status: "success",
                    data: !darkMode,
                  });
                  setDarkMode(dispatch, !darkMode);
                }}
              />
            </FormGroup>
            <ArgonBox display={{ xs: "none", lg: "flex" }} gap={2}>
              <ArgonButton
                component={Link}
                to={"/signin"}
                size="small"
                sx={({ palette: { blue, white } }) => ({
                  background: blue.one,
                  color: blue.eight,
                  padding: "12px 24px",
                  boxShadow: "none",

                  ":hover": {
                    background: blue.one,
                  },
                  ":focus:not(:hover)": {
                    background: blue.one,
                  },
                  ...(location.pathname.includes("signin") && activeRootStyle),
                })}
              >
                Sign In
              </ArgonButton>
              <ArgonButton
                component={Link}
                to={"/signup"}
                size="small"
                sx={({ palette: { blue } }) => ({
                  background: blue.one,
                  color: blue.eight,
                  padding: "12px 24px",
                  boxShadow: "none",

                  ":hover": {
                    background: blue.one,
                  },
                  ":focus:not(:hover)": {
                    background: blue.one,
                  },
                  ...(location.pathname.includes("signup") && activeRootStyle),
                })}
              >
                Sign Up
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>

          <ArgonBox
            display={{ xs: "inline-block", lg: "none" }}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color={transparent || darkMode ? "white" : "inherit"}
            sx={{ cursor: "pointer" }}
            onClick={openMobileNavbar}
          >
            <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox
          shadow={transparent ? "lg" : "none"}
          borderRadius="md"
          px={transparent ? 2 : 0}
          sx={{
            backgroundColor: ({
              palette: { white, transparent: transparentColor, background },
            }) => {
              let bgColorValue = transparentColor.main;

              if (transparent && darkMode) {
                bgColorValue = background.dark;
              } else if (transparent) {
                bgColorValue = white.main;
              }

              return bgColorValue;
            },
          }}
        >
          {mobileView && (
            <DefaultNavbarMobile routes={routes} open={mobileNavbar} />
          )}
        </ArgonBox>
      </ArgonBox>
    </Container>
  );
}

// Declaring default props for DefaultNavbar
DefaultNavbar.defaultProps = {
  brand: "Joyful",
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["contained", "outlined", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
      "white",
    ]),
    label: PropTypes.string.isRequired,
  }),
};

export default DefaultNavbar;
