import React from "react";
import { Card, Grid, styled } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useArgonController } from "context";
import { ReactComponent as TextFileIcon } from "assets/images/icons/TextFile.svg";
import borders from "assets/theme/base/borders";
import { useState } from "react";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import { formateText } from "components/Common/formateText";

const ReadMore = ({ children }) => {
  const text = children;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <ArgonTypography variant="jpBodyRegular">
      {isReadMore ? text?.slice(0, 350) : text}
      <ArgonBox onClick={toggleReadMore} my={1}>
        <ArgonTypography
          variant="jpBodyMedium"
          sx={({ palette: { blue, white } }) => ({
            color: darkMode ? white.main : blue.five,
            cursor: "pointer",
          })}
        >
          {isReadMore ? "Read More" : " Show Less"}
        </ArgonTypography>
      </ArgonBox>
    </ArgonTypography>
  );
};

const Reviews = ({ listing }) => {
  const [controller] = useArgonController();

  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: theme.palette.yellow.five,
    },
  }));
  const EmptyStarIconStyed = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
  }));

  const { darkMode } = controller;
  const { borderRadius } = borders;

  const TextFileIconSvg = styled(TextFileIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.blue.seven,
    },
  }));
  const regex = /<(?:"[^"]*"['"]*|'[^']*'['"]|&nbsp;|[^'">])+>/g;

  return (
    <>
    {listing?.internalReviewFieldsList.length  ? (
      <>
      {
        listing?.internalReviewFieldsList.map((item)=>(
      <Card sx={{ p: 2 }}>
        <ArgonBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          mb={3}
        >
          <ArgonBox display="flex" alignItems="center" gap={1}>
            <TextFileIconSvg />
            <ArgonTypography
              variant="jpH3Bold"
              sx={({ palette: { blue, white } }) => ({
                color: darkMode ? white.main : blue.seven,
              })}
            >
              {item?.name}
            </ArgonTypography>
          </ArgonBox>
              {console.log('item?.rating', item?.rating)}
          <ArgonBox display="flex" alignItems="stretch" gap={1}>
            <ArgonBox sx={{ display: "flex" }}>
              {[...Array(5)].map((x, index) => (
                <div key={index}>
                  {index < item?.rating ? (
                    <FullStarFillIconStyled />
                  ) : (
                    <EmptyStarIconStyed />
                  )}
                </div>
              ))}
            </ArgonBox>

            <ArgonTypography variant="jpBodyMedium">
              ({item?.rating})
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>

        {formateText(item?.value)?.length > 350 ? (
          <ReadMore>
            {formateText(item?.value)}
          </ReadMore>
        ) : (
          <ArgonTypography variant="jpBodyRegular">
            {" "}
            {formateText(item?.value)}
          </ArgonTypography>
        )}
      </Card>
        ))
      }
      </>
    ):(
      <></>
    )}
    </>
  );
};

export default Reviews;
