import { Card, Dialog, styled } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "assets/images/icons/Close.svg";
import { useArgonController } from "context";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import FormEditor from "components/Common/FormEditor";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import api2 from "api2";
import axios from "axios";
import ArgonSnackbar from "components/ArgonSnackbar";

export default function FlagModel({
  isOpenModel,
  handleModel,
  selectedUser,
  selectedReviewedId,
}) {
  const [controller] = useArgonController();
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });
  const token = useSelector((state) => state?.authState?.user?.token);
  const [comment, setComment] = useState("");
  const { darkMode } = controller;
  const ClosrIconSvg = styled(CloseIcon)(({ theme }) => ({
    height: 32,
    width: 32,
    cursor: "pointer",
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.eight,
    },
  }));
  const handleSubmit = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await api2
        .post(
          `admin/users/flag/${selectedUser}`,
          { flag: true, comment: comment, reviewedId: selectedReviewedId },
          config
        )
        .then((res) => {
          if (res)
            setAlert({
              status: "success",
              isOpen: true,
              alertMsg: "User flagged successfully.",
              bgColor: "bggreen",
            });
          setTimeout(() => {
            setComment("");
            handleModel();
          }, 500);
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  return (
    <>
      <Dialog
        open={isOpenModel}
        onClose={handleModel}
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Card sx={{ p: 4, overflow: "unset" }}>
          <ArgonBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <ArgonTypography variant="jpH3Bold">
              Report this user
            </ArgonTypography>

            <ArgonBox onClick={handleModel}>
              <ClosrIconSvg />
            </ArgonBox>
          </ArgonBox>

          <ArgonBox mb={1}>
            <ArgonTypography variant="jpBodyMedium">
              When you flag a review or comment, it will be immediately removed
              from our site until we have evaluated it. Please be assured that
              we take your feedback seriously. It is our privilege to have you
              as part of our community.
            </ArgonTypography>{" "}
          </ArgonBox>
          <ArgonBox>
            <ArgonBox>
              <FormEditor
                label=""
                placeholder={`Write here`}
                value={comment || ""}
                onChange={(value) => {
                  setComment(value);
                }}
              />
            </ArgonBox>
          </ArgonBox>
          <ArgonBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 2,
              mt: 3,
              width: "100%",
            }}
          >
            <ArgonButton
              size="large"
              sx={({ palette: { blue } }) => ({
                background: blue.one,
                px: 3,
                ":hover": {
                  background: blue.one,
                },
                ":focus:not(:hover)": {
                  background: blue.one,
                },
              })}
              onClick={() => handleModel()}
            >
              Cancel
            </ArgonButton>
            <ArgonButton
              size="large"
              sx={({ palette: { blue, white } }) => ({
                background: blue.five,
                color: white.main,
                px: 3,
                ":hover": {
                  background: blue.five,
                },
                ":focus:not(:hover)": {
                  background: blue.five,
                },
              })}
              onClick={handleSubmit}
            >
              Submit
            </ArgonButton>
          </ArgonBox>
        </Card>
        {alert.isOpen && (
          <ArgonSnackbar
            color={alert.status}
            icon="check"
            title={alert.status}
            content={alert.alertMsg}
            open={alert.isOpen}
            onClose={() => setAlert({ isOpen: false })}
            close={() => setAlert({ isOpen: false })}
            bgColor={alert.bgColor}
          />
        )}
      </Dialog>
    </>
  );
}
