import ArgonBox from 'components/ArgonBox';
import { Card, Grid, Switch, styled } from "@mui/material";
import React from 'react'
import ArgonTypography from 'components/ArgonTypography';
import { ReactComponent as TextFileIcon } from "assets/images/icons/TextFile.svg";
import { useArgonController } from 'context';
import FormEditor from 'components/Common/FormEditor';
import FormSelect from 'components/Common/FormSelect';
const listingRating = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];
export default function InternalReviews({ internalReviewFieldsList, setInternalReviewFieldsList }) {
    console.log('internalReviewFieldsList', internalReviewFieldsList)
    const TextFileIconSvg = styled(TextFileIcon)(({ theme }) => ({
        height: 24,
        width: 24,
        path: {
            fill: darkMode ? theme.palette.white.main : theme.palette.blue.seven,
        },
    }));
    const [controller] = useArgonController();
    const { darkMode } = controller;
    return (
        <>
            {internalReviewFieldsList?.length ? (
                // <></>
                <>
                        {console.log('internalReviewFieldsList12', internalReviewFieldsList)}
                    {internalReviewFieldsList.map((item,index) => (
                        <ArgonBox display="flex" flexDirection="column" gap={3} mb={5}>
                            <ArgonBox display="flex" alignItems="center" gap={1}>
                                <TextFileIconSvg />
                                <ArgonTypography
                                    variant="jpH3Bold"
                                    sx={({ palette: { blue, white } }) => ({
                                        color: darkMode ? white.main : blue.seven,
                                    })}
                                >
                                    {item?.name}
                                </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox component="form" role="form">
                                <Grid container spacing={3}>
                                    <Grid item lg={6} sm={12} xs={12}>
                                        <ArgonBox>
                                            <FormEditor
                                                value={item.value || ''}
                                                onChange={(value) => {
                                                    const internalReviewFieldsListArray = internalReviewFieldsList
                                                    internalReviewFieldsListArray.splice(index, 1, {
                                                            ...item,
                                                            value: value,
                                                            })
                                                                setInternalReviewFieldsList(internalReviewFieldsListArray);
                                                }}
                                                label="Description"
                                            />
                                        </ArgonBox>
                                    </Grid>

                                    <Grid item lg={6} xs={12} sm={12}>
                                        <ArgonBox>
                                            <FormSelect
                                                options={listingRating}
                                                value={listingRating.find(
                                                    (i) => i?.value === item?.rating
                                                )}
                                                onChange={(selected) => {
                                                    const internalReviewFieldsListArray = internalReviewFieldsList
                                                    internalReviewFieldsListArray.splice(index, 1, {
                                                            ...item,
                                                            rating: selected?.value,
                                                            })
                                                            console.log('selected?.value', selected?.value)
                                                            console.log('internalReviewFieldsListArray', internalReviewFieldsListArray)
                                                                setInternalReviewFieldsList([...internalReviewFieldsListArray]);
                                                }}
                                                placeholder="Choose Rating"
                                                size="large"
                                                label="Rating"
                                            />
                                        </ArgonBox>
                                    </Grid>
                                </Grid>
                            </ArgonBox>
                        </ArgonBox>
                    ))}
                </>
            ) : (
                <></>
            )}

        </>
    )
}
