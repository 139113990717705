/* eslint-disable no-param-reassign */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, Fragment } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-router components
import { Link, NavLink, useLocation } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import Container from "@mui/material/Container";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI Base Styles
import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 PRO React context
import { useArgonController } from "context";
import JoyfulLogo from "assets/images/logos/Joyful-logo.svg";
import { Card } from "@mui/material";
import ArgonInput from "components/ArgonInput";
import ArgonAvatar from "components/ArgonAvatar";
import borders from "assets/theme/base/borders";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCategoriesListApi } from "Actions/categoryAction";
import theme from "assets/theme";
import DefaultNavbarDropdown from "./DefaultNavbarDropdown";

function UserCategoryNav({ routes, brand, transparent, light, action }) {
  const location = useLocation();
  const { palette, functions, typography } = theme;
  const { white, blue } = palette;

  const [controller] = useArgonController();
  const dispatch = useDispatch();
  const { darkMode } = controller;
  const { borderRadius } = borders;
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const categoryList = useSelector(
    (state) => state.categoryState.categoriesList || []
  );
  const activeRootStyle = {
    backgroundColor: white.main,
    color: blue.five,
  };
  useEffect(() => {
    dispatch(getCategoriesListApi());
  }, [dispatch]);

  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  useEffect(() => {
    // A function that sets the display state for the UserNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileNavbar(false);
      } else {
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <>
      <ArgonBox
        py={2}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 3 }}
        my={0}
        mx={0}
        width="100%"
        shadow={transparent}
        position="relative"
        left={0}
        zIndex={99}
        sx={({ palette: { transparent: background, blue } }) => ({
          backgroundColor: darkMode ? background.light : blue.five,
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <Container>
          <ArgonBox display="flex" alignItems="center" gap={2}>
            {categoryList?.length ? (
              <>
                {categoryList.map((item) => (
                  <>
                  {item?.listingCount > 0  ? (
                    <ArgonBox>
                    <ArgonTypography
                      component={Link}
                      to={`/home/${item?.name.toLowerCase().trim()}/${
                        item?._id
                      }`}
                      variant="jpBodyRegular"
                      sx={({ palette: { white, blue, dark, yellow } }) => ({
                        padding: "10px 20px",
                        borderRadius: borderRadius.md,
                        color: darkMode ? white.main : white.main,

                        ":hover": {
                          backgroundColor: white.main,
                          color: blue.five,
                        },
                        ...(location.pathname.includes(
                          item?.name.toLowerCase().trim()
                        ) && activeRootStyle),
                      })}
                    >
                      {item?.name}
                    </ArgonTypography>
                  </ArgonBox>
                  ):(
                    <></>
                  )}
                  
                  </>
                ))}
                <></>
              </>
            ) : (
              <></>
            )}
            <DefaultNavbarDropdown />
          </ArgonBox>
        </Container>
      </ArgonBox>
    </>
  );
}

// Declaring default props for UserCategoryNav
UserCategoryNav.defaultProps = {
  brand: "Joyful",
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the UserCategoryNav
UserCategoryNav.propTypes = {
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["contained", "outlined", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
      "white",
    ]),
    label: PropTypes.string.isRequired,
  }),
};

export default UserCategoryNav;
