import React, { useEffect, useState } from "react";
import SearchCard from "../components/SearchCard";
import SearchFilter from "../components/SearchFilter";
import { Container, Grid } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import api2 from "api2";
import axios from "axios";
import ArgonPagination from "components/ArgonPagination";
import Icon from "@mui/material/Icon";


const SearchPage = () => {
  const { word } = useParams()
  const navigator = useNavigate()
  const [searchValue, setSearchValue] = useState(word)
  const [selectedValue, setSelectedValue] = useState()
  const token = useSelector((state) => state?.authState?.user?.token);
  const [categoryList, setCategoryList] = useState()
  const [filterCategory, setFilterCategory] = useState([])
  const [pageIndex, setPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [filterRating,setFilterRating] = useState([])
  const [value, setValue] = useState([0, 0]);
  const [skip, setSkip] = useState(0)
  const categories = useSelector((state) => state.categoryState.categoriesList);
  const fetchListing = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await api2.get(`listings?search=${searchValue ? searchValue : ""}&categoryIds=${encodeURIComponent(JSON.stringify(filterCategory))}&skip=${skip}&age=${value ? encodeURIComponent(JSON.stringify(value)) :""}&rating=${encodeURIComponent(JSON.stringify(filterRating))}`, config).then((res) => {
        if (res) {
          setTotalCount(res?.data?.data?.listingCount)
          setSelectedValue(res?.data?.data?.listing);
        }
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  }
  useEffect(() => {
    setCategoryList(categories || []);
  }, [categories]);
  useEffect(() => {
    fetchListing();
  }, [filterCategory,skip,value,filterRating]);
  useEffect(() => {
    if (searchValue) {
      navigator(`/search/${searchValue}`)
    }
  }, [word])
  const addOneAndMakeInteger = (number) => {
    if (!Number.isInteger(number)) {
      number += 1;
    }
    return Math.floor(number);
  }
  return (
    <Container>
      <Grid container spacing={4} my={3}>
        <Grid item lg={3} md={4} sm={12}>
          <SearchFilter
           categoryList={categoryList}
            selectedValue={selectedValue} 
            setFilterCategory={setFilterCategory}
             filterCategory={filterCategory} 
             setValue={setValue}
             value={value}
             filterRating={filterRating}
             setFilterRating = {setFilterRating}
             />
        </Grid>
        <Grid item lg={9} md={8} sm={12}>
          <ArgonBox>
            <ArgonTypography variant="h4">
              Showing {totalCount} results {searchValue ? `for "${searchValue}"` : ""}
            </ArgonTypography>
          </ArgonBox>
          <SearchCard selectedValue={selectedValue} />
        </Grid>
      </Grid>
      <ArgonPagination
        variant={"gradient"}
        color={"info"}
      >

        <>

          {pageIndex !== 0 && (
            <>
              <ArgonPagination item onClick={() => {
                setPageIndex(parseInt(pageIndex - 1))
                setSkip(parseInt(pageIndex - 1) * 8)
              }}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </ArgonPagination>
            </>
          )}
          {console.log('totalCount', totalCount)}
          {[...Array(addOneAndMakeInteger(totalCount / 8))].map((x, index) => (


            <ArgonPagination
              item
              // key={1}
              onClick={() => {
                setPageIndex(parseInt(index))
                setSkip(index * 8)
              }}
              active={pageIndex === index}
            >
              {index + 1}
            </ArgonPagination>

          ))}
        </>
        {parseInt(totalCount / 8) !== pageIndex && (
          <ArgonPagination item onClick={() => {
            setPageIndex(parseInt(pageIndex + 1))
            setSkip(parseInt(pageIndex + 1) * 8)
          }}>
            <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
          </ArgonPagination>
        )}

      </ArgonPagination>
    </Container>
  );
};

export default SearchPage;
