/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonTypography from "components/ArgonTypography";
import ArgonBox from "components/ArgonBox";
import { Icon, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import ArgonButton from "components/ArgonButton";

function StatusActionCell({
  item,
  setSelectedId,
  setSelectedStatus,
  setIsConfirmationOpen,
  handleEdit,
  handleDelete,
}) {
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem
        onClick={(e) => {
          handleEdit(item?._id);
          handleCloseMenu();
        }}
      >
        Edit
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          handleCloseMenu();
          handleDelete(item?._id);
        }}
      >
        Delete
      </MenuItem>
      <MenuItem
        onClick={(e) => {
          setSelectedId(item?._id);
          setSelectedStatus('approved');
          handleCloseMenu();
          setIsConfirmationOpen(true);
        }}
      >
        Approved
      </MenuItem>

      <MenuItem
        onClick={(e) => {
          setSelectedStatus('reject');
          setSelectedId(item?._id);
          handleCloseMenu();
          setIsConfirmationOpen(true);
        }}
      >
        Reject
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <ArgonBox ml="auto" color="secondary" pr={3} lineHeight={0}>
        <ArgonButton
          iconOnly
          sx={({ palette: { blue } }) => ({
            background: blue.one,
            ':hover': {
              background: blue.one,
            },
            ':focus:not(:hover)': {
              background: blue.one,
            },
          })}
          onClick={handleOpenMenu}
        >
          <Icon>menu_icon</Icon>
        </ArgonButton>
      </ArgonBox>

      {renderMenu()}
    </>
  );
}


export default StatusActionCell;
