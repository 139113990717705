import API2 from "api2";
import { errorHandling } from "components/Common/errorhandling";

const config = (accessToken) => ({
  headers: { Authorization: `Bearer ${accessToken}` },
});

export const addListingApi = (payload) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token;
    return new Promise(function (resolve, reject) {
      API2.post(`listings/add`, payload, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({ type: "ADD_LISTING_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "ADD_LISTING_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response);
        });
    });
  };
};
export const addArticleApi = (payload) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token;
    return new Promise(function (resolve, reject) {
      API2.post(`article/add`, payload, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({ type: "ADD_ARTICLE_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "ADD_ARTICLE_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response);
        });
    });
  };
};
export const getArticleApi = (skip, limit, search, categoryId, status) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token;
    let query = "";
    if (search) {
      query += `${query ? `&` : ""}search=${search}`;
    }
    if (categoryId) {
      query += `${query ? `&` : ""}categoryId=${categoryId}`;
    }
    if (status) {
      query += `${query ? `&` : ""}status=${status}`;
    }
    if (skip) {
      query += `${query ? `&` : ""}skip=${skip}`;
    }
    if (limit) {
      query += `${query ? `&` : ""}limit=${limit}`;
    }

    return new Promise(function (resolve, reject) {
      API2.get(`article?${query}`, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({ type: "GET_ARTICLE_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "GET_ARTICLE_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response);
        });
    });
  };
};
export const getListingApi = (skip, limit, search, categoryId, status,choice) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token;
    let query = "";
    if (search) {
      query += `${query ? `&` : ""}search=${search}`;
    }
    if (categoryId) {
      query += `${query ? `&` : ""}categoryId=${categoryId}`;
    }
    if (status) {
      query += `${query ? `&` : ""}status=${status}`;
    }
    if (skip) {
      query += `${query ? `&` : ""}skip=${skip}`;
    }
    if (limit) {
      query += `${query ? `&` : ""}limit=${limit}`;
    }
    if (choice !== undefined) {
      query += `${query ? `&` : ""}choice=${choice}`;
    }

    return new Promise(function (resolve, reject) {
      API2.get(`listings?${query}`, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({ type: "GET_LISTING_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "GET_LISTING_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response);
        });
    });
  };
};
export const getFeaturedImgApi = (status) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token;
    let query = "";
    if (status) {
      query += `${query ? `&` : ""}status=${status}`;
    }
    return new Promise(function (resolve, reject) {
      API2.get(`listings?${query}`, config(token))
        .then((res) => {
          const { data } = res?.data;
          let filteredListing =
            data?.listing?.length &&
            data?.listing.filter((item) => item?.isFeatured);
          let bannerArray = [];
          bannerArray =
            filteredListing?.length &&
            filteredListing.map((item) => {
              return {
                url: item?.thumbnailImg,
                link: `/${item?.category?.name}/single-category/${item?._id}`,
                ...item,
              };
            });
          dispatch({
            type: "FETCH_BANNER_SLIDER",
            payload: "success",
            data: bannerArray,
          });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({
            type: "FETCH_BANNER_SLIDER",
            payload: "failed",
            data: [],
          });
          reject(errorMessage);
          errorHandling(err?.response);
        });
    });
  };
};
export const getMyListingApi = (search, author) => {
  return async (dispatch, getState) => {
    let query = "";
    if (search) {
      query += `${query ? `&` : ""}search=${search}`;
    }
    if (author) {
      query += `${query ? `&` : ""}author=${author}`;
    }
    const token = getState()?.authState?.user?.token;
    return new Promise(function (resolve, reject) {
      API2.get(`listings/view/mylisting?${query}`, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "GET_MY_LISTING_SUCCESS",
            payload: "success",
            data,
          });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "GET_MY_LISTING_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response);
        });
    });
  };
};
export const getMyArticleApi = (search) => {
  return async (dispatch, getState) => {
    let query = "";
    if (search) {
      query += `${query ? `&` : ""}search=${search}`;
    }
    const token = getState()?.authState?.user?.token;
    return new Promise(function (resolve, reject) {
      API2.get(`article/view/myArticle?${query}`, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "GET_MY_ARTICLE_SUCCESS",
            payload: "success",
            data,
          });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "GET_MY_ARTICLE_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response);
        });
    });
  };
};
export const updateListingApi = (payload, id) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token;
    return new Promise(function (resolve, reject) {
      API2.put(`listings/update/${id}`, payload, config(token))
        .then((res) => {
          const { data } = res;
          // dispatch({ type: "GET_LISTING_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          // dispatch({ type: "GET_LISTING_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response);
        });
    });
  };
};
export const updateArticleApi = (payload, id) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token;
    return new Promise(function (resolve, reject) {
      API2.put(`article/update/${id}`, payload, config(token))
        .then((res) => {
          const { data } = res;
          // dispatch({ type: "GET_LISTING_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          // dispatch({ type: "GET_LISTING_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response);
        });
    });
  };
};
export const getBookmarkList = (userId) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token;
    return new Promise(function (resolve, reject) {
      API2.get(`bookmarks/${userId}`, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "GET_BOOKMARKED_LISTING_SUCCESS",
            payload: "success",
            data,
          });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          // dispatch({ type: "GET_LISTING_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response);
        });
    });
  };
};

export const getPaymentList = () => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token;
    return new Promise(function (resolve, reject) {
      API2.get(`payment/getPayment`, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({
            type: "GET_PAYMENT_LIST_SUCCESS",
            payload: "success",
            data,
          });
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          dispatch({ type: "GET_PAYMENT_LIST_FAILED", payload: "failed", err });
          reject(errorMessage);
          errorHandling(err?.response);
        });
    });
  };
};
