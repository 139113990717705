import { Card, Container, Grid } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import DefaultPricingCard from "examples/Cards/DefaultPricingCard";
import React from "react";
import ReviewsIcon from "@mui/icons-material/Reviews";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import PeopleIcon from "@mui/icons-material/People";
import { useArgonController } from "context";

const Plans = () => {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  return (
    <Container>
      <ArgonBox my={4}>
        <ArgonBox
          mb={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          gap={2}
        >
          <ArgonTypography variant="h3">Become a member now </ArgonTypography>

          <ArgonTypography variant="jpBodyRegular" sx={{ maxWidth: 700 }}>
            The Joyful Parenting subscription program supports our work and
            gives you the tools you need to help your kids make the best choices
            when it comes to media and technology.
          </ArgonTypography>
        </ArgonBox>

        <Grid container mb={3} spacing={3}>
          <Grid item md={4}>
            <Card sx={{ p: 2 }}>
              <ArgonBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={4}
              >
                <ReviewsIcon
                  sx={({ palette: { white, blue } }) => ({
                    color: darkMode ? white.main : blue.five,
                    height: 24,
                    width: 24,
                  })}
                />
                <ArgonTypography variant="jpH3Regular">
                  Unlimited reviews
                </ArgonTypography>
              </ArgonBox>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card sx={{ p: 2 }}>
              <ArgonBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={4}
              >
                <AutoFixHighIcon
                  sx={({ palette: { white, blue } }) => ({
                    color: darkMode ? white.main : blue.five,
                    height: 24,
                    width: 24,
                  })}
                />
                <ArgonTypography variant="jpH3Regular">
                  Entertainment guides
                </ArgonTypography>
              </ArgonBox>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card sx={{ p: 2 }}>
              <ArgonBox
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={4}
              >
                <PeopleIcon
                  sx={({ palette: { white, blue } }) => ({
                    color: darkMode ? white.main : blue.five,
                    height: 24,
                    width: 24,
                  })}
                />
                <ArgonTypography variant="jpH3Regular">
                  Made for your family
                </ArgonTypography>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={4}>
            <DefaultPricingCard
              title="Monthly"
              price={{ currency: "$", value: 3 }}
              action={{
                route: "/signin",
                color: "info",
                name: "monthly",
                label: "Active",
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4}>
            <DefaultPricingCard
              title="yearly"
              price={{ currency: "$", value: 29 }}
              action={{
                route: "/signin",
                color: "info",
                name: "yearly",
                label: "Active",
              }}
            />
          </Grid>
        </Grid>
        <ArgonBox my={2}>
          <ArgonTypography variant="jpCaptionMedium"></ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    </Container>
  );
};

export default Plans;
