
import { combineReducers } from "redux";
import authReducer from "./authReducer";
import categoryReducer from "./categoryReducer";
import listingReducer from "./listingReducer";
import reviewReducer from "./reviewReducer";
import userReducer from "./userReducer";
import userCustomFieldsReducer from "./userCustomFieldsReducer";
export default combineReducers({
  authState: authReducer,
  categoryState: categoryReducer,
  listingState:listingReducer,
  reviewState: reviewReducer,
  userState:userReducer,
  userCustomFieldsState:userCustomFieldsReducer
  })
  
