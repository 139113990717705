import styled from "@emotion/styled";
import { useArgonController } from "context";
import React from "react";
import { ReactComponent as InboxIconSvg } from "assets/images/icons/Inbox.svg";

const InboxIcon = () => {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const InboxIconStyled = styled(InboxIconSvg)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.five,
    },
  }));
  return (
    <>
      <InboxIconStyled />
    </>
  );
};

export default InboxIcon;
