import React, { useEffect, useState } from "react";
import { Card, styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import { useArgonController } from "context";
import borders from "assets/theme/base/borders";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ArgonTypography from "components/ArgonTypography";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import AgeIcon from "assets/images/icons/AgeIcon.svg";
import { useSelector } from "react-redux";
import { getBookmarkList } from "Actions/listingAction";
import { useDispatch } from "react-redux";
import api2 from "api2";
import axios from "axios";

const FeaturedCard = ({ i }) => {
  const [controller] = useArgonController();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { darkMode } = controller;
  const { borderRadius } = borders;
  const [bookmarkArray, setBookmarkArray] = useState([]);
  const userData = useSelector((state) => state?.authState?.user?.user);
  const token = useSelector((state) => state?.authState?.user?.token);
  const BookmarkList = useSelector(
    (state) => state?.listingState?.bookmarkList?.listingIds
  );
  useEffect(() => {
    if (token) {
      let bookmarkListData = [];
      bookmarkListData = BookmarkList?.map((item) => item?._id);
      setBookmarkArray(bookmarkListData || []);
    }
  }, [BookmarkList]);

  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: theme.palette.yellow.five,
    },
  }));
  const EmptyStarIconStyed = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
  }));
  const handleClick = async (bookmark) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let result = await api2
        .post(`bookmarks/manage`, { listingIds: bookmark }, config)
        .then((res) => {
          if (
            token &&
            (userData?.role === "parent" || userData?.role === "teenager")
          ) {
            dispatch(getBookmarkList(userData?._id));
          }
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };

  return (
    <>
      <Card sx={{ p: 2, position: 'relative' }}>
        <ArgonBox
          component={Link}
          to={`/${i.category.name.toLocaleLowerCase().trim()}/single-category/${
            i?._id
          }`}
        >
          <ArgonBox
            component="img"
            src={i?.thumbnailImg}
            alt=""
            sx={{
              height: '320px',
              width: '100%',
              borderRadius: borderRadius.md,
              objectFit: 'cover',
              objectPosition: 'top',
            }}
          />
        </ArgonBox>
        {userData?.role === 'superadmin' ||
        userData?.role === 'editor' ||
        userData?.role === 'author' ? (
          <></>
        ) : (
          <>
            <ArgonBox
              sx={({ palette: { white, blue } }) => ({
                position: 'absolute',
                right: 20,
                top: 20,
                display: 'flex',
                alignItems: 'center',
                p: 1,
                borderRadius: '100px',
                zIndex: 1,
                background: white.main,
                cursor: 'pointer',

                ':hover': {
                  background: blue.one,
                },
              })}
            >
              {!bookmarkArray?.includes(i?._id) ? (
                <BookmarkBorderIcon
                  sx={({ palette: { blue } }) => ({
                    height: '24px',
                    width: '24px',
                    fill: blue.five,
                  })}
                  onClick={() => {
                    if (token) {
                      const bookmarkInfo = bookmarkArray;
                      bookmarkInfo.push(i?._id);
                      handleClick(bookmarkInfo);
                    } else {
                      navigator('/signin');
                    }
                  }}
                />
              ) : (
                <BookmarkIcon
                  sx={({ palette: { blue } }) => ({
                    height: '24px',
                    width: '24px',
                    fill: blue.five,
                  })}
                  onClick={() => {
                    if (token) {
                      const bookmarkInfo = bookmarkArray;
                      const findIndex = bookmarkInfo.findIndex(
                        (x) => x === i?._id
                      );
                      bookmarkInfo.splice(findIndex, 1);
                      handleClick(bookmarkInfo);
                    }
                  }}
                />
              )}
            </ArgonBox>
          </>
        )}

        <ArgonBox
          display="flex"
          flexDirection="column"
          gap={1.2}
          mt={3}
          component={Link}
          to={`/${i.category.name.toLocaleLowerCase().trim()}/single-category/${
            i?._id
          }`}
        >
          <ArgonTypography
            variant="h5"
            className="tr-w-1"
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {i?.name}
          </ArgonTypography>

          <ArgonBox display="flex" flexDirection="column" gap={1.2} mt={2}>
            <ArgonBox
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mx: '0 !important' }}
            >
              <ArgonBox display="flex" alignItems="center" gap={1}>
                <ArgonBox component="img" src={AgeIcon} alt="" />
                <ArgonTypography variant="JpBodyMedium">
                  {i?.age}+
                </ArgonTypography>
              </ArgonBox>

              <ArgonBox
                display="flex"
                alignItems="center"
                gap={1}
                sx={({ palette: { natural } }) => ({
                  padding: '2px 8px 2px 6px',
                  border: `1px solid ${natural.three}`,
                  borderRadius: borderRadius.md,
                })}
              >
                <FullStarFillIconStyled />
                <ArgonTypography variant="jpBodyMedium">
                  {i?.averageRating || 0}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </>
  );
};

export default FeaturedCard;
