/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonTypography from "components/ArgonTypography";
import ArgonBox from "components/ArgonBox";
import { Icon, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import ArgonButton from "components/ArgonButton";

function ActionCell({ handleClick, setSelectedValue, item }) {
  return (
    <>
      <ArgonBox ml="auto" color="secondary" lineHeight={0}>
        <ArgonButton
          sx={({ palette: { blue } }) => ({
            background: blue.one,
            ":hover": {
              background: blue.one,
            },
            ":focus:not(:hover)": {
              background: blue.one,
            },
          })}
          onClick={() => {
            handleClick();
            setSelectedValue(item);
          }}
        >
          {item.flag ? "Flag" : "Unflag"}
        </ArgonButton>
      </ArgonBox>
    </>
  );
}

// Typechecking props for the ActionCell
ActionCell.propTypes = {
  children: PropTypes.string.isRequired,
};

export default ActionCell;
