/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Argon Dashboard 2 PRO MUI base styles
import ArgonInput from "components/ArgonInput";
import { Card } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "./data/dataTableData";
import { useEffect, useState } from "react";
import AddNewUser from "./add-new-user";
import { getUserByRole } from "Actions/categoryAction";
import { useDispatch } from "react-redux";
import ArgonSnackbar from "components/ArgonSnackbar";
import { useSelector } from "react-redux";
import NameCell from "./components/NameCell";
import DefaultCell from "./components/DefaultCell";
import StatusCell from "./components/StatusCell";
import ActionCell from "./components/ActionCell";
import moment from "moment";
import api2 from "api2";
import axios from "axios";
import DeleteModal from "components/Common/DeleteModal";
const columns = [
  { Header: "name", accessor: "name" },
  { Header: "role", accessor: "role" },
  { Header: "status", accessor: "status" },
  { Header: "last login", accessor: "lastLogin" },
  { Header: "action", accessor: "action", align: "center" },
];
function Team() {
  const dispatch = useDispatch();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const authors = useSelector(
    (state) => state?.categoryState?.authorList?.data?.userList
  );
  const token = useSelector((state) => state?.authState?.user?.token);
  const [search, setSearch] = useState();

  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });
  useEffect(() => {
    dispatch(getUserByRole(['superadmin', 'editor', 'author'], search));
  }, [dispatch, search]);
  useEffect(() => {
    const rowsArray =
      authors?.length &&
      authors.map((item) => {
        return {
          name: (
            <NameCell
              image={item?.profilePicture}
              name={item?.userName}
              email={item?.email}
            />
          ),
          role: <DefaultCell sx>{item?.role}</DefaultCell>,
          status: <StatusCell status={item.isActive ? "Active" : "Deactive"} />,
          lastLogin: (
            <DefaultCell>
              {item?.lastLogin
                ? moment(item?.lastLogin).format("MMM Do, YYYY")
                : "--"}
            </DefaultCell>
          ),
          action: (
            <ActionCell
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              item={item}
            />
          ),
        };
      });
    setRows(rowsArray || []);
  }, [authors]);
  const handleEdit = (selectedItemData) => {
    setSelectedItem(selectedItemData);
    setIsOpenMenu(true);
  };
  const handleDelete = (selectedItemData) => {
    setIsOpen(true);
    setSelectedItem(selectedItemData);
  };
  const handleSuccess = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await api2
        .delete(`account/delete/${selectedItem?._id}`, config)
        .then((res) => {
          if (res)
            setAlert({
              status: "success",
              isOpen: true,
              alertMsg: "Member deleted successfully.",
              bgColor: "bggreen",
            });
          dispatch(getUserByRole(['superadmin', 'editor', 'author']));
          setIsOpen(false);
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <ArgonBox py={3}>
        <Card
          sx={{
            p: 2,
          }}
        >
          <ArgonBox
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            <ArgonBox>
              <ArgonInput
                placeholder="Search Something.."
                startAdornment={
                  <Icon fontSize="large" style={{ marginRight: '6px' }}>
                    search
                  </Icon>
                }
                onChange={(e) => setSearch(e.target.value)}
              />
            </ArgonBox>{' '}
            <ArgonButton
              color="info"
              size="large"
              sx={{ px: 3 }}
              onClick={() => {
                setIsOpenMenu(true);
              }}
            >
              Add New &nbsp;
              <Icon sx={{ fontWeight: 'bold' }}>add</Icon>
            </ArgonButton>
          </ArgonBox>
        </Card>
        <AddNewUser
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={setIsOpenMenu}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
        <Card sx={{ mt: 4 }}>
          <ArgonBox pt={1}>
            <DataTable
              table={{ columns, rows: rows }}
              entriesPerPage={false}
              //   showTotalEntries={false}
            />
          </ArgonBox>
        </Card>
      </ArgonBox>
      <DeleteModal
        deleteContent={'Are you sure want to delete this user ?'}
        isOpenMenu={isOpen}
        setIsOpenMenu={setIsOpen}
        handleSuccess={handleSuccess}
      />
    </DashboardLayout>
  );
}

export default Team;
