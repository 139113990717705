/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Link, useNavigate } from "react-router-dom";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";
import ArgonInput from "components/ArgonInput";
import { Card } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import { useArgonController } from "context";
import CategoriesCard from "examples/Cards/CategoriesCard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesListApi } from "Actions/categoryAction";
import { getArticleCategoriesListApi } from "Actions/categoryAction";
import api2 from "api2";
import axios from "axios";

function ArticleCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { size } = typography;
  const [controller] = useArgonController();
  const [search, setSearch] = useState();
  const { darkMode } = controller;
  const [category, setCategory] = useState();
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: ""
  })
  const token = useSelector((state) =>state?.authState?.user?.token)
  const articleCategoryList = useSelector(
    (state) => state.categoryState.articleCategoriesList || []
  );
  useEffect(() => {
    
    dispatch(getArticleCategoriesListApi(search));
    dispatch({
      type: "SINGLE_CATEGORIES_FAILED",
      payload: "success",
      data: {},
    });
  }, [dispatch, search]);
  const handleEdit=(_id)=>{
    navigate(`/edit-article-category/${_id}`)
  }
  const handleDelete =async(_id)=>{
    try{
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
       await api2.delete(
        `article-category/delete/${_id}`,
        config
      ).then((res)=>{
        if(res)
        setAlert({
          status: "success",
          isOpen: true,
          alertMsg: "Article Category deleted successfully.",
          bgColor: "bggreen"
        })
        dispatch(getArticleCategoriesListApi())
        setTimeout(() => {
          navigate("/article-category")
        }, 1000);
      });
      
    }catch(error){
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Card
          sx={{
            p: 2,
          }}
        >
          <ArgonBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <ArgonBox>
              <ArgonInput
                placeholder="Search Something.."
                onChange={(e) => setSearch(e.target.value)}
                startAdornment={
                  <Icon fontSize="large" style={{ marginRight: "6px" }}>
                    search
                  </Icon>
                }
              />
            </ArgonBox>{" "}
            <ArgonButton
              component={Link}
              to="/add-article-category"
              color="info"
              size="large"
              sx={{ px: 3 }}
            >
              Add New &nbsp;
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            </ArgonButton>
          </ArgonBox>
        </Card>

        <>
          <Grid container spacing={3} mb={3} my={4}>
            {articleCategoryList.length ? (
              <>
                {articleCategoryList.map((item) => (
                  <Grid item xs={12} md={6} lg={3}>
                    <CategoriesCard {...item} handleEdit={handleEdit} handleDelete={handleDelete} alert={alert} setAlert={setAlert} />
                  </Grid>
                ))}
              </>
            ) : (
              <></>
            )}
          </Grid>
        </>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default ArticleCategory;
