import API2 from "api2";
const config = (accessToken) => ({
    headers: { Authorization:`Bearer ${accessToken}` },
  });
export const getReview = (listingId,search,userId,status,role,limit,skip) => {
    return (dispatch, getState) => {
        let query = ""
        if(listingId){
            query += `${query ? `&`:''}listingId=${listingId}`
        }
        if(search){
            query += `${query ? `&`:''}search=${search}`
            
        }
        if(userId){
            query += `${query ? `&`:''}userId=${userId}`
        }
        if(status){
            query +=  `${query ? `&`:''}status=${status}`
        }
        if(role){
            query +=  `${query ? `&`:''}role=${role}`
        }
        if(limit){
            query +=  `${query ? `&`:''}limit=${limit}`
        }
        if(skip){
            query +=  `${query ? `&`:''}skip=${skip}`
        }
        const token = getState()?.authState?.user?.token
        API2.get(`param-ratings?${query}`,config(token))
            .then((res) => {
                dispatch({
                    type: "ADD_REVIEW_SUCCESS",
                    payload: res?.data,
                    status:"success",
                    message:"review get successfully"
                });
            })
            .catch((err) => {
                dispatch({
                    type: "ADD_REVIEW_FAILED",
                  payload: {},
                  message: err.response.data.data,
                });
              });
    }
}