/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "../src/assets/css/custom.css";

// Soft UI Context Provider
import { PersistGate } from "redux-persist/integration/react";
import { persistor, persistorStore } from "persistorStore";
import { Provider } from "react-redux";
import { ArgonControllerProvider } from "context";

// react-perfect-scrollbar component
import PerfectScrollbar from "react-perfect-scrollbar";

// react-perfect-scrollbar styles
import "react-perfect-scrollbar/dist/css/styles.css";
import store from "store";

const container = document.getElementById("root");
const root = createRoot(container);
// import { Provider } from "react-redux";
root.render(
  <Provider store={store}>
    <Provider store={persistorStore}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ArgonControllerProvider>
            <PerfectScrollbar>
              <App />
            </PerfectScrollbar>
          </ArgonControllerProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </Provider>
);
