import React, { useEffect, useState } from "react";
import borders from "assets/theme/base/borders";
import ArgonBox from "components/ArgonBox";
import { useArgonController } from "context";
import ArgonTypography from "components/ArgonTypography";
import { Link, useNavigate } from "react-router-dom";
import { Grid, styled } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import { formateText } from "components/Common/formateText";
import { useSelector } from "react-redux";
import api2 from "api2";
import { useDispatch } from "react-redux";
import axios from "axios";
import ArgonPagination from "components/ArgonPagination";
import Icon from "@mui/material/Icon";

const ArticleCard = ({ selectedValue }) => {
  const dispatch = useDispatch();
  const [controller] = useArgonController();
  const navigator = useNavigate();
  const token = useSelector((state) => state?.authState?.user?.token);
  const userData = useSelector((state) => state?.authState?.user?.user);
  const [pageIndex, setPageIndex] = useState(0);

  const BookmarkList = useSelector(
    (state) => state?.listingState?.bookmarkList?.listingIds
  );
  useEffect(() => {
    if (token) {
      let bookmarkListData = [];
      bookmarkListData = BookmarkList?.map((item) => item?._id);
      setBookmarkArray(bookmarkListData || []);
    }
  }, [BookmarkList]);
  const { darkMode } = controller;
  const { borderRadius } = borders;
  const [bookmarkArray, setBookmarkArray] = useState([]);
  const ReadMore = ({ children }) => {
    const text = children;
    const [controller] = useArgonController();
    const { darkMode } = controller;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <ArgonTypography variant="jpBodyRegular">
        {isReadMore ? text?.slice(0, 150) : text}
        <ArgonBox onClick={toggleReadMore} my={1}>
          <ArgonTypography
            variant="jpBodyMedium"
            sx={({ palette: { blue, white } }) => ({
              color: darkMode ? white.main : blue.five,
              cursor: "pointer",
            })}
          >
            {isReadMore ? "Read More" : " Show Less"}
          </ArgonTypography>
        </ArgonBox>
      </ArgonTypography>
    );
  };

  return (
    <>
      <ArgonBox>
        <Grid
          container
          spacing={3}
          sx={{
            pb: 2,
            my: 2,
          }}
        >
          {selectedValue?.length ? (
            <>
              {selectedValue?.map((item) => (
                <Grid item md={6}>
                  <ArgonBox
                    sx={({ palette: { blue, natural }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 3,
                      my: 1,
                      py: 1,
                      width: "100%",
                      height: "100%",
                      borderBottom: darkMode
                        ? `1px solid ${blue.seven}`
                        : `1px solid ${natural.three}`,

                      [breakpoints.down("sm")]: {
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    })}
                  >
                    <ArgonBox>
                      <ArgonBox
                        component="img"
                        src={item?.thumbnailImg}
                        // src={item?.listingId?.thumbnailImg}
                        alt=""
                        sx={({ breakpoints }) => ({
                          width: "150px",
                          height: "150px",
                          borderRadius: borderRadius.md,
                          objectFit: "cover",
                          objectPosition: "top",

                          [breakpoints.down("md")]: {
                            alignItems: "center",
                          },
                        })}
                      />
                    </ArgonBox>

                    <ArgonBox display="flex" flexDirection="column" gap={1}>
                      <ArgonTypography
                        variant="jpH3Bold"
                        sx={({ palette: { blue, white } }) => ({
                          color: darkMode ? white.main : blue.seven,
                        })}
                        component={Link}
                        to={`/view-article/${item?._id}`}
                      >
                        {item?.title}
                      </ArgonTypography>

                      <ArgonTypography variant="jpCaptionRegular">
                        May 15, 2023
                      </ArgonTypography>

                      <ArgonTypography variant="jpBodyRegular">
                        {formateText(item?.shortDescription)?.length > 150 ? (
                          <ReadMore>
                            {formateText(item?.shortDescription)}
                          </ReadMore>
                        ) : (
                          <>
                            <ArgonTypography variant="jpBodyRegular">
                              {formateText(item?.shortDescription)}
                            </ArgonTypography>
                          </>
                        )}
                      </ArgonTypography>

                      <ArgonBox
                        display="flex"
                        alignItems="center"
                        gap={1}
                        flexWrap="wrap"
                        
                      >
                        {item?.categoryIds.length ? (
                          item?.categoryIds.map((i)=>(
                            <ArgonTypography
                            variant="jpBodyMedium"
                            sx={({ palette: { white, natural, blue } }) => ({
                              padding: "8px 16px",
                              backgroundColor: !darkMode
                                ? natural.three
                                : blue.five,
                              borderRadius: "8px",
                              textTransform: "capitalize",
                              cursor: "pointer",
  
                              ":hover": {
                                backgroundColor: !darkMode
                                  ? natural.four
                                  : blue.seven,
                              },
                            })}
                            onClick={() => {
                              navigator(`/all-article/${i?._id}`)
                            }}
                          >
                            {i?.name}
                          </ArgonTypography>
                          ))
                        ):(<></>)}
                     
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
              ))}
            </>
          ) : (
            <></>
          )}
        </Grid>
      </ArgonBox>
    </>
  );
};

export default ArticleCard;
