import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  styled,
} from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { ReactComponent as CloseIcon } from "assets/images/icons/Close.svg";
import { useArgonController } from "context";
import ImageUploadCard from "examples/Cards/ImageUploadCard";
import FormField from "components/Common/FormField";
import ArgonButton from "components/ArgonButton";
import FormSelect from "components/Common/FormSelect";
import ArgonSelect from "components/ArgonSelect";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import api2 from "api2";
import axios from "axios";
import { registerAction } from "Actions/loginAction";
import { updateUser } from "Actions/userAction";
import ArgonDropzone from "components/ArgonDropzone";
import ArgonSnackbar from "components/ArgonSnackbar";
import { addTeenAger } from "Actions/userAction";
import { getFamilyView } from "Actions/userAction";
import { useSelector } from "react-redux";

//
const rolesList = [
  { label: "Parent", value: "parent" },
  { label: "Teenager", value: "teenager" },
];
const statusList = [
  { label: "Active", value: "active" },
  { label: "Deactivate", value: "deactivate" },
];
const licenseList = [
  { label: "Free", value: "free" },
  { label: "Premium", value: "premium" },
];

const AddKid = ({ isOpenMenu, setIsOpenMenu, selectedItem }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [open, setOpen] = useState(isOpenMenu);
  const ref = useRef();
  const [userInfo, setUserInfo] = useState({});
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [showError, setShowError] = useState(false);
  const userData = useSelector((state) => state?.authState?.user?.user);
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });

  const ClosrIconSvg = styled(CloseIcon)(({ theme }) => ({
    height: 32,
    width: 32,
    cursor: "pointer",
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.eight,
    },
  }));
  useEffect(() => {
    setUserInfo({
      userName: selectedItem?.userName,
      email: selectedItem?.email,
      role: selectedItem?.role,
      profilePicture: selectedItem?.profilePicture,
      license: selectedItem?.license,
      password: selectedItem?.password,
      age: selectedItem?.age,
    });
  }, [selectedItem]);
  const handleClickOpen = () => {
    setIsOpenMenu(true);
  };

  const handleClose = () => {
    setIsOpenMenu(false);
    setUserInfo({});
    setShowError(false);
  };
  const handleUpload = async (file) => {
    try {
      let formData = new FormData();
      formData.append("file", file);
      let result = await api2.post(`imageUpload`, formData);
      setUserInfo({
        ...userInfo,
        profilePicture: result?.data?.data,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  const handleSubmit = () => {
    setShowError(true);
    if (userInfo?.userName && userInfo?.email && userInfo?.age) {
      const payload = {
        userName: userInfo?.userName,
        email: userInfo?.email,
        profilePicture: userInfo?.profilePicture,
        isActive: true,
        age: userInfo?.age,
        password: userInfo?.password,
        license: "",
      };
      if (!selectedItem) {
        dispatch(addTeenAger(payload));
        setAlert({
          ...alert,
          status: "success",
          isOpen: true,
          alertMsg: "Kid added successfully.",
          bgColor: "bggreen",
        });
      } else {
        dispatch(updateUser(payload, selectedItem?._id));
        setAlert({
          ...alert,
          status: "success",
          isOpen: true,
          alertMsg: "Kid updated successfully.",
          bgColor: "bggreen",
        });
      }
      setTimeout(() => {
        dispatch(getFamilyView(userData?._id));
      }, 1000);
      handleClose();
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };
  return (
    <>
      <Dialog
        open={isOpenMenu}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Card sx={{ p: 4, overflow: "unset" }}>
          <ArgonBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ArgonTypography variant="jpH3Bold">Add Kid</ArgonTypography>

            <ArgonBox onClick={handleClose}>
              <ClosrIconSvg />
            </ArgonBox>
          </ArgonBox>

          <ArgonBox sx={{ my: 4 }}>
            <Card
              sx={({ palette: { natural } }) => ({
                border: `1px solid ${natural.three}`,
                p: 4,
                overflow: "unset",
              })}
            >
              <Grid container spacing={3} sx={{ my: 0 }}>
                <ArgonBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Grid
                    item
                    onClick={() => {
                      ref?.current.childNodes[0].click();
                    }}
                    md={3}
                  >
                    <ImageUploadCard
                      url={userInfo?.profilePicture}
                      title={"Choose Image..."}
                    />
                    <ArgonBox
                      ref={ref}
                      sx={{ position: "relative", display: "none" }}
                    >
                      <ArgonDropzone
                        style={{
                          position: "absolute",
                          bottom: 0,
                          opacity: 0,
                          cursor: "pointer",
                        }}
                        options={{
                          onDrop: (acceptedFiles) =>
                            handleUpload(acceptedFiles[0]),
                          accept: { "image/*": [] },
                        }}
                      />
                    </ArgonBox>
                  </Grid>
                </ArgonBox>

                <Grid item lg={6} sm={12} xs={12}>
                  <ArgonBox>
                    <FormField
                      type="text"
                      placeholder="Kid Name"
                      size="large"
                      label="Kid Name"
                      error={showError && !userInfo.userName}
                      name="userName"
                      value={userInfo.userName}
                      onChange={handleChange}
                    />
                  </ArgonBox>
                </Grid>

                <Grid item lg={6} sm={12} xs={12}>
                  <ArgonBox>
                    <FormField
                      type="number"
                      placeholder="Kid Age"
                      size="large"
                      label="Age"
                      value={parseInt(userInfo.age)}
                      error={showError && !userInfo.age}
                      name="age"
                      onChange={handleChange}
                    />
                  </ArgonBox>
                </Grid>

                <Grid item lg={6} sm={12} xs={12}>
                  <ArgonBox>
                    <FormField
                      type="text"
                      placeholder="User Email"
                      size="large"
                      label="Email"
                      name="email"
                      value={userInfo.email}
                      error={showError && !userInfo?.email}
                      onChange={handleChange}
                    />
                  </ArgonBox>
                </Grid>

                <Grid item lg={6} sm={12} xs={12}>
                  <ArgonBox mb={2}>
                    <FormField
                      type="password"
                      placeholder="Please Enter Password"
                      size="large"
                      label="password"
                      name="password"
                      // value={userInfo.passwi}
                      // error={showError && !userInfo.password}
                      onChange={handleChange}
                    />
                  </ArgonBox>
                </Grid>
              </Grid>
            </Card>
          </ArgonBox>
          <DialogActions sx={{ p: 0 }}>
            <ArgonButton
              color="info"
              size="large"
              sx={{ px: 3 }}
              onClick={handleSubmit}
            >
              Save
            </ArgonButton>
          </DialogActions>
        </Card>
      </Dialog>
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </>
  );
};

export default AddKid;
