import React from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArgonBox from "components/ArgonBox";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ArgonTypography from "components/ArgonTypography";
import { Container } from "@mui/material";
import { useArgonController } from "context";
import { formateText } from "components/Common/formateText";
import AgeIcon from "assets/images/icons/AgeIcon.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArgonButton from "components/ArgonButton";

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <ArgonBox
      className={className}
      onClick={onClick}
      sx={({ breakpoints }) => ({
        display: "block",
        position: "absolute",
        left: 45,
        zIndex: 100,

        [breakpoints.down("md")]: {
          left: 10,
        },
      })}
    >
      1
    </ArgonBox>
  );
}

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <ArgonBox
      className={className}
      onClick={onClick}
      sx={({ breakpoints }) => ({
        display: "block",
        position: "absolute",
        right: 65,
        zIndex: 100,
        [breakpoints.down("md")]: {
          right: 10,
        },
      })}
    />
  );
}

const HeroSection = () => {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const settings = {
    autoplay: true,
    autoplaySpeed: 2500,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const bannerList = useSelector((state) => state?.categoryState?.bannersList);
  return (
    <>
      {bannerList?.length ? (
        <Slider {...settings}>
          {bannerList.map((item) => {
            return (
              <ArgonBox>
                {/* <ArgonBox
                  component="img"
                  src={item?.url}
                  alt=""
                  sx={({ breakpoints }) => ({
                    width: "100%",
                    height: "400px",
                    objectFit: "cover",
                    [breakpoints.down("md")]: {
                      height: "250px",
                    },
                  })}
                /> */}

                <ArgonBox>
                  <ArgonBox
                    sx={({ breakpoints }) => ({
                      width: "100%",
                      height: "70vh",
                      backgroundImage: `linear-gradient(90deg,rgba(0,0,0,1),rgba(0,0,0, 0)), url(${item?.url})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      position: "relative",
                      backgroundPosition: "top top",
                      backgroundAttachment: "fixed",
                      [breakpoints.down("md")]: {
                        height: "100%",
                      },
                    })}
                  >
                    <ArgonBox
                      sx={({ breakpoints }) => ({
                        padding: "150px",
                        paddingTop: "200px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 1,
                        width: "50%",

                        [breakpoints.down("md")]: {
                          padding: "50px",
                          paddingTop: "100px",
                          width: "100%",
                        },
                      })}
                    >
                      <ArgonTypography
                        variant="jpCaptionRegular"
                        sx={({ palette: { white } }) => ({
                          color: white.main,
                        })}
                      >
                        {item?.category?.name}
                      </ArgonTypography>

                      <ArgonBox display="flex" alignItems="baseline" gap={2}>
                        <ArgonTypography
                          variant="h2"
                          sx={({ palette: { white } }) => ({
                            color: white.main,
                            textTransform: "capitalize",
                          })}
                        >
                          {item?.name}
                        </ArgonTypography>

                        <ArgonBox display="flex" alignItems="center" gap={1}>
                          <ArgonBox
                            component="img"
                            src={AgeIcon}
                            alt="age"
                            sx={{
                              height: 18,
                              width: 18,
                            }}
                          />
                          <ArgonTypography
                            variant="h6"
                            sx={({ palette: { white } }) => ({
                              color: white.main,
                            })}
                          >
                            {item?.age}+
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>

                      {item?.shortDescription && (
                        <ArgonTypography
                          variant="jpCaptionRegular "
                          className="tr-2"
                          sx={({ palette: { white } }) => ({
                            color: white.main,
                          })}
                        >
                          {formateText(item?.shortDescription)}
                        </ArgonTypography>
                      )}

                      <ArgonBox display="flex" gap={1}>
                        {item?.genreList?.length ? (
                          <>
                            {item?.genreList.map((item) => (
                              <ArgonTypography
                                variant="jpBodyRegular"
                                sx={({ palette: { white } }) => ({
                                  textTransform: "capitalize",
                                  color: white.main,

                                  "&:not(:last-child):after": {
                                    content: `","`,
                                  },
                                })}
                              >
                                {item}
                              </ArgonTypography>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </ArgonBox>

                      <ArgonBox display="flex" alignItems="center" gap={1}>
                        <ArgonButton
                          component={Link}
                          variant="jpCaptionRegular"
                          to={item?.link}
                          endIcon={
                            <ArrowRightAltIcon
                              sx={{
                                fontSize: "large",
                                color: "#fff",
                              }}
                            />
                          }
                          sx={({ palette: { white } }) => ({
                            color: white.main,
                            textDecoration: "underline",
                            padding: 0,
                          })}
                        >
                          View More
                        </ArgonButton>
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            );
          })}
        </Slider>
      ) : (
        <></>
      )}
    </>
  );
};

export default HeroSection;
