import React, { useEffect, useState } from "react";
import { Card, Checkbox, Icon, Menu, MenuItem, Slider } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useNavigate, useParams } from "react-router-dom";
import ArgonButton from "components/ArgonButton";
import { useArgonController } from "context";
import { getArticleCategoriesListApi } from "Actions/categoryAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const minDistance = 2;

const ArticleFilter = ({value,setValue}) => {
  const {id} = useParams()
  const dispatch = useDispatch()
  const navigator =  useNavigate()
  const [topic, setTopic] = useState(null);
  const [age, setAge] = useState(null);
  const [selectedCategory,setSelectedCategory] = useState()
  const articleCategories = useSelector(
    (state) => state.categoryState.articleCategoriesList || []
  );
  const [controller] = useArgonController();
  const { darkMode } = controller;

  useEffect(() => {
    dispatch(getArticleCategoriesListApi());
  }, []);
  useEffect(()=>{
    const selectedCategoryInfo = articleCategories?.find((item)=>item?._id === id)
    setSelectedCategory(selectedCategoryInfo)
  },[id])
  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };

  const openMenuTopic = (event) => setTopic(event.currentTarget);
  const openMenuAge = (event) => setAge(event.currentTarget);

  const closeMenu = () => {
    setTopic(null);
    setAge(null);
  };

  const renderMenuTopic = (
    <Menu
      anchorEl={topic}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(topic)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={()=>{
        navigator(`/all-article`)
        closeMenu()
        }} >All Topics</MenuItem>
      {articleCategories.length ? (
        <>
        {articleCategories.map((item)=>(
          <MenuItem 
          onClick={()=>{
            navigator(`/all-article/${item?._id}`)
            closeMenu()
          }}
          sx={{ textTransform:"capitalize" }}
          >{item?.name}</MenuItem>
        ))}
        </>
      ):(
        <></>
      )}
    </Menu>
  );

  const renderMenuAge = (
    <Menu
      anchorEl={age}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(age)}
      onClose={closeMenu}
      keepMounted
    >
      <ArgonBox width="180px" p={0.5} display="flex" flexDirection="column">
        <ArgonTypography variant="subtitle1">Age</ArgonTypography>

        <Slider
          value={value}
          sx={({ palette: { blue } }) => ({
            mt: 1.5,
            "& .MuiSlider-valueLabel": {
              fontSize: 16,
              fontWeight: "bold",
              bottom: 50,
              backgroundColor: "unset",
              color: blue.five,
              "&:before": {
                display: "none",
              },
              "& *": {
                background: "transparent",
                color: darkMode ? "#fff" : "#000",
              },
            },
          })}
          onChange={handleChange}
          valueLabelDisplay="auto"
          // getAriaValueText={valuetext}
          disableSwap
        />
      </ArgonBox>
    </Menu>
  );

  return (
    <>
      <ArgonBox>
        <ArgonTypography
          variant="h3"
          sx={{
            textAlign: "center",
          }}
        >
          From parenting to media to everything in between
        </ArgonTypography>

        <ArgonBox mt={3} display="flex" gap={2}>
          <ArgonButton
            variant="gradient"
            color="primary"
            onClick={openMenuTopic}
            sx={{ textTransform:"capitalize" }}
          >
           {selectedCategory ? selectedCategory?.name :'All Topics'}  &nbsp;
            <Icon>expand_more</Icon>
          </ArgonButton>
          {renderMenuTopic}

          <ArgonButton variant="gradient" color="primary" onClick={openMenuAge}>
            All Age &nbsp;
            <Icon>expand_more</Icon>
          </ArgonButton>
          {renderMenuAge}
        </ArgonBox>
      </ArgonBox>
    </>
  );
};

export default ArticleFilter;
