import { Navigate, Route, useNavigate, useRoutes } from "react-router-dom";
import Profile from "layouts/user/profile";
import ChangePassword from "layouts/user/profile/change-password";
import MyKids from "layouts/user/profile/my-kids";
import MyProfile from "layouts/user/profile/my-profile";
import MyReview from "layouts/user/profile/my-review";
import Payment from "layouts/user/profile/payment";
import MyBookMarks from "layouts/user/profile/my-bookmarks";

// ----------------------------------------------------------------------

export default function UserProfileRoutes() {
  return useRoutes([
    {
      path: "/",
      element: <Profile />,
      children: [
        { path: "/profile", element: <MyProfile /> },
        { path: "/change-password", element: <ChangePassword /> },
        { path: "/my-kids", element: <MyKids /> },
        { path: "/my-review", element: <MyReview /> },
        { path: "/payment", element: <Payment /> },
        { path: "/my-bookmarks", element: <MyBookMarks /> },
      ],
    },
  ]);
}
