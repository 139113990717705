import React, { useEffect, useState } from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import UserNavbar from "examples/Navbars/UserNavbar";
import ArgonBox from "components/ArgonBox";
import borders from "assets/theme/base/borders";
import Icon from "@mui/material/Icon";
import FlagIcon from "@mui/icons-material/Flag";

import { ReactComponent as DoubleCheckIcon } from "assets/images/icons/DoubleCheckIcon.svg";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import { ReactComponent as EmptyStarIcon } from "assets/images/icons/EmptyStar.svg";
import { ReactComponent as HalfStarIcon } from "assets/images/icons/HalfStar.svg";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import AgeIcon from "assets/images/icons/AgeIcon.svg";
import { useTheme } from "@mui/material/styles";

import {
  AppBar,
  Backdrop,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  styled,
} from "@mui/material";
import ArgonTypography from "components/ArgonTypography";
import { useArgonController } from "context";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import breakpoints from "assets/theme/base/breakpoints";
import api2 from "api2";
import axios from "axios";
import PropTypes from "prop-types";
import ArgonButton from "components/ArgonButton";
import { getReview } from "Actions/reviewAction";
import { useDispatch } from "react-redux";
import ArgonAvatar from "components/ArgonAvatar";
import ConfirmationModel from "components/Common/ConfirmationModel";
import FullAccess from "components/Common/FullAccess";
import CustomFields from "./custom-fields";
import ReviewCards from "./components/ReviewCards";
import Footer from "examples/Footer";
import ArgonPagination from "components/ArgonPagination";
import { BorderBottom } from "@mui/icons-material";
import { getBookmarkList } from "Actions/listingAction";
import { formateText } from "components/Common/formateText";
import PhotosAndVideos from "./components/PhotosAndVideos";
import boxShadows from "assets/theme/base/boxShadows";
import WhereToWatch from "./components/WhereToWatch";
import EditorsRecommend from "./components/EditorsRecommend";
import FlagModel from "./components/FlagModel";
const regex = /<(?:"[^"]*"['"]*|'[^']*'['"]|&nbsp;|[^'">])+>/g;
const ReadMore = ({ children }) => {
  const text = children;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <ArgonTypography variant="jpBodyRegular">
      {isReadMore ? text?.slice(0, 350) : text}
      <ArgonBox onClick={toggleReadMore} my={1}>
        <ArgonTypography
          variant="jpBodyMedium"
          sx={({ palette: { blue, white } }) => ({
            color: darkMode ? white.main : blue.five,
            cursor: "pointer",
          })}
        >
          {isReadMore ? "Read More" : " Show Less"}
        </ArgonTypography>
      </ArgonBox>
    </ArgonTypography>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <ArgonBox>{children}</ArgonBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabIndexProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SingleCategory = ({ transparent }) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { xxl } = boxShadows;
  const theme = useTheme();
  const [isOpenModel, setIsOpenModel] = useState(false);
  const { listingId, categoryName } = useParams();
  const { borderRadius } = borders;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [listingInfo, setListingInfo] = useState();
  const userData = useSelector((state) => state?.authState?.user);
  const [accessListing, setAccessListing] = useState([listingId]);
  const [isOpen, setIsOpen] = useState(true);
  const [role, setRole] = useState("parent");
  const [skip, setSkip] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [limit, setLimit] = useState(10);
  const token = useSelector((state) => state?.authState?.user?.token);
  const [bookmarkArray, setBookmarkArray] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedReviwed, setSelectedReviwed] = useState();
  const BookmarkList = useSelector(
    (state) => state?.listingState?.bookmarkList?.listingIds
  );

  const [internalReviews, setInternalReviews] = useState([]);
  const userRating = useSelector(
    (state) => state?.reviewState?.myReview?.payload?.data
  );
  useEffect(() => {
    dispatch(getReview(listingId, "", "", "approved", role, limit, skip));
  }, [dispatch, role, skip, limit]);
  useEffect(() => {
    if (token) {
      let bookmarkListData = [];
      bookmarkListData = BookmarkList?.map((item) => item?._id);
      setBookmarkArray(bookmarkListData || []);
    }
  }, [BookmarkList]);
  const fetchListing = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${userData?.token}` },
      };
      let result = await api2
        .get(`listings/${listingId}`, config)
        .then((res) => {
          setListingInfo({
            ...res?.data?.data,
          });
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  useEffect(() => {
    if (listingId) {
      fetchListing();
    }
    const listingArray = sessionStorage.getItem("listingId")
      ? JSON.parse(sessionStorage.getItem("listingId"))
      : [];
    if (!listingArray.length) {
      sessionStorage.setItem("listingId", JSON.stringify([listingId]));
      setAccessListing(listingId);
    } else if (listingArray.length < 3) {
      const existing = listingArray.find((item) => item === listingId);
      if (!existing) {
        listingArray.push(listingId);
        sessionStorage.setItem("listingId", JSON.stringify(listingArray));
        setAccessListing(listingArray);
      }
    } else {
      setAccessListing(listingArray);
    }
  }, []);

  const handleSuccess = () => {};

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [value, setValue] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  const handleClick = async (bookmark) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let result = await api2
        .post(`bookmarks/manage`, { listingIds: bookmark }, config)
        .then((res) => {
          if (
            token &&
            (userData?.role === "parent" || userData?.role === "teenager")
          ) {
            dispatch(getBookmarkList(userData?.user?._id));
          }
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };

  const StyledTab = styled(Tab)(({ theme }) => ({
    "&.Mui-selected": {
      color: "#fff !important",
      backgroundColor: theme.palette.blue.five,
    },
  }));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const DoubleCheckIconStyled = styled(DoubleCheckIcon)(({ theme }) => ({
    height: 24,
    width: 24,
  }));

  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: theme.palette.yellow.five,
    },
  }));
  const EmptyStarIconStyed = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
  }));
  const addOneAndMakeInteger = (number) => {
    if (!Number.isInteger(number)) {
      number += 1;
    }
    const data = Math.floor(number);
    return data;
  };
  const handleModel = () => {
    setIsOpenModel(!isOpenModel);
  };
  return (
    <>
      <PageLayout>
        <UserNavbar />

        <Container fluid sx={{ mb: 10, mt: 5 }}>
          {/* SingleCategoryHeader */}
          <ArgonBox
            sx={({ breakpoints }) => ({
              display: 'flex',
              gap: 5,
              alignItems: 'center',

              [breakpoints.down('md')]: {
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 3,
              },
            })}
          >
            <ArgonBox sx={{ position: 'relative' }}>
              <ArgonBox>
                <ArgonBox
                  component="img"
                  src={listingInfo?.thumbnailImg}
                  alt=""
                  sx={({ palette: { blue } }) => ({
                    width: '230px',
                    height: '340px',
                    borderRadius: borderRadius.md,
                    boxShadow: darkMode ? 'none' : xxl,
                    objectFit: 'cover',
                    objectPosition: 'top',
                  })}
                />
              </ArgonBox>
              <ArgonBox
                sx={({ palette: { white, blue } }) => ({
                  position: 'absolute',
                  right: 10,
                  top: 10,
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  borderRadius: '100px',
                  zIndex: 1,
                  background: white.main,
                  cursor: 'pointer',

                  ':hover': {
                    background: blue.one,
                  },
                })}
              >
                {!bookmarkArray?.includes(listingId) ? (
                  <BookmarkBorderIcon
                    sx={({ palette: { blue } }) => ({
                      height: '24px',
                      width: '24px',
                      fill: blue.five,
                    })}
                    onClick={() => {
                      if (token) {
                        const bookmarkInfo = bookmarkArray;
                        bookmarkInfo.push(listingId);
                        handleClick(bookmarkInfo);
                      } else {
                        navigator('/signin');
                      }
                    }}
                  />
                ) : (
                  <BookmarkIcon
                    sx={({ palette: { blue } }) => ({
                      height: '24px',
                      width: '24px',
                      fill: blue.five,
                    })}
                    onClick={() => {
                      if (token) {
                        const bookmarkInfo = bookmarkArray;
                        const findIndex = bookmarkInfo.findIndex(
                          (x) => x === listingId
                        );
                        bookmarkInfo.splice(findIndex, 1);
                        handleClick(bookmarkInfo);
                      }
                    }}
                  />
                )}
              </ArgonBox>
            </ArgonBox>

            <ArgonBox
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
              position="relative"
              gap={3}
              width="100%"
            >
              <ArgonBox
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
              >
                <ArgonTypography
                  sx={({ breakpoints }) => ({
                    ...theme.typography.h3,
                    color: darkMode
                      ? theme.palette.white.main
                      : theme.palette.natural.eight,

                    [breakpoints.down('md')]: {
                      ...theme.typography.jpH3Regular,
                      fontWeight: 'bold',
                    },
                  })}
                >
                  {' '}
                  Parents' Guide to{' '}
                </ArgonTypography>
                <ArgonTypography
                  // variant="h1"
                  sx={({ breakpoints }) => ({
                    ...theme.typography.h1,
                    color: darkMode
                      ? theme.palette.white.main
                      : theme.palette.natural.eight,

                    [breakpoints.down('md')]: {
                      ...theme.typography.jpH3Regular,
                      fontWeight: 'bold',
                    },
                  })}
                >
                  {listingInfo?.name}
                </ArgonTypography>
              </ArgonBox>

              <ArgonBox
                display="flex"
                alignItems="baseline"
                justifyContent="center"
                gap={2}
              >
                <ArgonBox
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    borderRadius: borderRadius.md,
                  }}
                >
                  <ArgonBox component="img" src={AgeIcon} alt="age" />
                  <ArgonTypography variant="jpH3Regular">
                    age {listingInfo?.age}+
                  </ArgonTypography>
                </ArgonBox>

                <ArgonBox display="flex" alignItems="stretch" gap={1}>
                  <ArgonBox sx={{ display: 'flex' }}>
                    {[...Array(5)].map((x, index) => (
                      <div key={index}>
                        {index < listingInfo?.averageRating ? (
                          <FullStarFillIconStyled />
                        ) : (
                          <EmptyStarIconStyed />
                        )}
                      </div>
                    ))}
                  </ArgonBox>

                  <ArgonTypography variant="jpBodyMedium">
                    ({listingInfo?.averageRating})
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>

              <ArgonBox display="flex">
                {listingInfo?.genreList?.length ? (
                  <>
                    {listingInfo?.genreList.map((item) => (
                      <ArgonTypography
                        variant="jpCaptionRegular"
                        sx={{
                          textTransform: 'capitalize',
                          ':after': {
                            content: '"|"',
                            padding: '0 0.5rem',
                          },

                          ':last-child:after': {
                            content: '""',
                          },
                        }}
                      >
                        {item}
                      </ArgonTypography>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>

          {/*  */}
          {(userData?.token && userData?.user?.license === 'premium') ||
          accessListing.includes(listingId) ||
          userData?.user.role === 'superadmin' ||
          userData?.user.role === 'editor' ||
          userData?.user.role === 'author' ? (
            <Grid container gap={3} my={4}>
              <Grid md={8} sm={12}>
                {/*  */}
                {listingInfo?.logDescription && (
                  <ArgonBox display="flex" flexDirection="column" gap={2}>
                    <Card
                      sx={{
                        p: 4,
                        gap: 2,
                      }}
                    >
                      <ArgonTypography variant="jpH3Bold">
                        Plot Summary
                      </ArgonTypography>
                      <ArgonTypography variant="jpBodyRegular">
                        {formateText(listingInfo?.logDescription)}
                      </ArgonTypography>
                    </Card>
                  </ArgonBox>
                )}

                {listingInfo?.whatTheStory && (
                  <ArgonBox
                    mt={5}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                  >
                    <Card
                      sx={{
                        p: 4,
                        gap: 2,
                      }}
                    >
                      <ArgonTypography variant="jpH3Bold">
                        What's it about ?
                      </ArgonTypography>
                      <ArgonTypography variant="jpBodyRegular">
                        {formateText(listingInfo?.whatTheStory)}
                      </ArgonTypography>
                    </Card>
                  </ArgonBox>
                )}

                {listingInfo?.isItAnyGood && (
                  <ArgonBox
                    mt={5}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                  >
                    <Card
                      sx={{
                        p: 4,
                        gap: 2,
                      }}
                    >
                      <ArgonTypography variant="jpH3Bold">
                        Is It Any Good ?
                      </ArgonTypography>
                      <ArgonTypography variant="jpBodyRegular">
                        {formateText(listingInfo?.isItAnyGood)}
                      </ArgonTypography>
                    </Card>
                  </ArgonBox>
                )}

                {listingInfo?.talkToYourKidsAbout && (
                  <ArgonBox
                    mt={5}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                  >
                    <Card
                      sx={{
                        p: 4,
                        gap: 2,
                      }}
                    >
                      <ArgonTypography variant="jpH3Bold">
                        Talk To Your Kids About
                      </ArgonTypography>
                      <ArgonTypography variant="jpBodyRegular">
                        {formateText(listingInfo?.talkToYourKidsAbout)}
                      </ArgonTypography>
                    </Card>
                  </ArgonBox>
                )}

                {/* ReviewCards */}
                <ArgonBox mt={5}>
                  <Grid container spacing={3}>
                    {listingInfo?.internalReviewFieldsList?.length &&
                      listingInfo?.internalReviewFieldsList.map((item) => (
                        <ReviewCards item={item} />
                      ))}
                  </Grid>
                </ArgonBox>

                {/* CustomFields  */}
                {listingInfo?.length ? (
                  <CustomFields listingInfo={listingInfo} />
                ) : (
                  <></>
                )}

                {/* PhotosAndVideos */}
                {listingInfo?.media?.length ? (
                  <ArgonBox mt={5}>
                    <PhotosAndVideos listingInfo={listingInfo} />
                  </ArgonBox>
                ) : (
                  <></>
                )}

                {/*  */}
                <Grid container>
                  <Grid xs={12}>
                    <Card sx={{ p: 4, my: 4 }}>
                      <>
                        <ArgonBox display="flex" justifyContent="space-between">
                          <ArgonTypography variant="jpH3Bold">
                            User Reviews
                          </ArgonTypography>{' '}
                        </ArgonBox>

                        <Grid item xs={12} my={2}>
                          <AppBar position="static">
                            <Tabs
                              orientation={tabsOrientation}
                              value={value}
                              onChange={handleChange}
                              sx={({ palette: { natural } }) => ({
                                backgroundColor: natural.two,
                                height: '48px',
                              })}
                            >
                              <StyledTab
                                label="Parent Reviews"
                                {...tabIndexProps(0)}
                                onClick={() => {
                                  setRole('parent');
                                  setPageIndex(0);
                                }}
                              />
                              <StyledTab
                                label="Kids Reviews"
                                {...tabIndexProps(1)}
                                onClick={() => {
                                  setRole('teenager');
                                  setPageIndex(0);
                                }}
                              />
                            </Tabs>
                          </AppBar>

                          {/*  */}
                          <TabPanel value={value} index={0}>
                            {userRating?.ratingData?.length ? (
                              <>
                                {userRating?.ratingData.map((item) => (
                                  <>
                                    {item?.userId?.role === 'parent' && (
                                      <>
                                        <ArgonBox
                                          sx={({ palette: { blue } }) => ({
                                            pb: 2,
                                            my: 6,
                                            borderBottom: darkMode
                                              ? `1px solid ${blue.seven}`
                                              : `1px solid ${blue.one}`,
                                          })}
                                        >
                                          <ArgonBox
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                          >
                                            <ArgonBox
                                              display="flex"
                                              alignItems="center"
                                              gap={1}
                                              mb={2}
                                            >
                                              <ArgonBox
                                                component="img"
                                                src={
                                                  item?.userId?.profilePicture
                                                }
                                                alt=""
                                                height="40px"
                                                width="40px"
                                                borderRadius="md"
                                                bgColor="light"
                                                sx={{ objectFit: 'cover' }}
                                              />
                                              <ArgonTypography
                                                variant="jpH3Bold"
                                                sx={({
                                                  palette: { blue, white },
                                                }) => ({
                                                  color: darkMode
                                                    ? white.main
                                                    : blue.seven,
                                                  textTransform: 'capitalize',
                                                })}
                                              >
                                                {item?.userId?.name ||
                                                  item?.userId?.userName}
                                              </ArgonTypography>
                                            </ArgonBox>

                                            <ArgonBox
                                              display="flex"
                                              alignItems="stretch"
                                              gap={1}
                                            >
                                              <ArgonBox
                                                sx={{ display: 'flex' }}
                                              >
                                                {[...Array(5)].map(
                                                  (x, index) => (
                                                    <div key={index}>
                                                      {index < item?.rating ? (
                                                        <FullStarFillIconStyled />
                                                      ) : (
                                                        <EmptyStarIconStyed />
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                              </ArgonBox>
                                              <ArgonTypography variant="jpBodyMedium">
                                                ({item?.rating})
                                              </ArgonTypography>
                                              <FlagIcon />
                                              <ArgonTypography
                                                variant="jpBodyRegular"
                                                sx={{
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                  setIsOpenModel(true);
                                                  setSelectedReviwed(item?._id);
                                                  setSelectedUser(
                                                    item?.userId?._id
                                                  );
                                                }}
                                              >
                                                Report
                                              </ArgonTypography>
                                            </ArgonBox>
                                          </ArgonBox>
                                          {formateText(item?.ratingText)
                                            ?.length > 350 ? (
                                            <ReadMore>
                                              {formateText(item?.ratingText)}
                                            </ReadMore>
                                          ) : (
                                            <ArgonTypography variant="jpBodyRegular">
                                              {formateText(item?.ratingText)}
                                            </ArgonTypography>
                                          )}
                                        </ArgonBox>
                                      </>
                                    )}
                                  </>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                            {userData?.user?.role === 'parent' && (
                              <ArgonBox
                                sx={{
                                  p: 3,
                                  my: 4,
                                }}
                              >
                                <ArgonBox
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                  gap={2}
                                >
                                  <ArgonTypography
                                    variant="jpBodyBold"
                                    textAlign="center"
                                  >
                                    Exciting news! We've added new review
                                    options to hear your feedback and improve
                                    your experience. Share your thoughts now!
                                  </ArgonTypography>
                                  <ArgonButton
                                    color="info"
                                    onClick={() => {
                                      navigation(
                                        `/${categoryName}/single-category/${listingId}/add-review`
                                      );
                                    }}
                                  >
                                    Add your rating
                                  </ArgonButton>
                                </ArgonBox>
                              </ArgonBox>
                            )}
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            {userRating?.ratingData?.length ? (
                              <>
                                {userRating?.ratingData.map((item) => (
                                  <>
                                    {item?.userId?.role === 'teenager' && (
                                      <>
                                        <ArgonBox
                                          sx={({ palette: { blue } }) => ({
                                            pb: 2,
                                            my: 6,
                                            borderBottom: darkMode
                                              ? `1px solid ${blue.seven}`
                                              : `1px solid ${blue.one}`,
                                          })}
                                        >
                                          <ArgonBox
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                          >
                                            <ArgonBox
                                              display="flex"
                                              alignItems="center"
                                              gap={1}
                                              mb={2}
                                            >
                                              <ArgonBox
                                                component="img"
                                                src={
                                                  item?.userId?.profilePicture
                                                }
                                                alt=""
                                                height="40px"
                                                width="40px"
                                                borderRadius="section"
                                                sx={{ objectFit: 'cover' }}
                                              />
                                              <ArgonTypography
                                                variant="jpH3Bold"
                                                sx={({
                                                  palette: { blue, white },
                                                }) => ({
                                                  color: darkMode
                                                    ? white.main
                                                    : blue.seven,
                                                })}
                                              >
                                                {item?.userId?.name ||
                                                  item?.userId?.userName}
                                              </ArgonTypography>
                                            </ArgonBox>

                                            <ArgonBox
                                              display="flex"
                                              alignItems="stretch"
                                              gap={1}
                                            >
                                              <ArgonBox
                                                sx={{ display: 'flex' }}
                                              >
                                                {[...Array(5)].map(
                                                  (x, index) => (
                                                    <div key={index}>
                                                      {index < item?.rating ? (
                                                        <FullStarFillIconStyled />
                                                      ) : (
                                                        <EmptyStarIconStyed />
                                                      )}
                                                    </div>
                                                  )
                                                )}
                                              </ArgonBox>

                                              <ArgonTypography variant="jpBodyMedium">
                                                ({item?.rating})
                                              </ArgonTypography>
                                            </ArgonBox>
                                          </ArgonBox>
                                          {formateText(item?.ratingText)
                                            ?.length > 350 ? (
                                            <ReadMore>
                                              {formateText(item?.ratingText)}
                                            </ReadMore>
                                          ) : (
                                            <ArgonTypography variant="jpBodyRegular">
                                              {' '}
                                              {formateText(item?.ratingText)}
                                            </ArgonTypography>
                                          )}
                                        </ArgonBox>
                                      </>
                                    )}
                                  </>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                            {userData?.user?.role === 'teenager' && (
                              <Card
                                sx={{
                                  p: 3,
                                  my: 4,
                                }}
                              >
                                <ArgonBox
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                  gap={2}
                                >
                                  <ArgonTypography variant="jpBodyBold">
                                    Exciting news! We've added new review
                                    options to hear your feedback and improve
                                    your experience. Share your thoughts now!
                                  </ArgonTypography>
                                  <ArgonButton
                                    color="info"
                                    onClick={() => {
                                      navigation(
                                        `/${categoryName}/single-category/${listingId}/add-review`
                                      );
                                    }}
                                  >
                                    Add your rating
                                  </ArgonButton>
                                </ArgonBox>
                              </Card>
                            )}

                            <ArgonBox
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                              }}
                              my={3}
                            ></ArgonBox>
                          </TabPanel>
                          {userRating?.totalReviewsCount > 10 && (
                            <ArgonPagination
                              variant={'gradient'}
                              color={'info'}
                            >
                              <>
                                {pageIndex !== 0 && (
                                  <>
                                    <ArgonPagination
                                      item
                                      onClick={() => {
                                        setPageIndex(parseInt(pageIndex - 1));
                                        setSkip(parseInt(pageIndex - 1) * 8);
                                      }}
                                    >
                                      <Icon sx={{ fontWeight: 'bold' }}>
                                        chevron_left
                                      </Icon>
                                    </ArgonPagination>
                                  </>
                                )}

                                {[
                                  ...Array(
                                    addOneAndMakeInteger(
                                      userRating?.totalReviewsCount / 10
                                    )
                                  ),
                                ].map((x, index) => (
                                  <ArgonPagination
                                    item
                                    // key={1}
                                    onClick={() => {
                                      setPageIndex(parseInt(index));
                                      setSkip(index * 10);
                                    }}
                                    active={pageIndex === index}
                                  >
                                    {index + 1}
                                  </ArgonPagination>
                                ))}
                              </>
                              {parseInt(userRating?.totalReviewsCount / 10) !==
                                pageIndex && (
                                <ArgonPagination
                                  item
                                  onClick={() => {
                                    setPageIndex(parseInt(pageIndex + 1));
                                    setSkip(parseInt(pageIndex + 1) * 10);
                                  }}
                                >
                                  <Icon sx={{ fontWeight: 'bold' }}>
                                    chevron_right
                                  </Icon>
                                </ArgonPagination>
                              )}
                            </ArgonPagination>
                          )}
                        </Grid>
                      </>
                    </Card>
                    {!userData && (
                      //  <TabPanel value={value} index={1}>
                      <Card
                        sx={{
                          p: 3,
                          my: 4,
                        }}
                      >
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          gap={2}
                        >
                          <ArgonTypography variant="jpBodyBold">
                            Exciting news! We've added new review options to
                            hear your feedback and improve your experience.
                            Share your thoughts now!
                          </ArgonTypography>
                          <ArgonButton
                            color="info"
                            onClick={() => {
                              navigation('/signin');
                            }}
                          >
                            Add your rating
                          </ArgonButton>
                        </ArgonBox>
                      </Card>
                      //  </TabPanel>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid md={3} sm={12}>
                <WhereToWatch listingInfo={listingInfo} />

                <Divider />

                <EditorsRecommend />
              </Grid>
            </Grid>
          ) : (
            <>
              <FullAccess />
            </>
          )}
        </Container>
        <FlagModel
          isOpenModel={isOpenModel}
          handleModel={handleModel}
          selectedUser={selectedUser}
          selectedReviewedId={selectedReviwed}
        />
      </PageLayout>
      <Footer />
    </>
  );
};

export default SingleCategory;
