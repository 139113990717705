/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Argon Dashboard 2 PRO MUI base styles
import { Card } from "@mui/material";
import { useArgonController } from "context";
import borders from "assets/theme/base/borders";

import ProfileCard from "examples/Cards/ProfileCard";
import SubscriptionDetailCard from "examples/Cards/SubscriptionDetailCard";
import ListingsViewCard from "examples/Cards/ListingsViewCard";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "./data/dataTableData";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMyListingApi } from "Actions/listingAction";
import { useSelector } from "react-redux";
import NameCell from "./components/NameCell";
import DefaultCell from "./components/DefaultCell";
import StatusCell from "./components/StatusCell";
import moment from "moment";
const columns = [
  { Header: "product", accessor: "product" },
  { Header: "date added ", accessor: "dateAdded" },
  { Header: "last updated", accessor: "lastUpdated" },
  { Header: "action", accessor: "action", align: "center" },
];
function UserProfile() {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { borderRadius } = borders;
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMyListingApi());
  }, []);
  const myListing = useSelector(
    (state) => state?.listingState?.myListingList?.data
  );
  const userData = useSelector((state) => state?.authState?.user?.user);
  useEffect(() => {
    const myListingArray =
      myListing?.length &&
      myListing.map((item) => {
        return {
          product: (
            <NameCell
              image={item?.thumbnailImg}
              listingName={item?.name}
              categoryName={item?.category?.name}
              year={item?.yearReleased}
            />
          ),
          dateAdded: (
            <DefaultCell>
              {moment(item?.createdAt).format("MMM Do, YYYY")}
            </DefaultCell>
          ),
          lastUpdated: (
            <DefaultCell>
              {moment(item?.updatedAt).format("MMM Do, YYYY")}
            </DefaultCell>
          ),
          action: (
            <StatusCell status={!item?.isDraft ? "Published" : "Drafted"} />
          ),
        };
      });
    setRows(myListingArray);
  }, [myListing]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <>
          <Grid container spacing={4} mb={3}>
            <Grid item xs={12} md={6}>
              <ProfileCard />
            </Grid>
            {userData?.role === "parent" ||
              (userData?.role === "teenager" && (
                <Grid item xs={12} md={6}>
                  <SubscriptionDetailCard />
                </Grid>
              ))}
          </Grid>
          {userData?.role === "parent" ||
            (userData?.role === "teenager" && (
              <ArgonBox py={3}>
                <ArgonTypography variant="jpH3Bold">
                  Listings Viewed
                </ArgonTypography>
                <Grid container my={4} spacing={3}>
                  <Grid item md={3} xs={12}>
                    <ListingsViewCard />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <ListingsViewCard />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <ListingsViewCard />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <ListingsViewCard />
                  </Grid>
                </Grid>
              </ArgonBox>
            ))}

          <ArgonBox py={3}>
            <ArgonTypography variant="jpH3Bold">Listings Added</ArgonTypography>
            <ArgonBox my={4}>
              <DataTable
                table={{columns,rows:rows || []}}
                entriesPerPage={false}
                //   showTotalEntries={false}
              />
            </ArgonBox>
          </ArgonBox>
        </>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default UserProfile;
