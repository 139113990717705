
export default (
    state = { },
    action
  ) => {
    console.log('action', action)
    switch (action.type) {  
      case "ADD_CUSTOM_FIELDS_SUCCESS":
        return {
          ...state,
          userCustomFields: action,
        };
  
      case "ADD_CUSTOM_FIELDS_FILED":
        return {
          ...state,
          userCustomFields: [],

        }
      case "GET_CUSTOM_FIELDS_SUCCESS":
        return {
          ...state,
          userCustomFieldsList: action,
        };
  
      case "GET_CUSTOM_FIELDS_FILED":
        return {
          ...state,
          userCustomFieldsList: [],

        }
        default:
            return state;
    }
    }

