import React, { useEffect } from "react";
import { useState } from "react";
import { Card, Icon, Menu, MenuItem, styled } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import { ReactComponent as EmptyStarIcon } from "assets/images/icons/EmptyStar.svg";
import { ReactComponent as HalfStarIcon } from "assets/images/icons/HalfStar.svg";
import { useArgonController } from "context";
import borders from "assets/theme/base/borders";
import AgeIcon from "assets/images/icons/AgeIcon.svg";
import ArgonButton from "components/ArgonButton";
import { useSelector } from "react-redux";
import { getReview } from "Actions/reviewAction";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "components/Common/DeleteModal";
import api2 from "api2";
import ArgonSnackbar from "components/ArgonSnackbar";
import axios from "axios";
import ArgonPagination from "components/ArgonPagination";
import { formateText } from "components/Common/formateText";

const ReadMore = ({ children }) => {
  const text = children;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [isReadMore, setIsReadMore] = useState(true);
  const [selectedReviewId, setSelectedReviewId] = useState();
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <ArgonTypography variant="jpBodyRegular">
      {isReadMore ? text?.slice(0, 350) : text}
      <ArgonBox onClick={toggleReadMore} my={1}>
        <ArgonTypography
          variant="jpBodyMedium"
          sx={({ palette: { blue, white } }) => ({
            color: darkMode ? white.main : blue.five,
            cursor: "pointer",
          })}
        >
          {isReadMore ? "Read More" : " Show Less"}
        </ArgonTypography>
      </ArgonBox>
    </ArgonTypography>
  );
};

const MyReview = () => {
  const navigator = useNavigate();
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { borderRadius } = borders;
  const dispatch = useDispatch();
  const regex = /<(?:"[^"]*"['"]*|'[^']*'['"]|&nbsp;|[^'">])+>/g;

  const [openMenu, setOpenMenu] = useState(null);
  const [selectedItem, setSelectedItem] = useState();
  const userData = useSelector((state) => state?.authState?.user?.user);
  const userRating = useSelector(
    (state) => state?.reviewState?.myReview?.payload?.data
  );
  const token = useSelector((state) => state?.authState?.user?.token);
  const [deleteId, setDeleteId] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  useEffect(() => {
    dispatch(getReview("", "", userData?._id, "", "", limit, skip));
  }, [dispatch, skip, limit]);
  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: theme.palette.yellow.five,
    },
  }));
  const EmptyStarIconStyed = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
  }));

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const renderMenu = (item) => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          navigator(
            `/${item?.listingId?.category?.name
              .toLowerCase()
              ?.trim()}/single-category/${item?.listingId?._id}/edit-review/${
              item?._id
            }`
          );
          // handleEdit(item);
        }}
      >
        Edit
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          setDeleteId(item?._id);
          setDeleteOpen(true);
          // handleDelete(item);
        }}
      >
        Delete
      </MenuItem>
      <MenuItem onClick={handleCloseMenu}>{}</MenuItem>
    </Menu>
  );

  const handleSuccess = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await api2
        .delete(`param-ratings/delete/${deleteId}`, config)
        .then((res) => {
          if (res)
            setAlert({
              status: "success",
              isOpen: true,
              alertMsg: "Review deleted successfully.",
              bgColor: "bggreen",
            });
          dispatch(getReview("", "", userData?._id));
          setDeleteOpen(false);
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  const addOneAndMakeInteger = (number) => {
    if (!Number.isInteger(number)) {
      number += 1;
    }
    const data = Math.floor(number);
    return data;
  };
  return (
    <>
      <Card sx={{ p: 3 }}>
        <ArgonTypography variant="h5">My Reviews </ArgonTypography>
        {userRating?.ratingData?.length ? (
          <>
            {userRating?.ratingData.map((item) => (
              <>
                <ArgonBox>
                  <ArgonBox
                    sx={({ palette: { blue } }) => ({
                      pb: 2,
                      my: 2,
                      borderBottom: darkMode
                        ? `1px solid ${blue.seven}`
                        : `1px solid ${blue.one}`,
                    })}
                    onClick={() => {
                      setSelectedItem(item);
                    }}
                  >
                    <ArgonBox
                      sx={({ breakpoints }) => ({
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 3,
                        width: '100%',

                        [breakpoints.down('sm')]: {
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      })}
                    >
                      <ArgonBox>
                        <ArgonBox
                          component="img"
                          src={item?.listingId?.thumbnailImg}
                          alt=""
                          sx={({
                            palette: { white, natural },
                            breakpoints,
                          }) => ({
                            width: '80px',
                            height: '80px',
                            borderRadius: borderRadius.md,
                            objectFit: 'cover',
                            objectPosition: 'top',

                            [breakpoints.down('md')]: {
                              alignItems: 'center',
                            },
                          })}
                        />
                      </ArgonBox>

                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        sx={{ flexGrow: 1 }}
                        alignItems="flex-start"
                      >
                        <ArgonBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          gap={3}
                          width="100%"
                        >
                          <ArgonBox display="flex" flexDirection="column">
                            <ArgonBox
                              display="flex"
                              alignItems="baseline"
                              gap={1}
                            >
                              <ArgonTypography
                                variant="jpH3Bold"
                                sx={({ palette: { blue, white } }) => ({
                                  color: darkMode ? white.main : blue.seven,
                                })}
                                component={Link}
                                to={`/${item?.listingId?.category?.name
                                  .toLowerCase()
                                  ?.trim()}/single-category/${
                                  item?.listingId?._id
                                }`}
                              >
                                {item?.listingId?.name}
                              </ArgonTypography>

                              <ArgonTypography variant="jpBodyRegular">
                                ({item?.listingId?.category?.name})
                              </ArgonTypography>
                            </ArgonBox>

                            <ArgonBox
                              my={1}
                              display="flex"
                              alignItems="center"
                              gap={2}
                            >
                              <ArgonBox
                                display="flex"
                                alignItems="stretch"
                                gap={1}
                              >
                                <ArgonBox sx={{ display: 'flex' }}>
                                  {[...Array(5)].map((x, index) => (
                                    <div key={index}>
                                      {index < item?.rating ? (
                                        <FullStarFillIconStyled />
                                      ) : (
                                        <EmptyStarIconStyed />
                                      )}
                                    </div>
                                  ))}
                                </ArgonBox>

                                <ArgonTypography variant="jpBodyMedium">
                                  ({item?.rating})
                                </ArgonTypography>
                              </ArgonBox>
                              <ArgonBox
                                display="flex"
                                alignItems="center"
                                gap={1}
                              >
                                <ArgonBox
                                  component="img"
                                  src={AgeIcon}
                                  alt=""
                                />
                                <ArgonTypography variant="JpBodyRegular">
                                  {item?.listingId?.age} +
                                </ArgonTypography>
                              </ArgonBox>
                            </ArgonBox>
                          </ArgonBox>

                          <ArgonBox
                            onClick={handleOpenMenu}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Icon
                              sx={({ palette: { white, dark } }) => ({
                                color: darkMode ? white.main : dark.main,
                              })}
                            >
                              more_vert
                            </Icon>
                          </ArgonBox>
                          {renderMenu(selectedItem)}
                        </ArgonBox>

                        {/*  */}
                        {formateText(item?.ratingText)?.length > 350 ? (
                          <ReadMore>{formateText(item?.ratingText)}</ReadMore>
                        ) : (
                          <ArgonTypography variant="jpBodyRegular">
                            {' '}
                            {formateText(item?.ratingText)}
                          </ArgonTypography>
                        )}
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </>
            ))}
          </>
        ) : (
          <>
            <ArgonBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={2}
              sx={{
                height: '200px',
              }}
            >
              <ArgonTypography variant="jpH3Bold">
                Currently there are no reviews available
              </ArgonTypography>
              <ArgonTypography
                variant="jpBodyRegular"
                sx={{ maxWidth: '760px', textAlign: 'center' }}
              >
                Please rate something you watched or read or played recently
                with your children! It is helpful to other families to know what
                you think about media and entertainment.
              </ArgonTypography>
            </ArgonBox>
          </>
        )}
        <ArgonBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
          my={3}
        >
          {userRating?.totalReviewsCount > 8 ? (
            <ArgonPagination variant={'gradient'} color={'info'}>
              <>
                {pageIndex !== 0 && (
                  <>
                    <ArgonPagination
                      item
                      onClick={() => {
                        setPageIndex(parseInt(pageIndex - 1));
                        setSkip(parseInt(pageIndex - 1) * 8);
                      }}
                    >
                      <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
                    </ArgonPagination>
                  </>
                )}

                {[
                  ...Array(
                    addOneAndMakeInteger(userRating?.totalReviewsCount / 8)
                  ),
                ].map((x, index) => (
                  <ArgonPagination
                    item
                    // key={1}
                    onClick={() => {
                      setPageIndex(parseInt(index));
                      setSkip(index * 10);
                    }}
                    active={pageIndex === index}
                  >
                    {index + 1}
                  </ArgonPagination>
                ))}
              </>
              {parseInt(userRating?.totalReviewsCount / 8) !== pageIndex && (
                <ArgonPagination
                  item
                  onClick={() => {
                    setPageIndex(parseInt(pageIndex + 1));
                    setSkip(parseInt(pageIndex + 1) * 8);
                  }}
                >
                  <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
                </ArgonPagination>
              )}
            </ArgonPagination>
          ) : (
            <></>
          )}
        </ArgonBox>
        <DeleteModal
          deleteContent={'Are you sure want to delete this review ?'}
          isOpenMenu={deleteOpen}
          setIsOpenMenu={setDeleteOpen}
          handleSuccess={handleSuccess}
        />
      </Card>
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </>
  );
};

export default MyReview;
