import React from "react";
import { Card, Grid, styled } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { useArgonController } from "context";
import { ReactComponent as TextFileIcon } from "assets/images/icons/TextFile.svg";
import borders from "assets/theme/base/borders";

const ListingInformation = ({listing}) => {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { borderRadius } = borders;

  const TextFileIconSvg = styled(TextFileIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.blue.seven,
    },
  }));

  return (
    <>
      <Card sx={{ p: 4 }}>
        <ArgonBox display="flex" alignItems="center" gap={1} mb={4}>
          <TextFileIconSvg />
          <ArgonTypography
            variant="jpH3Bold"
            sx={({ palette: { blue, white } }) => ({
              color: darkMode ? white.main : blue.seven,
            })}
          >
            Listing Information
          </ArgonTypography>
        </ArgonBox>

        <Grid container spacing={3}>
          <Grid item lg={4} sm={12} xs={12}>
            <ArgonBox
              component="img"
              src={listing?.thumbnailImg}
              sx={{
                height: "180px",
                width: "100%",
                borderRadius: borderRadius.md,
              }}
              alt=""
            />
          </Grid>
          <Grid item lg={8} sm={12} xs={12}>
            <ArgonBox
              component="img"
              src={listing?.coverImg}
              sx={{
                height: "180px",
                width: "100%",
                borderRadius: borderRadius.md,
              }}
              alt=""
            />
          </Grid>
        </Grid>

        <ArgonBox component="form" role="form" mt={4}>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={12} xs={12}>
              <ArgonBox display="flex" flexDirection="column" gap={1}>
                <ArgonTypography variant="jpCaptionRegular">
                  Listing Title
                </ArgonTypography>
                <ArgonTypography variant="jpBodyBold">
                  {listing?.name}
                </ArgonTypography>
              </ArgonBox>
            </Grid>

       

            <Grid item lg={6} xs={12} sm={12}>
              <ArgonBox display="flex" flexDirection="column" gap={1}>
                <ArgonTypography variant="jpCaptionRegular">
                  Category
                </ArgonTypography>
                <ArgonTypography variant="jpBodyBold">{listing?.category?.name}</ArgonTypography>
              </ArgonBox>
            </Grid>

            <Grid item lg={6} xs={12} sm={12}>
              <ArgonBox display="flex" flexDirection="column" gap={1}>
                <ArgonTypography variant="jpCaptionRegular">
                  Year Released
                </ArgonTypography>
                <ArgonTypography variant="jpBodyBold">{listing?.yearReleased}</ArgonTypography>
              </ArgonBox>
            </Grid>

            <Grid item lg={6} xs={12} sm={12}>
              <ArgonBox display="flex" flexDirection="column" gap={1}>
                <ArgonTypography variant="jpCaptionRegular">
                  Author
                </ArgonTypography>

                <ArgonBox display="flex" alignItems="center" gap={1}>
                  <ArgonBox
                    component="img"
                    src={listing?.author?.profilePicture}
                    alt=""
                    sx={{
                      height: "26px",
                      width: "26px",
                      borderRadius: borderRadius.md,
                      objectFit: "cover",
                    }}
                  />
                  <ArgonTypography variant="jpBodyBold">
                  {listing?.author?.name}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </Grid>

            <Grid item lg={6} xs={12} sm={12}>
              <ArgonBox display="flex" flexDirection="column" gap={1}>
                <ArgonTypography variant="jpCaptionRegular">
                  Age
                </ArgonTypography>
                <ArgonTypography variant="jpBodyBold">{listing?.age}+</ArgonTypography>
              </ArgonBox>
            </Grid>

            <Grid item lg={6} xs={12} sm={12}>
              <ArgonBox display="flex" flexDirection="column" gap={1}>
                <ArgonTypography variant="jpCaptionRegular">
                  Genre
                </ArgonTypography>
                <ArgonBox display="flex" alignItems="center" gap={1}>
                {listing?.genreList?.length && listing?.genreList.map((i)=>(
                  <ArgonTypography
                    variant="jpBodyRegular"
                    sx={({ palette: { natural } }) => ({
                      background: natural.two,
                      padding: 1,
                      borderRadius: borderRadius.sm,
                      width: "fit-content",
                    })}
                  >
                    {i}
                  </ArgonTypography>
                ))}
                </ArgonBox>
              </ArgonBox>
            </Grid>
            {listing?.customFieldsList ? (
              <>
              {listing?.customFieldsList?.map((item,index)=>(
                <Grid item lg={6} xs={12} sm={12}>
                <ArgonBox display="flex" flexDirection="column" gap={1}>
                  <ArgonTypography variant="jpCaptionRegular">
                   {item?.name}
                  </ArgonTypography>
                  <ArgonTypography variant="jpBodyBold">{item?.value}</ArgonTypography>
                </ArgonBox>
              </Grid>
              ))}
            </>
            ):(
              <></>
            )}
            
            <Grid item lg={6} xs={12} sm={12}>
              <ArgonBox display="flex" flexDirection="column" gap={1}>
                {/* <ArgonTypography variant="jpCaptionRegular">
                  Category Related Fields
                </ArgonTypography> */}
                <ArgonBox display="flex" alignItems="center" gap={1}>
                  {/* <ArgonTypography
                    variant="jpBodyRegular"
                    sx={({ palette: { natural } }) => ({
                      background: natural.two,
                      padding: 1,
                      borderRadius: borderRadius.sm,
                      width: "fit-content",
                    })}
                  >
                    Xyz
                  </ArgonTypography>
                  <ArgonTypography
                    variant="jpBodyRegular"
                    sx={({ palette: { natural } }) => ({
                      background: natural.two,
                      padding: 1,
                      borderRadius: borderRadius.sm,
                      width: "fit-content",
                    })}
                  >
                    Xyz
                  </ArgonTypography>
                  <ArgonTypography
                    variant="jpBodyRegular"
                    sx={({ palette: { natural } }) => ({
                      background: natural.two,
                      padding: 1,
                      borderRadius: borderRadius.sm,
                      width: "fit-content",
                    })}
                  >
                    Xyz
                  </ArgonTypography> */}
                </ArgonBox>
              </ArgonBox>
            </Grid>
          </Grid>
        </ArgonBox>
      </Card>
    </>
  );
};

export default ListingInformation;
