/* eslint-disable no-param-reassign */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, Fragment } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-router components
import { Link, NavLink, useNavigate } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import Container from "@mui/material/Container";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI examples components
import UserNavbarMobile from "examples/Navbars/UserNavbar/UserNavbarMobile";

// Argon Dashboard 2 PRO MUI Base Styles
import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 PRO React context
import { useArgonController } from "context";
import JoyfulLogo from "assets/images/logos/Joyful-logo.svg";
import {
  Card,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  styled,
  Typography,
} from "@mui/material";
import ArgonInput from "components/ArgonInput";
import ArgonAvatar from "components/ArgonAvatar";
import UserCategoryNav from "./UserCategoryNav";
import { useSelector } from "react-redux";
import ArgonButton from "components/ArgonButton";
import { setDarkMode } from "context";
import { useDispatch } from "react-redux";
import theme from "assets/theme";
import api2 from "api2";
import axios from "axios";

function UserNav({ routes, brand, transparent, light, action }) {
  const [controller, dispatch] = useArgonController();
  const dispatchMode = useDispatch();
  // const { darkMode } = controller;
  const darkMode = useSelector((state) => state?.authState?.mode);
  const { palette } = theme;
  const { white, blue, natural } = palette;

  const navigator = useNavigate();

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const userData = useSelector((state) => state?.authState?.user?.user);
  const token = useSelector((state) => state?.authState?.user?.token);
  const [isChecked, setIsChecked] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");

  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);
  useEffect(() => {
    setDarkMode(dispatch, darkMode);
  }, [darkMode]);
  useEffect(() => {
    // A function that sets the display state for the UserNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);
  const handleClick = () => {
    if (
      token &&
      (userData?.role === "parent" || userData?.role === "teenager")
    ) {
      navigator("/profile");
    } else if (
      token &&
      (userData?.role === 'editor' ||
        userData?.role === 'author' ||
        userData?.role === 'superadmin')
    ) {
      navigator('/categories');
    } else {
      navigator('/signin');
    }
  };
  const handleOptions = async (searchValue) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      await api2.get(`listings?search=${searchValue}`, config).then((res) => {
        if (res) {
          setOptions(res?.data?.data?.listing);
        }
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };

  const MaterialUISwitch = styled(Switch)(() => ({
    width: 62,
    height: 34,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      top: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: `${darkMode} ? ${blue.five} : ${blue.five} !important`,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: darkMode ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: darkMode ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  return (
    <ArgonBox
      display="flex"
      flexDirection="column"
      sx={{
        position: "relative",
        zIndex: 100,
      }}
    >
      <ArgonBox
        py={2}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 3 }}
        my={0}
        mx={0}
        width="100%"
        shadow={transparent}
        color={light ? "white" : "dark"}
        position="relative"
        left={0}
        zIndex={99}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(darkMode ? background.dark : white.main, 0.8),
          // backgroundColor: transparent,
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <Container>
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={3}
            >
              <ArgonBox display="flex" component={Link} to="/home">
                <ArgonBox component="img" src={JoyfulLogo} alt="Joyful" />
              </ArgonBox>

              <ArgonBox position="relative">
                <ArgonInput
                  placeholder="Search Something.."
                  value={search}
                  startAdornment={
                    <Icon fontSize="small" style={{ marginRight: "6px" }}>
                      search
                    </Icon>
                  }
                  sx={{
                    borderRadius: "40px",
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    handleOptions(e.target.value);
                  }}
                />

                {search ? (
                  <Card
                    sx={({ palette: { white, blue } }) => ({
                      px: 2,
                      pb: 1,
                      pt: 2,
                      width: "100%",
                      position: "absolute",
                      zIndex: 9999,
                      my: 1,
                      borderRadius: 2,
                      backgroundColor: darkMode ? blue.seven : white.main,
                      maxHeight: 300,
                      overflowY: "auto",
                    })}
                  >
                    {options.length ? (
                      <>
                        {options.map((i, index) => (
                          <>
                            {index < 5 ? (
                              <>
                                <ArgonTypography
                                  variant="jpCaptionRegular"
                                  sx={({ palette: { white, blue } }) => ({
                                    textTransform: "capitalize",
                                    margin: "3px 0",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: darkMode
                                        ? blue.five
                                        : blue.one,
                                    },
                                  })}
                                  onClick={() => {
                                    navigator(
                                      `/${i?.category?.name
                                        .toLocaleLowerCase()
                                        .trim()}/single-category/${i?._id}`
                                    );
                                    setSearch("");
                                  }}
                                >
                                  {i.name}
                                </ArgonTypography>
                                <Divider sx={{ my: 0 }} />
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                        <ArgonTypography
                          variant="button"
                          fontWeight="bold"
                          sx={({ palette: { white, blue } }) => ({
                            // background: blue.one,
                            textTransform: "capitalize",
                            margin: "10px 0 5px",
                            padding: "5px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: darkMode ? blue.five : blue.one,
                            },
                          })}
                          onClick={()=>{
                            navigator(`/search/${search}`)
            // eslint-disable-next-line no-restricted-globals 
                            window.location.reload()
                          }}
                        >
                          Show all results
                        </ArgonTypography>
                      </>
                    ) : (
                      <ArgonTypography
                        variant="jpCaptionRegular"
                        sx={({ palette: { white, blue } }) => ({
                          textTransform: "capitalize",
                          marginBottom: "6px",
                          padding: "5px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: darkMode ? blue.five : blue.one,
                          },
                        })}
                      >
                        No search found
                      </ArgonTypography>
                    )}
                  </Card>
                ) : (
                  <></>
                )}
              </ArgonBox>
            </ArgonBox>

            <>
              {token ? (
                <ArgonBox display="flex" alignItems="center" gap={2}>
                  <FormGroup
                    sx={{
                      // width: "100%",
                      position: "relative",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <MaterialUISwitch sx={{ m: 1 }} checked={darkMode} />
                      }
                      label=""
                      onChange={(e) => {
                        dispatchMode({
                          type: "MODE",
                          status: "success",
                          data: !darkMode,
                        });
                        setDarkMode(dispatch, !darkMode);
                      }}
                    />
                  </FormGroup>
                  <>
                    <ArgonBox
                      // component={Link}
                      // to="/profile"
                      display="flex"
                      alignItems="flex-end"
                      justifyContent="space-between"
                      onClick={() => handleClick()}
                      sx={{ cursor: "pointer" }}
                    >
                      <ArgonBox display="flex" alignItems="center">
                        <ArgonBox mr={2}>
                          {/* <ArgonAvatar
                        src={userData?.profilePicture}
                        alt="something here"
                        variant="rounded"
                        shadow="md"
                        bgColor="light"
                      /> */}
                          {userData?.profilePicture ? (
                            <ArgonBox
                              component="img"
                              src={userData?.profilePicture}
                              sx={{
                                height: "48px",
                                width: "48px",
                                objectFit: "cover",
                              }}
                              alt="profile-image"
                              borderRadius="lg"
                              shadow="sm"
                            />
                          ) : (
                            <ArgonAvatar
                              variant="rounded"
                              sx={{
                                height: "48px",
                                width: "48px",
                              }}
                              alt="profile-image"
                              shadow="sm"
                              bgColor="info"
                            />
                          )}
                        </ArgonBox>
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          justifyContent="center"
                        >
                          <ArgonTypography
                            variant="jpBodyMedium"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {userData?.userName}
                          </ArgonTypography>
                          <ArgonTypography
                            variant="jpCaptionRegular"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {userData?.role}
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                  </>
                </ArgonBox>
              ) : (
                <>
                  <ArgonBox
                    display={{ xs: "none", lg: "flex" }}
                    gap={2}
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <ArgonTypography
                      variant="jpBodyBold"
                      component={Link}
                      to={"/signin"}
                    >
                      Login
                    </ArgonTypography>
                    <ArgonButton
                      component={Link}
                      to={"/signup"}
                      size="large"
                      sx={({ palette: { blue } }) => ({
                        background: blue.one,
                        color: blue.eight,
                        padding: "12px 24px",
                        boxShadow: "none",

                        ":hover": {
                          background: blue.one,
                        },
                        ":focus:not(:hover)": {
                          background: blue.one,
                        },
                      })}
                    >
                      Sign Up
                    </ArgonButton>
                  </ArgonBox>
                </>
              )}

              {/* <Switch checked={darkMode}
            onChange={(e)=>{
              dispatchMode({
                type: "MODE",
                status: "success",
                data: !darkMode,
              });
                setDarkMode(dispatch, !darkMode);
            }} /> */}
            </>

            <ArgonBox
              display={{ xs: "inline-block", lg: "none" }}
              lineHeight={0}
              py={1.5}
              pl={1.5}
              color={transparent || darkMode ? "white" : "inherit"}
              sx={{ cursor: "pointer" }}
              onClick={openMobileNavbar}
            >
              <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox
            shadow={transparent ? "lg" : "none"}
            borderRadius="md"
            px={transparent ? 2 : 0}
            sx={{
              backgroundColor: ({
                palette: { white, transparent: transparentColor, background },
              }) => {
                let bgColorValue = transparentColor.main;

                if (transparent && darkMode) {
                  bgColorValue = background.dark;
                } else if (transparent) {
                  bgColorValue = white.main;
                }

                return bgColorValue;
              },
            }}
          >
            {mobileView && (
              <UserNavbarMobile routes={routes} open={mobileNavbar} />
            )}
          </ArgonBox>
        </Container>
      </ArgonBox>
    </ArgonBox>
  );
}

// Declaring default props for UserNav
UserNav.defaultProps = {
  brand: "Joyful",
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the UserNav
UserNav.propTypes = {
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(["contained", "outlined", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
      "white",
    ]),
    label: PropTypes.string.isRequired,
  }),
};

export default UserNav;
