import React from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import ArgonBox from "components/ArgonBox";
import UserNavbar from "examples/Navbars/UserNavbar";
import Footer from "examples/Footer";
import ArticlePage from "./view/ArticlePage";

const AllArticles = () => {
  return (
    <>
      <PageLayout>
        <UserNavbar />
        <ArgonBox>
          <ArticlePage />
        </ArgonBox>
      </PageLayout>
      <Footer />
    </>
  );
};

export default AllArticles;
