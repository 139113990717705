import React from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import ArgonBox from "components/ArgonBox";
import UserNavbar from "examples/Navbars/UserNavbar";
import Footer from "examples/Footer";
import SearchPage from "./view/SearchPage";

const Search = () => {
  return (
    <>
      <PageLayout>
        <UserNavbar />
        <ArgonBox>
          <SearchPage />
        </ArgonBox>
      </PageLayout>
      <Footer />
    </>
  );
};

export default Search;
