const ImagePreview = ({ f }: { f: any }) => {
  return (
    // <div className="d-flex align-items-center flex-column">
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        border: "1px solid transparent",
      }}
      key={f.path}
    >
      <img
        data-dz-thumbnail=""
        style={{
          width: "inherit",
          height: "100%",
          borderRadius: "15px",
          objectFit: "contain",
        }}
        alt="Arc_de_Triomphe,_Paris_21_October_2010.png"
        // src={URL.createObjectURL(f)}
        src={"/"}
      />
    </div>
    //   <p
    //     style={{
    //       whiteSpace: "nowrap",
    //       overflow: "hidden",
    //       textOverflow: "ellipsis",
    //       width: 350,
    //     }}
    //   >
    //     {f.name}
    //   </p>
    // </div>
  );
};

export default ImagePreview;
