import React from "react";
import { Card, styled } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import { useArgonController } from "context";
import borders from "assets/theme/base/borders";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ArgonTypography from "components/ArgonTypography";
import { ReactComponent as FullStarIcon } from "assets/images/icons/FullStar.svg";
import AgeIcon from "assets/images/icons/AgeIcon.svg";
import { useSelector } from "react-redux";
import api2 from "api2";
import { useDispatch } from "react-redux";
import { getBookmarkList } from "Actions/listingAction";
import axios from "axios";

const BookMarkCard = ({i}) => {
  const [controller] = useArgonController();
  const dispatch  = useDispatch()
  const userData = useSelector((state) => state?.authState?.user?.user);
  const token = useSelector((state) =>state?.authState?.user?.token)
  const navigator = useNavigate();
  const { darkMode } = controller;
  const { borderRadius } = borders;
  const handleClick =async (bookmark)=>{
    try{
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let result = await api2
      .post(`bookmarks/manage`,{listingIds:bookmark}, config)
      .then((res) => {
    if(token && (userData?.role === "parent" || userData?.role === "teenager")){
      dispatch(getBookmarkList(userData?._id))
    }          
      });
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new Error("Cancelled");
    }
  }
  }
  const BookmarkList = useSelector((state) => state?.listingState?.bookmarkList?.listingIds)

  const FullStarFillIconStyled = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: theme.palette.yellow.five,
    },
  }));
  const EmptyStarIconStyed = styled(FullStarIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.three,
    },
  }));

  return (
    <>
      <Card
        sx={({ palette: { white, blue } }) => ({
          p: 2,
          position: "relative",
          backgroundColor: darkMode ? blue.seven : white.main,
        })}
      >
        <ArgonBox
        component={Link}
        to={`/${i?.category?.name.toLocaleLowerCase().trim()}/single-category/${i?._id}`}
        >
          <ArgonBox
            component="img"
              src={i?.thumbnailImg}
            alt=""
            sx={{
              height: "210px",
              width: "100%",
              borderRadius: borderRadius.md,
              objectFit: 'cover',
              objectPosition: 'top',
              // objectFit: "contain",
            }}
          />
        </ArgonBox>

        <ArgonBox
          sx={({ palette: { white, blue } }) => ({
            position: "absolute",
            right: 20,
            top: 20,
            display: "flex",
            alignItems: "center",
            p: 1,
            borderRadius: "100px",
            zIndex: 1,
            background: white.main,
            cursor: "pointer",

            ":hover": {
              background: blue.one,
            },
          })}
        >
          <BookmarkIcon
            sx={({ palette: { blue } }) => ({
              height: "24px",
              width: "24px",
              fill: blue.five,
            })}
            onClick={()=>{
              let bookmarkInfoList =BookmarkList.length ?  BookmarkList.map((i)=>i?._id) : []
              let findIndex = bookmarkInfoList.findIndex((x)=>x === i?._id)
              bookmarkInfoList.splice(findIndex,1)
              handleClick(bookmarkInfoList)
            }}
          />
        </ArgonBox>

        <ArgonBox
          display="flex"
          flexDirection="column"
          gap={1.2}
          mt={1}
            component={Link}
            to={`/${i?.category?.name.toLocaleLowerCase().trim()}/single-category/${i?._id}`}
        >
          <ArgonTypography
            variant="h5"
            className="tr-w-1"
            sx={{
              textTransform: "capitalize",
            }}
          >
            {i?.name}
            name
          </ArgonTypography>

          <ArgonBox display="flex" flexDirection="column" gap={1.2} mt={2}>
            <ArgonBox
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <ArgonBox display="flex" alignItems="center" gap={1}>
                <ArgonBox component="img" src={AgeIcon} alt="" />
                <ArgonTypography variant="JpBodyMedium">
                  {i?.age}+ 
                </ArgonTypography>
              </ArgonBox>

              <ArgonBox
                display="flex"
                alignItems="center"
                gap={1}
                sx={({ palette: { natural } }) => ({
                  padding: "2px 8px 2px 6px",
                  border: `1px solid ${natural.three}`,
                  borderRadius: borderRadius.md,
                })}
              >
                {i.averageRating ? (
                  <FullStarFillIconStyled />
                ):(
                  <EmptyStarIconStyed/>
                )}

                <ArgonTypography variant="jpBodyMedium">{i.averageRating}</ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </>
  );
};

export default BookMarkCard;
