import API2 from "api2";
import { addFamily } from "./userAction";
export const registerAction = (registerInfo) => {
    const payload = {
       ...registerInfo
    }
    return (dispatch, getState) => {
        API2.post("auth/register", payload)
            .then((res) => {
                if(res.data?.data?.user?.role === "parent"){
                    dispatch(addFamily({moderatorsId:res.data?.data?.user?._id})); 
                      }
                dispatch({
                    type: "REGISTER_SUCCESS",
                    payload: res?.data,
                    status:"success",
                    message:"User registration successfully"
                });
            })
            .catch((err) => {
                dispatch({
                  type: "REGISTER_FAILED",
                  payload: {},
                  message: err.response.data.data,
                });
              });
    }
}