import DragComponent from "./DragComponent";
import ImagePreview from "./ImagePreview";
import PropTypes from "prop-types";
export default function FilesPreview({
  files,
  isDragActive,
}: {
  files: any,
  isDragActive: boolean,
}) {
  if (!files?.length) {
    return <DragComponent isDragActive={isDragActive} />;
  }
  return (
    <div className="d-flex flex-row position-relative flex-wrap">
      {files.map((f) => {
        if (!f) return null;

        return f.type.includes("image") ? (
          <ImagePreview f={f} key={f.path} />
        ) : (
          <div className="d-flex align-items-center flex-column" key={f.path}>
            <i className="bi bi-file-earmark-fill" style={{ fontSize: 50 }} />
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 350,
                textAlign: "center",
                marginTop: 10,
              }}
            >
              {f.name}
            </p>
          </div>
        );
      })}
    </div>
  );
}
