/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";
import ArgonInput from "components/ArgonInput";
import { Card, Switch, styled } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import { useArgonController } from "context";
import CategoriesCard from "examples/Cards/CategoriesCard";
import ArgonDropzone from "components/ArgonDropzone";
import ImageUploadCard from "examples/Cards/ImageUploadCard";
import { useEffect, useRef } from "react";
import FormField from "components/Common/FormField";
import ArgonEditor from "components/ArgonEditor";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as TextFileIcon } from "assets/images/icons/TextFile.svg";
import ArgonSelect from "components/ArgonSelect";
import FormSelect from "components/Common/FormSelect";
// import FormEditor from "components/Common/FormEditor";
import { useDispatch } from "react-redux";
import api2 from "api2";
import axios from "axios";
import FormEditor from "components/Common/FormEditor";
import { useSelector } from "react-redux";
import { getCategoriesListApi } from "Actions/categoryAction";
import { getUserByRole } from "Actions/categoryAction";
import { addListingApi } from "Actions/listingAction";
import ArgonSnackbar from "components/ArgonSnackbar";
import { updateListingApi } from "Actions/listingAction";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import CloseIcon from "@mui/icons-material/Close";
import Lightbox from "lightbox-react";
import "lightbox-react/style.css";
import { getArticleCategoriesListApi } from "Actions/categoryAction";
import { addArticleApi } from "Actions/listingAction";
import { updateArticleApi } from "Actions/listingAction";

function AddArticle() {
  const { size } = typography;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { id } = useParams();
  const thumbnailRef = useRef();
  const coverRef = useRef();
  const mediaRef = useRef();
  const [categoryList, setCategoryList] = useState([]);
  const [authorList, setAuthorList] = useState([]);
  const [description,setDescription]  = useState("");

  const [logDescription, setLogDescription] = useState("");
  const articleCategories = useSelector(
    (state) => state.categoryState.articleCategoriesList || []
  );
  const userData = useSelector((state) => state?.authState?.user);
  const [isFeatured, setIsFeatured] = useState(true);
  const [articleInfo, setArticleInfo] = useState();
  const token = useSelector((state) => state?.authState?.user?.token);
  const [showError, setShowError] = useState(false);
  const [thumbnailImg, setThumbnailImg] = useState();
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  const [mediaLoader, setMediaLoader] = useState(false);
  const [isOpenLightBox, setIsOpenLightBox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [shortDescription,setShortDescription] = useState()
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });
  const fetchArticle = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let result = await api2.get(`article/${id}`, config).then((res) => {
        setThumbnailImg(res?.data?.data?.thumbnailImg)
        setShortDescription(res?.data?.data?.shortDescription)
        setDescription(res?.data?.data?.description)
        setArticleInfo(res?.data?.data)
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  useEffect(() => {
    if (id) {
      fetchArticle();
    }
  }, []);
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setArticleInfo({
      ...articleInfo,
      [name]: value,
    });
  };
  const handleUpload = async (fieldName, file) => {
    try {
      const config = {
        headers: { Authorization: token },
      };
      let formData = new FormData();
      formData.append("file", file);
      let result = await api2.post(`imageUpload`, formData, config);
      if (fieldName === "thumbnailImg") {
        setThumbnailLoader(false);
        setThumbnailImg(result?.data?.data);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setAlert({
          isOpen: true,
          status: "error",
          alertMsg: "can't upload image",
          bgColor: "bgred",
        });
        throw new Error("Cancelled");
      }
    }
  };
  useEffect(() => {
    let categoryListArray = [];
    categoryListArray =
    articleCategories.length &&
    articleCategories.map((item) => {
        return {
          label: item?.name,
          value: item?._id,
        };
      });
    setCategoryList(categoryListArray || []);
  }, [articleCategories]);
  useEffect(() => {
    dispatch(getArticleCategoriesListApi());
    if (userData?.user?.role === 'superadmin') {
      dispatch(getUserByRole(['author']));
    }
    dispatch(getArticleCategoriesListApi());
  }, [dispatch]);
  const handleClick = (e, isDraft) => {
    setShowError(true);
    if (
      articleInfo?.title && articleInfo?.categoryIds.length && articleInfo?.age
    ) {
      let payload = {
        title:articleInfo?.title,
        categoryIds:articleInfo?.categoryIds,
        age:articleInfo?.age,
        shortDescription:shortDescription,
        description:description,
        thumbnailImg:thumbnailImg,
      };
      if (userData?.user?.role === 'superadmin') {
        payload = { ...payload, approvalStatus: 'approved' };
      } else {
        payload = { ...payload, approvalStatus: 'pending' };
      }
      if (id) { 
        dispatch(updateArticleApi(payload, id));
        setAlert({
          ...alert,
          status: "success",
          isOpen: true,
          alertMsg: "Article update successfully.",
          bgColor: "bggreen",
        });
      } else {
        dispatch(addArticleApi(payload));
        setAlert({
          ...alert,
          status: "success",
          isOpen: true,
          alertMsg: "Article added successfully.",
          bgColor: "bggreen",
        });
      }
      setTimeout(() => {
        navigator("/articles");
      }, 500);
    }
  };
  const TextFileIconSvg = styled(TextFileIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.blue.seven,
    },
  }));
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={4}>
        <Grid container direction="row" spacing={4} sx={{}}>
          <Grid item xs={12} md={12} lg={9}>
            <Card sx={{ p: 4 }}>
              <ArgonBox display="flex" alignItems="center" gap={1} mb={4}>
                <TextFileIconSvg />
                <ArgonTypography
                  variant="jpH3Bold"
                  sx={({ palette: { blue, white } }) => ({
                    color: darkMode ? white.main : blue.seven,
                  })}
                >
                  Write Article
                </ArgonTypography>
              </ArgonBox>

              <Grid container spacing={3}>
                <Grid
                  item
                  lg={4}
                  sm={12}
                  xs={12}
                  onClick={() => {
                    thumbnailRef?.current.childNodes[0].click();
                  }}
                >
                  <ImageUploadCard
                    url={thumbnailImg}
                    title={"Choose Thumbnail"}
                    isLoading={thumbnailLoader}
                  />
                  <ArgonBox
                    ref={thumbnailRef}
                    sx={{ position: "relative", display: "none" }}
                  >
                    <ArgonDropzone
                      style={{
                        position: "absolute",
                        bottom: 0,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      options={{
                        onDrop: (acceptedFiles) => {
                          setThumbnailLoader(true);
                          handleUpload("thumbnailImg", acceptedFiles[0]);
                        },
                        accept: { "image/*": [] },
                      }}
                    />
                  </ArgonBox>
                </Grid>
              </Grid>

              <ArgonBox component="form" role="form" mt={4}>
                <Grid container spacing={3}>
                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormField
                        type="text"
                        placeholder="Your Article Title"
                        size="large"
                        label="Article Title"
                        value={articleInfo?.title}
                        name="title"
                        onChange={handleChanges}
                        error={showError && !articleInfo?.title}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      {console.log('categoryList', categoryList)}
                      {console.log('articleInfo?.categoryIds', articleInfo?.categoryIds)}
                      <FormSelect
                        isMulti
                        options={categoryList}
                        placeholder="Listing Category"
                        size="large"
                        label="Category"
                        value={
                          categoryList.filter((item)=>articleInfo?.categoryIds.find((i)=>i?._id === item?.value))
                        }
                        onChange={(selectedValue) => {
                          setArticleInfo({
                            ...articleInfo,
                            categoryIds: selectedValue.map((item)=>item?.value),
                          });
                        }}
                        // error={showError && !articleInfo?.category}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormField
                        type="number"
                        placeholder="Your Age"
                        size="large"
                        label="Age"
                        name="age"
                        error={showError && !articleInfo?.age}
                        value={articleInfo?.age}
                        onChange={handleChanges}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={12} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        label="Short Description"
                        placeholder="Write something..."
                        value={shortDescription || ""}
                        onChange={(value) =>  setShortDescription(value) }
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        label="Description"
                        placeholder="Write something..."
                        value={description || ""}
                        onChange={(value) => {
                          setDescription(value)
                        }} 
                      />
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
          </Grid>

          <Grid item xs={12} md={12} lg={3}>
            <Card sx={{ p: 2 }}>
              <ArgonBox
                mb={1}
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <ArgonButton
                  color="info"
                  size="large"
                  fullWidth
                  onClick={(e) => handleClick(e, { isDraft: false })}
                >
                  Publish
                </ArgonButton>
                {articleInfo?.isDraft !== false && (
                  <ArgonButton
                    size="large"
                    sx={({ palette: { blue } }) => ({
                      background: blue.one,
                      ":hover": {
                        background: blue.one,
                      },
                      ":focus:not(:hover)": {
                        background: blue.one,
                      },
                    })}
                    fullWidth
                    onClick={(e) => handleClick(e, { isDraft: true })}
                  >
                    Save Draft
                  </ArgonButton>
                )}

                <ArgonButton
                  size="large"
                  sx={({ palette: { error } }) => ({
                    background: error.secondary,
                    color: error.primary,
                    ":hover": {
                      background: error.secondary,
                    },
                    ":focus:not(:hover)": {
                      background: error.secondary,
                    },
                  })}
                  fullWidth
                  component={Link}
                  to="/articles"
                >
                  Cancel
                </ArgonButton>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </DashboardLayout>
  );
}

export default AddArticle;
