import API2 from "api2";
import { errorHandling } from "components/Common/errorhandling";

const config = (accessToken) => ({
  headers: { Authorization:`Bearer ${accessToken}` },
});

export const updateUser = (payload,id) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token
    return new Promise(function (resolve, reject) {
        API2.put(`account/update/${id}`,payload, config(token))
        .then((res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          reject(errorMessage);
          errorHandling(err?.response)
        });
    });
  };
};

export const addFamily = (payload) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token
    return new Promise(function (resolve, reject) {
        API2.post(`family/create`,payload, config(token))
        .then((res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          reject(errorMessage);
          errorHandling(err?.response)
        });
    });
  };
};

export const addTeenAger = (payload) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token
    return new Promise(function (resolve, reject) {
        API2.post(`family/add-teenager`,payload, config(token))
        .then((res) => {
          const { data } = res;
          resolve(data);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.message;
          reject(errorMessage);
          errorHandling(err?.response)
        });
    });
  };
};
export const getFamilyView = (parentId) => {
  return async (dispatch, getState) => {
    const token = getState()?.authState?.user?.token
    return new Promise(function (resolve, reject) {
        API2.get(`family/view/${parentId}`, config(token))
        .then((res) => {
          const { data } = res;
          dispatch({ type: "GET_MY_FAMILY_SUCCESS", payload: "success", data });
          resolve(data);
        })
        .catch((err) => {
          return 
          // const errorMessage = err?.response?.data?.message;
          // reject(errorMessage);
          // errorHandling(err?.response)
        });
    });
  };
};
