import {
  Box,
  Collapse,
  Divider,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DataTableBodyCell from "./DataTableBodyCell";
import { formateText } from "components/Common/formateText";
import ArgonTypography from "components/ArgonTypography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArgonBox from "components/ArgonBox";
import { useArgonController } from "context";

export default function DataTableRow({
  key,
  row,
  noEndBorder,
  rows,
  collapsed,
}) {
  const [isOpen, setIsOpen] = useState();
  const [controller] = useArgonController();
  const { darkMode } = controller;

  return (
    <>
      <TableRow
        key={key}
        style={collapsed && { cursor: 'pointer' }}
        {...row.getRowProps()}
        onClick={() => {
          if (collapsed) {
            setIsOpen(!isOpen);
          }
        }}
      >
        {row.cells.map((cell, index) => (
          <DataTableBodyCell
            key={index}
            noBorder={noEndBorder && rows.length - 1 === key}
            align={cell.column.align ? cell.column.align : 'left'}
            {...cell.getCellProps()}
          >
            {cell.render('Cell')}
          </DataTableBodyCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {console.log('row', row?.original?.action?.props?.item)}
            {row?.original?.action?.props?.item?.reportComments?.length && (
              <>
                {row?.original?.action?.props?.item?.reportComments.map((i) => (
                  <ArgonBox>
                    <ArgonBox display="flex" alignItems="center" gap={1} my={2}>
                      <ArgonBox
                        component="img"
                        src={i?.flaggedBy?.profilePicture}
                        alt=""
                        height="30px"
                        width="30px"
                        borderRadius="md"
                        bgColor="light"
                        sx={{ objectFit: 'cover' }}
                      />
                      <ArgonTypography
                        variant="jpBodyBold"
                        sx={({ palette: { blue, white } }) => ({
                          color: darkMode ? white.main : blue.seven,
                          textTransform: 'capitalize',
                        })}
                      >
                        {i?.flaggedBy?.name}'s comment
                      </ArgonTypography>
                    </ArgonBox>

                    <ArgonBox ml={5}>
                      <ArgonTypography
                        variant="jpCaptionNormal"
                        gutterBottom
                        component="div"
                      >
                        {formateText(i?.comment)}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                ))}
              </>
            )}

            <Divider />

            {/* <Box sx={{ margin: 1 }}>
              <ArgonTypography variant="jpCaptionNormal" gutterBottom component="div">
              {formateText(row?.original?.action?.props?.item?.ratingText)}
              </ArgonTypography>
              </Box> */}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
