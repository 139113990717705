/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Link from "@mui/material/Link";

// Argon Dashboard 2 PRO MUI components
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI context
import { useArgonController } from "context";

// Images
import { ListItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "assets/images/icons/Logout.svg";
import borders from "assets/theme/base/borders";
import { useDispatch } from "react-redux";

function SidenavFooter() {
  const [controller] = useArgonController();
  const navigator = useNavigate()
  const dispatch = useDispatch()
  const { darkMode } = controller;
   const { borderRadius } = borders;

  const LogoutIconStyled = styled(LogoutIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.blue.one : theme.palette.error.main,
    },
  }));

  return (
    <ArgonBox>
      {/* <NavLink to={"/signin"}> */}
        <ListItem
          component="li"
          sx={({ palette: { blue, error } }) => ({
            // backgroundColor: error.secondary,
            backgroundColor: darkMode ? blue.five :  error.secondary,

            padding: "12px",
            borderRadius: borderRadius.md,
            display: "flex",
            alignItems: "center",
            cursor:"pointer"
          })}
          onClick={()=>{
            // navigator('/signin')
            // eslint-disable-next-line no-restricted-globals 
            location.replace("/signin")
            dispatch({ type: "login", payload: "success", data:{}})

            // sessionStorage.setItem("listingId","")
          }}
        >
          <LogoutIconStyled />
          <ArgonTypography
            variant="jpBodyRegular"
            sx={({ palette: { blue, error } }) => ({
              color: darkMode ? blue.one :  error.primary,
              ml: 1,
            })}
          >
            Sign Out
          </ArgonTypography>
        </ListItem>
      {/* </NavLink> */}
    </ArgonBox>
  );
}

export default SidenavFooter;
