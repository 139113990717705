
export default (
  state = { categoriesList: [], authorList: [] },
  action
) => {
  switch (action.type) {
    case "ADD_CATEGORY_SUCCESS":
      return {
        ...state,
        category: action?.data,
      };

    case "ADD_CATEGORY_FAILED":
      return {
        ...state,
        category: [],
      };
    case "ADD_ARTICLE_CATEGORY_SUCCESS":
      return {
        ...state,
        articleCategory: action?.data,
      };

    case "ADD_ARTICLE_CATEGORY_FAILED":
      return {
        ...state,
        articleCategory: [],
      };
    case "CATEGORIES_LIST_SUCCESS":
      return {
        ...state,
        categoriesList: action?.data,
      };
    case "CATEGORIES_LIST_FAILED":
      return {
        ...state,
        categoriesList: [],
      };
    case "CATEGORIES_ARTICLE_LIST_SUCCESS":
      return {
        ...state,
        articleCategoriesList: action?.data,
      };
    case "CATEGORIES_ARTICLE_LIST_FAILED":
      return {
        ...state,
        articleCategoriesList: [],
      };
    case "SINGLE_CATEGORIES_SUCCESS":
      return {
        ...state,
        singleCategory: action?.data,
      };
    case "SINGLE_CATEGORIES_FAILED":
      return {
        ...state,
        singleCategory: {},
      };
    case "FETCH_AuthorList_SUCCESS":
      return {
        ...state,
        authorList: action?.data,
      };
    case "FETCH_BANNER_SLIDER":
      return {
        ...state,
        bannersList: action?.data,
      };
    case "FETCH_AuthorList_FAILED":
      return {
        ...state,
        authorList: [],
      };
    default:
      return state;
  }
};
