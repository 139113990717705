import React, { useEffect, useState } from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import ArgonBox from "components/ArgonBox";
import UserNavbar from "examples/Navbars/UserNavbar";
import Footer from "examples/Footer";
import { Container, Icon } from "@mui/material";
import ArgonTypography from "components/ArgonTypography";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useArgonController } from "context";
import { useSelector } from "react-redux";
import api2 from "api2";
import { formateText } from "components/Common/formateText";
import axios from "axios";

const ViewArticle = () => {
  const {id} = useParams()
  const navigator = useNavigate()
  const [controller] = useArgonController();
  const [article,setArticle] = useState()
  const token = useSelector((state) => state?.authState?.user?.token);
  const { darkMode } = controller;
  const fetchArticleData = async()=>{
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
       await api2.get(`article/${id}`, config).then((res) => {
        setArticle(res?.data?.data)
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  }
  useEffect(()=>{
    fetchArticleData()
  },[id])

  return (
    <>
      <PageLayout>
        <UserNavbar />
        <ArgonBox>
          <Container>
            <ArgonBox my={5}>
              <ArgonTypography
                component={Link}
                to="/all-article"
                variant="body2"
                sx={{
                  lineHeight: 0,
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "underline",
                  gap: 1,
                }}
              >
                <Icon fontSize="medium">navigate_before_icon</Icon>
                Browse all articles
              </ArgonTypography>

              <ArgonBox my={2}>
                <ArgonBox my={1}>
                  <ArgonTypography variant="h3">{article?.title}</ArgonTypography>
                </ArgonBox>

                <ArgonBox my={1}>
                  <ArgonTypography variant="subtitle1">
                   {formateText(article?.shortDescription)}
                  </ArgonTypography>
                </ArgonBox>

                <ArgonBox
                  my={2}
                  display="flex"
                  alignItems="center"
                  gap={1}
                  flexWrap="wrap"
                >
                  <ArgonTypography variant="jpBodyMedium">
                    Topics :
                  </ArgonTypography>
                  {article?.categoryIds.length ? (
                  <>
                    {
                      article?.categoryIds.map((i)=>(
                        <ArgonTypography
                        variant="jpBodyMedium"
                        sx={({ palette: { white, natural, blue } }) => ({
                          padding: "8px 16px",
                          backgroundColor: !darkMode ? natural.three : blue.five,
                          borderRadius: "8px",
                          textTransform: "capitalize",
                          cursor: "pointer",
    
                          ":hover": {
                            backgroundColor: !darkMode ? natural.four : blue.seven,
                          },
                        })}
                        onClick={()=>{
                          navigator(`/all-article/${i?._id}`)
                        }}
                      >
                        {i?.name}
                      </ArgonTypography>
                      ))
                    }
                  </> 
                  ):(
                    <></>
                  )}
                
                </ArgonBox>

                {/*  */}
                <ArgonBox
                  component="img"
                  src={article?.thumbnailImg}
                  alt=""
                  sx={{
                    width: "100%",
                    height: "500px",
                    objectFit: "contain",
                    my: 2,
                  }}
                />

                {/*  */}

                <ArgonTypography variant="jpBodyMedium">
                  <div
                    className="atricle-text"
                        dangerouslySetInnerHTML={{
                          __html: article?.description,
                        }}/>
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </Container>
        </ArgonBox>
      </PageLayout>
      <Footer />
    </>
  );
};

export default ViewArticle;
