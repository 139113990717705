/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import JoyfulLogo from "assets/images/logos/Joyful-logo.svg";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateUser } from "Actions/userAction";
import { useState } from "react";
import ArgonSnackbar from "components/ArgonSnackbar";
import axios from "axios";
import api2 from "api2";
// import useRazorpay from "react-razorpay";


function DefaultPricingCard({
  title,
  price,
  subTitle,
  specifications,
  action,
}) {
  const userData = useSelector((state) => state?.authState?.user);
  const token = useSelector((state) => state?.authState?.user?.token);
  const navigator = useNavigate()
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
  });
  async function displayRazorpay(plan) {
    let options  ={}
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let result = await api2
        .post(`payment/addOrder`, { plan: plan}, config)
        .then((res) => {
           options = {
              key: res?.data?.data?.key_id, // Enter the Key ID generated from the Dashboard
              amount: res?.data?.data?.amount.toString(),
              currency: "USD",
              name: res?.data?.data?.name,
              description: "",
              image: { JoyfulLogo },
              order_id: res?.data?.data?.orderId,
              handler: async function (response) {
                response.plan = plan
                  await api2.post(`payment/addPayment`, { plan: plan}, config)
                  let userInfo = userData?.user;
                  userInfo["license"] = "premium";
                  dispatch(updateUser(userInfo, userInfo?._id));
                 setTimeout(() => {
                  navigator("/home")
                 }, 300);
              },
              prefill: {
                  name: res?.data?.data?.name,
                  email: res?.data?.data?.email,
              },
              theme: {
                  color: "#61dafb",
              },
          };
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }


    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const dispatch = useDispatch();
  function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
    
}
  const handleClick =async (e) => {
    displayRazorpay(e.target.name)
  };

  return (
    <Card sx={{ minHeight: "100%" }}>
      <ArgonBox pt={3} pb={2} px={2} textAlign="center">
        <ArgonTypography
          variant="body2"
          color="dark"
          textTransform="uppercase"
          fontWeight="medium"
        >
          {title}
        </ArgonTypography>
        <ArgonBox
          my={1}
          display="flex"
          alignItems="flex-end"
          justifyContent="center"
        >
          <ArgonTypography variant="h1">
            <ArgonTypography display="inline" component="small" variant="h2">
              {price.currency}
            </ArgonTypography>
            {price.value}
          </ArgonTypography>

          <ArgonTypography variant="jpH3Regular"> {subTitle} </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox pb={3} px={3}>
        <ArgonBox>
          <ArgonButton
            // component={Link}
            // to={action.route}
            name={action.name}
            color={action.color}
            fullWidth
            onClick={handleClick}
          >
            {action.label}&nbsp;
            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </ArgonButton>
        </ArgonBox>
      </ArgonBox>
      {alert?.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </Card>
  );
}

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  specifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]).isRequired,
  }).isRequired,
};

export default DefaultPricingCard;
