import React, { useState } from "react";
import ArgonButton from "components/ArgonButton";
import { Card, Dialog, DialogActions, styled } from "@mui/material";
import ArgonTypography from "components/ArgonTypography";
import ArgonBox from "components/ArgonBox";
import { ReactComponent as CloseIcon } from "assets/images/icons/Close.svg";
import { useArgonController } from "context";

import DeleteIcon from "assets/images/icons/DeleteIcon.svg";

const DeleteModal = ({
  deleteContent,
  isOpenMenu,
  setIsOpenMenu,
  handleSuccess,
}) => {
  const handleClose = () => {
    setIsOpenMenu(false);
  };

  const [controller] = useArgonController();
  const { darkMode } = controller;

  const ClosrIconSvg = styled(CloseIcon)(({ theme }) => ({
    height: 32,
    width: 32,
    cursor: 'pointer',
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.natural.eight,
    },
  }));
  return (
    <>
      <Dialog
        open={isOpenMenu}
        onClose={handleClose}
        maxWidth="xs"
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <Card sx={{ p: 4, overflow: 'unset' }}>
          <ArgonBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ArgonBox />

            <ArgonBox onClick={handleClose}>
              <ClosrIconSvg />
            </ArgonBox>
          </ArgonBox>

          <ArgonBox display="flex" flexDirection="column" alignItems="center">
            <ArgonBox mb={4}>
              <ArgonBox component="img" src={DeleteIcon} alt="delete" />
            </ArgonBox>

            <ArgonBox
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
            >
              <ArgonTypography variant="jpH3Bold" textAlign="center">
                {deleteContent}
              </ArgonTypography>
              <ArgonTypography
                variant="jpBodyRegular"
                textAlign="center"
                sx={({ palette: { natural } }) => ({
                  color: natural.five,
                })}
              >
                You will remove this permanently after you approve to delete
                this.
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>

          <ArgonBox
            sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 7 }}
          >
            <ArgonButton
              size="large"
              fullWidth
              sx={({ palette: { blue } }) => ({
                background: blue.one,
                px: 3,

                ':hover': {
                  background: blue.one,
                },
                ':focus:not(:hover)': {
                  background: blue.one,
                },
              })}
              onClick={() => handleClose()}
            >
              No, I Don't
            </ArgonButton>
            <ArgonButton
              size="large"
              fullWidth
              sx={({ palette: { error, white } }) => ({
                background: error.primary,
                color: white.main,
                px: 3,

                ':hover': {
                  background: error.primary,
                },
                ':focus:not(:hover)': {
                  background: error.primary,
                },
              })}
              onClick={() => handleSuccess()}
            >
              Yeah, I Do
            </ArgonButton>
          </ArgonBox>
        </Card>
      </Dialog>
    </>
  );
};

export default DeleteModal;
