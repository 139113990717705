/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router components
import { Link } from "react-router-dom";

// @mui material components
import Collapse from "@mui/material/Collapse";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO React contexts
import { useArgonController } from "context";
import ArgonButton from "components/ArgonButton";

function DefaultNavbarMobile({ routes, open }) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <ArgonBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
        <ArgonBox display="flex" flexDirection="column" gap={2}>
          <ArgonButton
            component={Link}
            to={"/signin"}
            size="small"
            sx={({ palette: { blue, white } }) => ({
              background: blue.five,
              color: white.main,
              padding: "12px 24px",
              boxShadow: "none",

              ":hover": {
                background: blue.five,
              },
              ":focus:not(:hover)": {
                background: blue.five,
              },
            })}
          >
            Sign In
          </ArgonButton>
          <ArgonButton
            component={Link}
            to={"/signup"}
            size="small"
            sx={({ palette: { blue } }) => ({
              background: blue.one,
              color: blue.eight,
              padding: "12px 24px",
              boxShadow: "none",

              ":hover": {
                background: blue.one,
              },
              ":focus:not(:hover)": {
                background: blue.one,
              },
            })}
          >
            Sign Up
          </ArgonButton>
        </ArgonBox>
      </ArgonBox>
    </Collapse>
  );
}

// Typechecking props for the DefaultNavbarMobile
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
