/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";
import ArgonInput from "components/ArgonInput";
import { Card, styled } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import { useArgonController } from "context";
import CategoriesCard from "examples/Cards/CategoriesCard";
import ArgonDropzone from "components/ArgonDropzone";
import ImageUploadCard from "examples/Cards/ImageUploadCard";
import { useRef } from "react";
import FormField from "components/Common/FormField";
import ArgonEditor from "components/ArgonEditor";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as TextFileIcon } from "assets/images/icons/TextFile.svg";
import ArgonSelect from "components/ArgonSelect";
import FormSelect from "components/Common/FormSelect";
import FormEditor from "components/Common/FormEditor";

const listingAuthor = [
  { value: "JohnDoe", label: "John Doe" },
  { value: "markLuis", label: "Mark Luis" },
];

function AddCommunityReview() {
  const { size } = typography;
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const ref = useRef();
  const [editorValue, setEditorValue] = useState("");

  const TextFileIconSvg = styled(TextFileIcon)(({ theme }) => ({
    height: 24,
    width: 24,
    path: {
      fill: darkMode ? theme.palette.white.main : theme.palette.blue.seven,
    },
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={4}>
        <Grid container direction="row" spacing={4} sx={{}}>
          <Grid item xs={12} md={12} lg={9}>
            <Card sx={{ p: 4 }}>
              <ArgonBox display="flex" alignItems="center" gap={1} mb={4}>
                <TextFileIconSvg />
                <ArgonTypography
                  variant="jpH3Bold"
                  sx={({ palette: { blue, white } }) => ({
                    color: darkMode ? white.main : blue.seven,
                  })}
                >
                  Community Reviews
                </ArgonTypography>
              </ArgonBox>

              <ArgonBox component="form" role="form">
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormSelect
                        options={listingAuthor}
                        placeholder="Listing Category"
                        size="large"
                        label="Category"
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormSelect
                        options={listingAuthor}
                        placeholder="Select Listing"
                        size="large"
                        label="Listing"
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormSelect
                        options={listingAuthor}
                        placeholder="Your Author"
                        size="large"
                        label="Author"
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormSelect
                        options={listingAuthor}
                        placeholder="Your Age"
                        size="large"
                        label="Age"
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormSelect
                        isMulti
                        options={listingAuthor}
                        placeholder="Your Genre"
                        size="large"
                        label="Genre"
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormSelect
                        isMulti
                        options={listingAuthor}
                        placeholder="Category Related Fields"
                        size="large"
                        label="Category Related Fields"
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        value={editorValue}
                        onChange={setEditorValue}
                        label="Short Description"
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        value={editorValue}
                        onChange={setEditorValue}
                        label="Long Description"
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        value={editorValue}
                        onChange={setEditorValue}
                        label="What's it about ?"
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        value={editorValue}
                        onChange={setEditorValue}
                        label="Is It Any Good ?"
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item lg={6} sm={12} xs={12}>
                    <ArgonBox>
                      <FormEditor
                        value={editorValue}
                        onChange={setEditorValue}
                        label="Talk To Your Kids About"
                      />
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
          </Grid>

          <Grid item xs={12} md={12} lg={3}>
            <Card sx={{ p: 2 }}>
              <ArgonBox
                mb={1}
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <ArgonButton
                  color="info"
                  size="large"
                  fullWidth
                  component={Link}
                  to="/"
                >
                  Publish
                </ArgonButton>

                <ArgonButton
                  size="large"
                  sx={({ palette: { blue } }) => ({
                    background: blue.one,
                    ":hover": {
                      background: blue.one,
                    },
                    ":focus:not(:hover)": {
                      background: blue.one,
                    },
                  })}
                  fullWidth
                  component={Link}
                  to="/"
                >
                  Save Draft
                </ArgonButton>

                <ArgonButton
                  size="large"
                  sx={({ palette: { error } }) => ({
                    background: error.secondary,
                    color: error.primary,
                    ":hover": {
                      background: error.secondary,
                    },
                    ":focus:not(:hover)": {
                      background: error.secondary,
                    },
                  })}
                  fullWidth
                  component={Link}
                  to="/"
                >
                  Cancel
                </ArgonButton>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default AddCommunityReview;
