import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import AddKid from "./add-kid";
import DataTable from "examples/Tables/DataTable";
import dataTableData from "./data/dataTableData";
import { useDispatch } from "react-redux";
import { getFamilyView } from "Actions/userAction";
import { useSelector } from "react-redux";
import NameCell from "./components/NameCell";
import DefaultCell from "./components/DefaultCell";
import ActionCell from "./components/ActionCell";
import api2 from "api2";
import axios from "axios";
import DeleteModal from "components/Common/DeleteModal";
import ArgonSnackbar from "components/ArgonSnackbar";
  const columns= [
    { Header: "name", accessor: "name" },
    { Header: "email", accessor: "email" },
    { Header: "age", accessor: "age" },
    { Header: "action", accessor: "action", align: "center" },
  ]
const MyKids = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState();
  const [rows,setRows] = useState([])
  const userData = useSelector((state) =>state?.authState?.user?.user)
  const {familyList} = useSelector((state) => state?.userState)
  const token = useSelector((state) =>state?.authState?.user?.token)
  const [deleteId,setDeleteId] = useState() 
  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: ""
  })
  const dispatch  =useDispatch()
  useEffect(()=>{
    dispatch(getFamilyView(userData?._id))
  },[dispatch])
  const handleEdit =(item)=>{
    setSelectedItem(item)
    setIsOpenMenu(true)
  }
  const handleDelete =(item)=>{
    setIsOpen(true)
    setDeleteId(item?._id)
  }
  useEffect(()=>{
    const familyListArray = familyList?.data?.family?.teenagers?.length && familyList?.data?.family?.teenagers?.map((item)=>{
      return{
        name: (
          <NameCell
            image={item?.profilePicture}
            name={item?.userName}
            email={item?.email}
          />
        ),
        email: <DefaultCell>{item?.email}</DefaultCell>,
        age: <DefaultCell>{item?.age}</DefaultCell>,
        action: <ActionCell handleEdit={handleEdit} handleDelete={handleDelete} item={item}/>,
      }
    })
    setRows(familyListArray || [])
  },[familyList])
  const handleSuccess =async()=>{
    try{
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
         await api2.delete(
          `family/remove-teenager/${deleteId}`,
          config
        ).then((res)=>{
          if(res)
          setAlert({
            status: "success",
            isOpen: true,
            alertMsg: "Kid deleted successfully.",
            bgColor: "bggreen"
          })
          dispatch(getFamilyView(userData?._id))
          setIsOpen(false)
        });
        
      }catch(error){
        if (axios.isCancel(error)) {
          throw new Error("Cancelled");
        }
      }
}
  return (
    <>
      <Card id="my-kids" sx={{ p: 3 }}>
        {rows.length ? (
          <ArgonBox
            mb={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={3}
          >
            <ArgonTypography variant="h5">My Kids </ArgonTypography>

            <ArgonButton
              onClick={() => {
                setIsOpenMenu(true);
              }}
              variant="gradient"
              color="info"
              size="large"
            >
              Add
            </ArgonButton>
          </ArgonBox>
        ) : (
          <ArgonBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={3}
          >
            <ArgonTypography variant="jpH3Body">
              Find out what your kid should be doing
            </ArgonTypography>

            <ArgonButton
              onClick={() => {
                setIsOpenMenu(true);
              }}
              variant="gradient"
              color="info"
              size="large"
            >
              Add
            </ArgonButton>
          </ArgonBox>
        )}

        <ArgonBox pt={1}>
          {rows.length ? (
            <DataTable
              table={{ columns, rows: rows }}
              entriesPerPage={false}
              isSorted={false}
              showTotalEntries={false}
            />
          ) : (
            <></>
          )}
        </ArgonBox>
      </Card>

      <AddKid
        isOpenMenu={isOpenMenu}
        setIsOpenMenu={setIsOpenMenu}
        selectedItem={selectedItem}
      />
      <DeleteModal
        deleteContent={'Are you sure want to delete this user ?'}
        isOpenMenu={isOpen}
        setIsOpenMenu={setIsOpen}
        handleSuccess={handleSuccess}
      />
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </>
  );
};

export default MyKids;
