/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import borders from "assets/theme/base/borders";

function StatusCell({ status, image }) {
  const { borderRadius } = borders;
  return (
    <ArgonBox
      display="flex"
      alignItems="center"
      gap={1}
      sx={({ palette: { natural } }) => ({
        padding: "2px 8px 2px 6px",
        border: `1px solid ${natural.three}`,
        borderRadius: borderRadius.md,
      })}
    >
      <ArgonBox
        component="i"
        sx={({ palette: { blue } }) => ({
          background: blue.five,
          width: "12px",
          height: "12px",
          borderRadius: "3px",
          display: "inline-block",
        })}
      />
      <ArgonBox display="flex" flexDirection="column">
        <ArgonTypography variant="jpBodyMedium">{status}</ArgonTypography>
      </ArgonBox>
    </ArgonBox>
  );
}

// Setting default values for the props or StatusCell
StatusCell.defaultProps = {
  status: "",
};

// Typechecking props for the StatusCell
StatusCell.propTypes = {
  status: PropTypes.string,
};

export default StatusCell;
