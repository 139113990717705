/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Argon Dashboard 2 PRO MUI base styles
import ArgonInput from "components/ArgonInput";
import { Card, Select, styled } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import { useArgonController } from "context";
import ArgonDropzone from "components/ArgonDropzone";
import { useEffect, useRef } from "react";
import FormField from "components/Common/FormField";
import ArgonEditor from "components/ArgonEditor";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormEditor from "components/Common/FormEditor";
import { addCategoryApi } from "Actions/categoryAction";
import { useDispatch } from "react-redux";
import api2 from "api2";
import axios from "axios";
import ArgonSnackbar from "components/ArgonSnackbar";
import { getSignalCategoriesListApi } from "Actions/categoryAction";
import { useSelector } from "react-redux";
import { updateCategoryApi } from "Actions/categoryAction";
import { getUserByRole } from "Actions/categoryAction";
import { ReactComponent as DeleteIcon } from "assets/images/icons/Trash.svg";
import ImageUploadCard from "examples/Cards/ImageUploadCard";
import Loader from "components/Common/CircularProgress";
import FormSelect from "components/Common/FormSelect";
import { addArticleCategoryApi } from "Actions/categoryAction";
import { updateArticleCategoryApi } from "Actions/categoryAction";
const optionList =[
  {
    label:"Topic",
    value:"topic"
  },
  {
    label:"Platform",
    value:"platform"
  }
]
function AddArticleCategory() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { id } = useParams();
  const [controller] = useArgonController();
  const thumbnailRef = useRef();
  const [description, setDescription] = useState("");
  const [customFields, setCustomFields] = useState([
    {
      name: "",
      type: "",
    },
  ]);
  const [internal, setInternal] = useState([""]);
  const [showErrors, setShowErrors] = useState(false);
  const [thumbnailImg, setThumbnailImg] = useState();
  const [coverImg, setCoverImg] = useState();
  const coverRef = useRef();

  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  const [coverLoader, setCoverLoader] = useState(false);

  const [alert, setAlert] = useState({
    status: "",
    isOpen: false,
    alertMsg: "",
    bgColor: "",
  });
  const [categoryInfo, setCategoryInfo] = useState({
    description: "",
  });
  const token = useSelector((state) => state?.authState?.user?.token);
  const handleChanges = (e) => {
    const { name, value } = e.target;
    setCategoryInfo({
      ...categoryInfo,
      [name]: value,
    });
  };
  const fetchCategory = async () => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      if (id) {
        let result = await api2.get(`article-category/${id}`, config).then((res) => {
          if (res){
            setCategoryInfo({
              name: res?.data?.data.name,
              shortName: res?.data?.data.shortName,
              categoryType:res?.data?.data.categoryType
            });
          setThumbnailImg(res?.data?.data.thumbnail);
          setCoverImg(res?.data?.data.coverImg);
          setCustomFields(res?.data?.data.customFields || []);
          setInternal(res?.data?.data.internalReviewFields || []);
          setDescription(res?.data?.data.description || "");

          }
        });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("Cancelled");
      }
    }
  };
  useEffect(() => {
    // if(id){
    fetchCategory();
    // }
  }, [dispatch]);
  const handleSave = () => {
    setShowErrors(true);
    if (categoryInfo?.name) {
      const payload = {
        name: categoryInfo?.name,
        shortName: categoryInfo?.shortName,
        description: description,
        thumbnail: thumbnailImg,
        coverImg: coverImg,
        customFields: customFields,
        internalReviewFields: internal,
        categoryType:categoryInfo?.categoryType
      };
      if (id) {
        dispatch(updateArticleCategoryApi(payload, id));
        setAlert({
          ...alert,
          status: "success",
          isOpen: true,
          alertMsg: "Article Category update successfully.",
          bgColor: "bggreen",
        });
      } else {
        dispatch(addArticleCategoryApi(payload));
        setAlert({
          ...alert,
          status: "success",
          isOpen: true,
          alertMsg: "Article Category added successfully.",
          bgColor: "bggreen",
        });
      }
      setTimeout(() => {
        navigator("/article-category");
      }, 1000);
    }
  };
  const handleUpload = async (fieldName, file) => {
    try {
      let formData = new FormData();
      formData.append("file", file);
      let result = await api2.post(`imageUpload`, formData);
      if (fieldName === "thumbnailImg") {
        setThumbnailImg(result?.data?.data);
        setThumbnailLoader(false);
      }
      if (fieldName === "coverImg") {
        setCoverLoader(false);
        setCoverImg(result?.data?.data);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setAlert({
          isOpen: true,
          status: "error",
          alertMsg: "can't upload image",
          bgColor: "bgred",
        });
        throw new Error("Cancelled");
      }
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <ArgonBox py={4}>
          <Grid container direction="row" spacing={4} sx={{}}>
            <Grid item xs={12} md={12} lg={9}>
              <Card sx={{ p: 4, overflow: "visible" }}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    lg={4}
                    sm={12}
                    xs={12}
                    onClick={() => {
                      thumbnailRef?.current.childNodes[0].click();
                    }}
                  >
                    <ImageUploadCard
                      url={thumbnailImg}
                      title={"Choose Thumbnail"}
                      isLoading={thumbnailLoader}
                    />
                    <ArgonBox
                      ref={thumbnailRef}
                      sx={{ position: "relative", display: "none" }}
                    >
                      <ArgonDropzone
                        style={{
                          position: "absolute",
                          bottom: 0,
                          opacity: 0,
                          cursor: "pointer",
                        }}
                        options={{
                          onDrop: (acceptedFiles) => {
                            setThumbnailLoader(true);
                            handleUpload("thumbnailImg", acceptedFiles[0]);
                          },
                          accept: { "image/*": [] },
                        }}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    sm={12}
                    xs={12}
                    onClick={() => {
                      coverRef?.current.childNodes[0].click();
                    }}
                  >
                    <ImageUploadCard
                      url={coverImg}
                      title={"Choose Cover Image"}
                      isLoading={coverLoader}
                    />
                    <ArgonBox
                      ref={coverRef}
                      sx={{ position: "relative", display: "none" }}
                    >
                      <ArgonDropzone
                        style={{
                          position: "absolute",
                          bottom: 0,
                          opacity: 0,
                          cursor: "pointer",
                        }}
                        options={{
                          onDrop: (acceptedFiles) => {
                            setCoverLoader(true);
                            handleUpload("coverImg", acceptedFiles[0]);
                          },
                          accept: { "image/*": [] },
                        }}
                      />
                    </ArgonBox>
                  </Grid>
                </Grid>
                <ArgonBox component="form" role="form" mt={4}>
                  <Grid container spacing={3}>
                    <Grid item lg={6} sm={12} xs={12}>
                      <ArgonBox>
                        <FormField
                          type="text"
                          placeholder="Your Category Title"
                          size="large"
                          label="Category Title"
                          value={categoryInfo?.name}
                          name="name"
                          onChange={handleChanges}
                          error={!categoryInfo?.name && showErrors}
                        />
                      </ArgonBox>
                    </Grid>

                    <Grid item lg={6} sm={12} xs={12}>
                      <ArgonBox>
                        <FormField
                          type="text"
                          placeholder="Your Category Short Name"
                          size="large"
                          label="Category Short Name"
                          value={categoryInfo?.shortName}
                          name="shortName"
                          onChange={handleChanges}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item lg={6} xs={12} sm={12}>
                    <ArgonBox>
                      <FormSelect
                        options={optionList}
                        placeholder="Category topic"
                        size="large"
                        label="Category"
                        value={
                          optionList.filter((item)=>categoryInfo?.categoryType?.includes(item?.value)) 
                        }
                        onChange={(selectedValue) => {
                          setCategoryInfo({
                            ...categoryInfo,
                            categoryType: selectedValue?.value 
                          });
                        }}
                        // error={showError && !articleInfo?.category}
                      />
                    </ArgonBox>
                  </Grid>

                    <Grid item lg={12} sm={12} xs={12}>
                      <ArgonBox>
                        <FormEditor
                          label="Description"
                          value={description}
                          onChange={(value) => setDescription(value)}
                        />
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Card>
            </Grid>

            <Grid item xs={12} md={12} lg={3}>
              <Card sx={{ p: 2 }}>
                <ArgonBox
                  mb={1}
                  sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                >
                  <ArgonButton
                    color="info"
                    size="large"
                    fullWidth
                    onClick={handleSave}
                  >
                    Save
                  </ArgonButton>
                  <ArgonButton
                    size="large"
                    sx={({ palette: { error } }) => ({
                      background: error.secondary,
                      color: error.primary,
                      ":hover": {
                        background: error.secondary,
                      },
                      ":focus:not(:hover)": {
                        background: error.secondary,
                      },
                    })}
                    fullWidth
                    component={Link}
                    to="/categories"
                  >
                    Cancel
                  </ArgonButton>
                </ArgonBox>
              </Card>
            </Grid>
          </Grid>
        </ArgonBox>
      </DashboardLayout>
      {alert.isOpen && (
        <ArgonSnackbar
          color={alert.status}
          icon="check"
          title={alert.status}
          content={alert.alertMsg}
          open={alert.isOpen}
          onClose={() => setAlert({ isOpen: false })}
          close={() => setAlert({ isOpen: false })}
          bgColor={alert.bgColor}
        />
      )}
    </>
  );
}

export default AddArticleCategory;
